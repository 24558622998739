<template>
  <r-modal
    :show="show"
    size="md"
    :title="translations['AddNewResource']"
    @hide="$emit('hide')"
  >
    <dialog-form
      :dialog-data="dialogData"
      :data-in-store="false"
      @input="handleInput"
    />

    <template #modal-footer>
      <div class="flex justify-end">
        <r-button variant="secondary" @click="$emit('hide')">
          {{ translations["Cancel"] }}
        </r-button>
        <r-button variant="primary" @click="handleAddResource">
          {{ translations["AddResources"] }}
        </r-button>
      </div>
    </template>
  </r-modal>
</template>

<script>
import RModal from "@/components/elements/RModal.vue";
import DialogForm from "@/components/dialog/DialogForm.vue";
import RButton from "@/components/elements/RButton.vue";
import dayjs from "dayjs";
import {getTranslations} from "@/functions/session/getTranslations.js";
import {notify} from "@/util/notify.js";

const translations = getTranslations();

export default {
  name: "AddResourceDialog",
  components: {
    RButton,
    DialogForm,
    RModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedResourceIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    resources: {
      type: Array,
      required: true,
    },
    weekDays: {
      type: Array,
      default: () => [
        {name: "Monday", title: translations["DescriptionMonday"]},
        {name: "Tuesday", title: translations["DescriptionTuesday"]},
        {name: "Wednesday", title: translations["DescriptionWednesday"]},
        {name: "Thursday", title: translations["DescriptionThursday"]},
        {name: "Friday", title: translations["DescriptionFriday"]},
        {name: "Saturday", title: translations["DescriptionSaturday"]},
        {name: "Sunday", title: translations["DescriptionSunday"]},
      ],
    },
  },
  data() {
    return {
      translations,

      dialogData: {
        Description:
          translations["ResourcePlanningDescription"] ||
          "Resource and hour capacity are required. To also create plannings, fill in the start time, end time, and select days.",
        Fields: {
          Resource: {
            Name: "Resource",
            Type: "select",
            Options: [],
            Required: true,
            IsRequired: true,
            Value: null,
            Editor: "MultiSelect",
            Title: translations["Resource"],
          },
          HourCapacity: {
            Name: "HourCapacity",
            Type: "number",
            Required: true,
            IsRequired: true,
            Value: 0,
            Title: translations["HourCapacity"],
            Placeholder: translations["EnterNumberOfHours"],
          },
          StartTime: {
            Name: "StartTime",
            Type: "time",
            Required: false,
            IsRequired: false,
            Value: null,
            Title: translations["StartTime"],
            Editor: "Time",
            Description:
              translations["OptionalForPlanning"] ||
              "Optional for creating plannings",
          },
          EndTime: {
            Name: "EndTime",
            Type: "time",
            Required: false,
            IsRequired: false,
            Value: null,
            Title: translations["EndTime"],
            Editor: "Time",
            Description:
              translations["OptionalForPlanning"] ||
              "Optional for creating plannings",
          },
          Days: {
            Name: "Days",
            Type: "select",
            Required: false,
            IsRequired: false,
            Value: [], // Default to no selection
            Title: translations["Days"],
            Editor: "MultiSelect",
            Description:
              translations["OptionalForPlanning"] ||
              "Optional for creating plannings",
            Options: this.weekDays.map((day) => ({
              Value: day.name,
              Text: day.title,
            })),
          },
        },
      },
    };
  },
  computed: {
    availableResourceOptions() {
      return this.resources
        .filter(
          (resource) => !this.selectedResourceIds.includes(resource.ResourceID),
        )
        .map((resource) => ({
          Value: resource.ResourceID,
          Text: resource.Description ?? resource.ShortCode,
          Attributes: {
            Color: resource.Color,
          },
        }));
    },
  },
  watch: {
    show(isVisible) {
      if (isVisible) {
        this.updateDialogFields();
      }
    },
    resources: {
      handler() {
        this.updateDialogFields();
      },
      deep: true,
    },
    selectedResourceIds: {
      handler() {
        this.updateDialogFields();
      },
      deep: true,
    },
  },
  created() {
    this.updateDialogFields();
  },
  methods: {
    updateDialogFields() {
      // Keep all the existing fields but update specific properties
      const updatedFields = {};

      // Process each field individually to maintain all properties
      Object.keys(this.dialogData.Fields).forEach((fieldName) => {
        updatedFields[fieldName] = {
          ...this.dialogData.Fields[fieldName],
        };
      });

      // Update specific fields
      updatedFields.Resource = {
        ...updatedFields.Resource,
        Options: this.availableResourceOptions,
        Value: null,
      };

      updatedFields.HourCapacity = {
        ...updatedFields.HourCapacity,
        Value: 8,
      };

      updatedFields.StartTime = {
        ...updatedFields.StartTime,
        Value: null,
      };

      updatedFields.EndTime = {
        ...updatedFields.EndTime,
        Value: null,
      };

      updatedFields.Days = {
        ...updatedFields.Days,
        Value: [],
      };

      this.dialogData = {
        ...this.dialogData,
        Description: this.dialogData.Description,
        Fields: updatedFields,
      };
    },

    handleInput(fields) {
      this.dialogData = {
        ...this.dialogData,
        Fields: fields,
      };
    },

    validateTimeValues(startTimeValue, endTimeValue) {
      // Only validate if both fields have values
      if (!startTimeValue || !endTimeValue) {
        return true;
      }

      // Parse times using dayjs
      const startTime = dayjs(`2000-01-01T${startTimeValue}`);
      const endTime = dayjs(`2000-01-01T${endTimeValue}`);

      // Check if times are valid
      if (!startTime.isValid() || !endTime.isValid()) {
        return false;
      }

      // Start time should not be after end time
      return !startTime.isAfter(endTime);
    },

    handleAddResource() {
      const fields = this.dialogData.Fields;
      const resourceIds = fields.Resource.Value;
      const hourCapacity = fields.HourCapacity.Value || 0;
      const startTime = fields.StartTime.Value;
      const endTime = fields.EndTime.Value;
      const days = fields.Days.Value || [];

      if (!resourceIds || resourceIds.length === 0) {
        return;
      }

      // Validate if we should create plannings
      const shouldCreatePlannings = !!(startTime && endTime && days.length > 0);

      // Validate time fields if both are present
      if (
        shouldCreatePlannings &&
        !this.validateTimeValues(startTime, endTime)
      ) {
        notify({
          message: translations["InvalidTimeRange"],
          type: "error",
        });
        return;
      }

      // If we have partial planning data, show a warning
      if ((startTime || endTime || days.length > 0) && !shouldCreatePlannings) {
        const missingFields = [];
        if (!startTime) missingFields.push(translations["StartTime"]);
        if (!endTime) missingFields.push(translations["EndTime"]);
        if (days.length === 0) missingFields.push(translations["Days"]);

        notify({
          message: `${translations["PlanningNotCreated"]}: ${missingFields.join(", ")}`,
          type: "warning",
        });
      }

      const resources = this.resources
        .filter((resource) => resourceIds.includes(resource.ResourceID))
        .map((resource) => ({
          ...resource,
          HourCapacity: hourCapacity,
          // Only include planning data if complete
          ...(shouldCreatePlannings && {
            startTime,
            endTime,
            days,
          }),
          // Add flag to indicate if plannings should be created
          createPlannings: shouldCreatePlannings,
        }));
      debugger;
      this.$emit("add-resource", resources);

      // Reset values after emitting
      this.updateDialogFields();
    },
  },
};
</script>
