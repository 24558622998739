import {setRowRentalEndValue} from "./setRowRentalEndValue.js";
import {parseDate} from "../../../util/parsing/parseDate.js";

export function processRowsDateTimeRentalEndMetadata({
  rows,
  newValue,
  vueComponent,
}) {
  const DateTimeRentalEnd =
    newValue?.Value ?? vueComponent.formFields.ChangeRentalEndDate.Value;

  return rows.map((row) => {
    if (row.rowMeta?.virtual) return row;

    if (
      DateTimeRentalEnd === "AllLines" ||
      (DateTimeRentalEnd === "AllLinesExceptReportedEnd" &&
        !row.DateTimeReportedEnd.Value) ||
      (DateTimeRentalEnd === "AllLinesExceptExpectedEndOrReportedEnd" &&
        !row.DateTimeBusinessEnd.Value &&
        !row.DateTimeReportedEnd.Value)
    ) {
      let newDate = parseDate({
        dateString: vueComponent.formFields.DateTimeRentalEnd.Value,
      });

      row = {
        ...row,
        DateTimeRentalEnd: {
          ...row.DateTimeRentalEnd,
          CellStyle: "!bg-primary !text-white",
          Value: newDate,
        },
      };
      return row;
    } else {
      row = {
        ...row,
        DateTimeRentalEnd: {
          ...row.DateTimeRentalEnd,
          CellStyle: null,
          Value: row.DateTimeRentalEnd.OriginalValue,
        },
      };

      return setRowRentalEndValue({row});
    }
  });
}
