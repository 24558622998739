import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Updates a list of default planning resource DTOs
 *
 * @param {Array} defaultPlanningResourceList - The list of default planning resource DTOs to update
 * @param {number} defaultPlanningResourceList[].DefaultPlanningResourceID - The ID of the default planning resource
 * @param {number} defaultPlanningResourceList[].ResourceID - The ID of the resource
 * @param {string} defaultPlanningResourceList[].Description - The description of the default planning resource
 * @param {number} defaultPlanningResourceList[].HourCapacity - The hour capacity of the resource
 * @returns {Promise<Array>} - Promise containing the updated default planning resource DTOs
 */
export const updateDefaultPlanningResource = async (
  defaultPlanningResourceList,
) => {
  return axiosInstanceWithErrorHandling(
    "/api/v2/warehouse/default-planning-resource",
    {
      method: "PATCH",
      data: defaultPlanningResourceList,
    },
  );
};
