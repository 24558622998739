import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Deletes plannings by their IDs
 *
 * @param {Array<number>} planningIDs - Array of planning IDs to delete
 * @returns {Promise<Object>} - Promise containing the response
 */
export const deletePlanning = async (planningIDs) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning", {
    method: "DELETE",
    data: planningIDs,
  });
};
