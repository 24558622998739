import {getRawValueFromDropdownValue} from "@/util/parsing/getRawValueFromDropdownValue";
import {getStockForQuotationItems} from "../stock/getStockForQuotationItems";
import DataLoader from "dataloader";

export async function rowsStockFetch({rows, quotation}) {
  const stockLoader = new DataLoader((rowsToLoad) =>
    getStockForQuotationItems({rowsToLoad, quotation}),
  );

  return Promise.all(
    rows.map(async (row, index) => {
      if (
        row.rowMeta?.virtual ||
        getRawValueFromDropdownValue(row.ItemType?.Value) === "Free" ||
        row.QuotationItemID?.Value?.toString().includes("_")
      ) {
        return row;
      }

      const quotationItemStockPrediction = await stockLoader.load(row);

      if (
        !quotationItemStockPrediction ||
        quotationItemStockPrediction instanceof Error
      ) {
        return row;
      }

      if (
        quotationItemStockPrediction.QuotationItemID !==
        row.QuotationItemID.Value
      ) {
        throw new Error(
          `Stock prediction is out of order! ${quotationItemStockPrediction.QuotationItemID} != ${row.QuotationItemID.Value} (index ${index})`,
        );
      }

      return {
        ...row,
        rowMeta: {
          ...row.rowMeta,
          stock: quotationItemStockPrediction.ItemStock,
        },
      };
    }),
  );
}
