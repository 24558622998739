<template>
  <div class="flex w-100 lg:flex-row flex-col">
    <div class="mr-auto flex">
      <div
        class="fake-button button button-menu no-hover no-action mr-auto h-fit"
      >
        <span>{{ count }}</span>
      </div>
      <div class="flex ml-2">
        <datagrid-pagination
          :page="page"
          :count="count"
          :page-size="pageSize"
          @page-change="$emit('page-change', $event)"
        />
      </div>
      <div
        v-if="activeRow"
        class="responsive-description flex max-h-[130px] overflow-hidden mx-3 pt-1 font-bold justify-center"
        :title="activeRowDescription"
      >
        <span>{{ activeRowDescription }}</span>
      </div>
    </div>

    <datagrid-inbound-order-item-footer-price-sum
      :rows="nonVirtualRows"
      :title="currency"
    />
  </div>
</template>

<script>
import DatagridPagination from "@/components/datagrid/DatagridPagination.vue";
import DatagridInboundOrderItemFooterPriceSum from "./DatagridInboundOrderItemFooterPriceSum.vue";
import {getRawValueFromDropdownValue} from "../../../util/parsing/getRawValueFromDropdownValue";

export default {
  name: "DatagridInboundOrderItemFooter",
  components: {
    DatagridInboundOrderItemFooterPriceSum,
    DatagridPagination,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    loadingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    activeRow: {
      type: Object,
      required: false,
      default: null,
    },
    currency: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
  computed: {
    nonVirtualRows() {
      return this.rows.filter((row) => !row.rowMeta?.virtual);
    },
    count() {
      return this.rows.filter((row) => !row.rowMeta?.compositionItem).length;
    },
    activeRowDescription() {
      return `#${this.activeRow.Ranking.Value} | ${getRawValueFromDropdownValue(this.activeRow.ItemID.Value) ?? ""} - ${getRawValueFromDropdownValue(this.activeRow.ItemDescription.Value) ?? ""}`;
    },
  },
};
</script>
