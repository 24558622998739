<template>
  <div class="flex">
    <canvas-navigation-buttons
      :r-window="activeRWindow"
      :r-sub-window="activeRSubWindow"
    />
    <canvas-currency-toggle
      v-if="windowId"
      :bulkedit="bulkedit"
      :window-id="windowId"
      :window="window"
      :sub-window="subWindow"
    />

    <canvas-history-button v-if="changeTrackingEnabled" :table="currentTable" />
    <canvas-reset-button
      :bulkedit="bulkedit"
      :active-window-id="windowId"
      :active-r-window="activeRWindow"
      :active-r-sub-window="activeRSubWindow"
      :window="window"
      :window-id="windowId"
      @new-job="$emit('new-job', $event)"
    />
    <canvas-dispose-button />
  </div>
</template>

<script>
import CanvasCurrencyToggle from "./CanvasCurrencyToggle.vue";
import CanvasDisposeButton from "./CanvasDisposeButton.vue";
import CanvasHistoryButton from "./CanvasHistoryButton.vue";
import CanvasResetButton from "./CanvasResetButton.vue";
import CanvasNavigationButtons from "./CanvasNavigationButtons.vue";

export default {
  name: "CanvasButtons",
  components: {
    CanvasNavigationButtons,
    CanvasCurrencyToggle,
    CanvasHistoryButton,
    CanvasDisposeButton,
    CanvasResetButton,
  },
  props: {
    window: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    bulkedit: {
      type: Boolean,
      required: true,
    },
    currentTable: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    subWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    activeRWindow: {
      type: Object,
      required: true,
    },
    activeRSubWindow: {
      type: [Object, null],
      required: false,
      default: () => null,
    },
    changeTrackingEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    windowId() {
      return this.window.id;
    },
  },
};
</script>
