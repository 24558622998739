<template>
  <div ref="item-scanner" class="h-[33px]">
    <v-select
      v-model.lazy="selectedValue"
      class="!h-full"
      :options="filter.options"
      :placeholder="translations['ItemScanner-AddANewItem']"
      :taggable="true"
      :loading="itemFetcherLoading"
      :reset-on-options-change="true"
      :dropdown-should-open="dropdownShouldOpen"
      :disabled="disabled"
      @search:focus="handleSearch"
      @search="handleSearch"
      @option:selected="selectOption"
    >
      <template #selected-option="{value}">
        <div>
          {{ replaceUrlInString(value) }}
        </div>
      </template>
      <template #option="option">
        <item-scanner-label
          :option="option"
          :get-label="getLabel"
          :search-value="searchValue"
        />
      </template>

      <template #spinner="{loading}">
        <i v-if="loading" class="fas fa-spinner fa-spin text-2xl"></i>
      </template>

      <template #no-options>{{ translations.NoRowsFound }}</template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {getScanBoxItems} from "@/services/item";
import {removeScanFieldFilterValueFromString} from "../../functions/item-scanner/removeScanFieldFilterValueFromString";
import ItemScannerLabel from "./ItemScannerLabel.vue";

export default {
  name: "ItemScanner",
  components: {
    vSelect,
    ItemScannerLabel,
  },
  inject: ["translations"],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraScanFetchParams: {
      type: Object,
      required: false,
    },
    entity: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      filter: {
        options: [],
      },
      selectedValue: null,
      searchValue: "",
      loading: false,
      itemFetcherLoading: false,
      pendingSearchEvent: null,
    };
  },
  computed: {
    replaceString() {
      return "";
    },
    settings() {
      return this.$store.state.settings;
    },
  },
  watch: {
    searchValue(val) {
      // check if val is string
      if (typeof val === "string" && val.includes(this.replaceString)) {
        this.searchValue = val.replace(this.replaceString, "");
      }
    },
  },
  methods: {
    getLabel({row}) {
      return row.label;
    },
    dropdownShouldOpen({open, search}) {
      if (this.settings.FetchScannerItemsByClicking && open) return true;

      if (!search) return false;

      return true;
    },
    selectOption(option) {
      if (typeof option == "string") {
        this.$emit("select", {
          value: removeScanFieldFilterValueFromString(option),
        });
        this.resetSelectBox();
        return;
      }
      const scanValueObject = option;

      if (!option.value) {
        scanValueObject.value = removeScanFieldFilterValueFromString(
          option.label ?? option,
        );
      }

      this.$emit("select", scanValueObject);
      this.resetSelectBox();
    },
    resetSelectBox() {
      this.selectedValue = null;

      // find input in scanElement and focus it
      const input = this.$refs["item-scanner"].querySelector(".vs__search");
      // wait 100 ms for input to be rendered
      setTimeout(() => {
        input.focus();
      }, 100);
    },
    replaceUrlInString(value) {
      return value?.replace(this.replaceString, "");
    },
    async handleSearch(event) {
      if (!event && !this.settings.FetchScannerItemsByClicking) return;
      const formattedSearchValue = removeScanFieldFilterValueFromString(event);

      if (this.pendingSearchEvent) {
        this.pendingSearchEvent = formattedSearchValue;
        return;
      }

      this.pendingSearchEvent = formattedSearchValue;
      this.itemFetcherLoading = true;

      while (this.pendingSearchEvent !== null) {
        const searchEvent = this.pendingSearchEvent;

        await this.fetchOptions(searchEvent);

        if (searchEvent === this.pendingSearchEvent) {
          this.pendingSearchEvent = null;
        }
      }
      this.itemFetcherLoading = false;
    },
    async fetchOptions(event) {
      let searchValue = event ?? "";
      this.searchValue = searchValue;

      this.loading = true;

      let params = {
        ...{
          tablename:
            global.session.activeWindow.sub?.window?.output.Request.Subject ??
            global.session.activeWindow.output.Request.Subject,
        },
        ...this.extraScanFetchParams,
      };

      console.log(this.extraScanFetchParams);

      const requestData = await getScanBoxItems({searchValue, params});

      const newOptions = this.filter?.options ?? [];

      for (const searchResult of requestData) {
        if (newOptions.some((option) => option.value === searchResult.Value)) {
          continue;
        }

        if (
          searchResult.Attributes.Type === "ItemSerial" &&
          (this.entity === "Quotations" || this.entity === "Invoices")
        ) {
          continue;
        } else {
          newOptions.push({
            label: searchResult.Text,
            value: searchResult.Value,
            type: searchResult.Attributes.Type,
            stock: searchResult.Attributes.Stock,
          });
        }
      }

      this.filter.options = newOptions;

      if (this.newFetchData !== null) {
        const newFetchData = this.newFetchData;
        this.newFetchData = null;

        await this.fetchOptions(newFetchData);
      }
    },
  },
};
</script>
