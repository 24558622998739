import {rowsProcessPrices} from "@/functions/datagrid/quotation-item/rows/rowsProcessPrices";
import {rowsProcessPopupPriceCalculations} from "@/functions/datagrid/order-item/rows/rowsProcessPopupPriceCalculations";
import {rowsProcessTotalExcVAT} from "../order-item/rows/rowsProcessTotalExcVAT.js";
import {rowsProcessPopupStockPredictions} from "./rows/rowsProcessPopupStockPredictions";
import {getSettings} from "../../../util/getSettings";

export async function loadRowDataForPage({rows, page, pageSize, quotation}) {
  try {
    if (!rows) return;
    const {FrontendRentalObjectPriceCalculation} = getSettings();

    let modifiedRows = rowsProcessPopupStockPredictions({rows: rows.slice()});

    const start = (page - 1) * pageSize;
    const end = page * pageSize;

    let visibleRows = modifiedRows.slice(start, end);

    if (
      FrontendRentalObjectPriceCalculation ===
      "PerformAutomaticPriceCalculation"
    ) {
      visibleRows = await rowsProcessPrices({rows: visibleRows, quotation});
      visibleRows = rowsProcessTotalExcVAT({
        rows: visibleRows,
      });
      visibleRows = await rowsProcessPopupPriceCalculations({
        rows: visibleRows,
      });
    }

    const result = {};
    const originalRows = rows.reduce(
      (acc, row) => ({...acc, [row.ClientSideUUID.Value]: row}),
      {},
    );

    for (let row of visibleRows) {
      const originalRow = originalRows[row.ClientSideUUID.Value];
      const modifiedProperties = {};

      for (let key in row) {
        if (JSON.stringify(row[key]) !== JSON.stringify(originalRow[key])) {
          modifiedProperties[key] = row[key];
        }
      }

      if (Object.keys(modifiedProperties).length > 0) {
        result[row.ClientSideUUID.Value] = modifiedProperties;
      }
    }

    return result;
  } catch (e) {
    console.log(e);
  }
}
