<template>
  <div v-if="showNavigationButtons" class="mr-2 mt-[2px]">
    <r-button
      variant="icon"
      override-classes="!py-0 px-1 !m-0 !mr-[6px] text-lg"
      :title="previousCriteriaLabel"
      :disabled="!previousCriteria"
      @click="navigate(previousCriteria)"
    >
      <i class="fas fa-arrow-left"></i>
    </r-button>

    <r-button
      variant="icon"
      override-classes="!py-0 px-1 !m-0 text-lg"
      :title="nextCriteriaLabel"
      :disabled="!nextCriteria"
      @click="navigate(nextCriteria)"
    >
      <i class="fas fa-arrow-right"></i>
    </r-button>
  </div>
</template>

<script>
import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges";
import {getWindowFromWindowId} from "../../../functions/window/getWindowFromWindowId";
import {getEntityNavigation} from "../../../services/v2/core/getEntityNavigation";
import {getActiveWarehouse} from "../../../util/getActiveWarehouse";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations";
import {debounce} from "lodash";
import {notify} from "../../../util/notify";
import RButton from "../../elements/RButton.vue";
import store from "../../../../state/store";
const translations = getTranslations();

export default {
  name: "CanvasNavigationButtons",
  components: {
    RButton,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rSubWindow: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      nextCriteria: null,
      previousCriteria: null,
    };
  },
  computed: {
    showNavigationButtons() {
      const selectedTab = this.rWindow.tabs?.find((tab) => tab.Selected);
      return (
        this.rWindow.tabs?.length > 0 && selectedTab.RawTitle === "General"
      );
    },
    previousCriteriaLabel() {
      if (!this.previousCriteria) return "";
      const previousCriteriaValue = Object.values(
        this.previousCriteria?.[0],
      )?.[0];
      return previousCriteriaValue
        ? translations.NavigatePreviousEntity.replace(
            "[title]",
            previousCriteriaValue,
          )
        : "";
    },
    nextCriteriaLabel() {
      if (!this.nextCriteria) return "";
      const nextCriteriaValue = Object.values(this.nextCriteria?.[0])?.[0];
      return nextCriteriaValue
        ? translations.NavigateNextEntity.replace("[title]", nextCriteriaValue)
        : "";
    },
  },
  watch: {
    "rWindow.id": {
      handler: function () {
        if (this.rWindow?.subject && this.showNavigationButtons) {
          this.debouncedInitializer();
        }
      },
      deep: true,
    },
  },
  created() {
    this.debouncedInitializer = debounce(
      function () {
        this.setEntityNavigationData();
      },
      100,
      {leading: true, trailing: false},
    );
  },
  methods: {
    async navigate(criteria) {
      store.state.loading = true;
      if (
        (this.rSubWindow.data.dirty || this.rWindow.data.dirty) &&
        !(await confirmDiscardUnsavedChanges())
      )
        return (store.state.loading = false);

      const result = await this.setEntityNavigationData(criteria);
      if (result.Error) {
        store.state.loading = false;
        return notify({
          type: "error",
          message: translations.SomethingWentWrongTryAgainLater,
        });
      }

      const {subject, prefix} = this.rWindow;

      const legacyWindow = getWindowFromWindowId({
        windowId: this.rWindow.id,
        session: global.session,
      });
      legacyWindow.dispose(false);

      await global.session.openWindow({
        Subject: subject,
        Prefix: prefix,
        Criteria: criteria,
      });
      store.state.loading = false;
    },
    async setEntityNavigationData(criteria) {
      const requestBody = {
        Subject: this.rWindow.subject,
        Criteria: criteria ?? this.rWindow.criteria,
      };

      const result = await getEntityNavigation({
        body: requestBody,
        params: {Warehouse: getActiveWarehouse()},
      });
      const {NextCriteria, PreviousCriteria} = result.data;

      this.nextCriteria = NextCriteria;
      this.previousCriteria = PreviousCriteria;

      return result?.data;
    },
  },
};
</script>

<style scoped></style>
