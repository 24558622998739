export const getVisibleRowsGrouped = ({rows, page, pageSize}) => {
  const normalRows = rows.filter((row) => !row.rowMeta?.virtual);
  const virtualRows = rows.filter((row) => row.rowMeta?.virtual);

  if (virtualRows.length < 1) return rows;

  let start = (page - 1) * pageSize;
  let end = start + pageSize;

  let pageRows = normalRows.slice(start, end);

  const mergedPageRows = [];

  pageRows.forEach((row) => {
    const matchedVirtualRow = virtualRows.find(
      (r) => r.OrderID.Value === row.OrderID.Value,
    );

    if (
      !mergedPageRows.find(
        (row) =>
          row.OrderID.Value === matchedVirtualRow.OrderID?.Value &&
          row.rowMeta?.virtual,
      )
    ) {
      mergedPageRows.push(matchedVirtualRow);
    }
    mergedPageRows.push(row);
  });

  return mergedPageRows;
};
