import {createReturnHasFailed} from "./createReturnHasFailed.js";
import {openReturnItemScanbox} from "./openReturnItemScanbox.js";
import store from "../../../../state/store.js";

export async function handleReturnResult({
  result,
  currentDirtyState,
  vueComponent,
}) {
  const {OpenReturnAfterCreate} = store.state.settings;

  if (!result || createReturnHasFailed({result})) {
    return vueComponent.$emit("data-change", {
      windowId: vueComponent.rWindow.id,
      newData: {
        ...vueComponent.rWindow.data,
        dirty: currentDirtyState,
      },
    });
  }

  if (result && !OpenReturnAfterCreate) {
    vueComponent.rows = vueComponent.rows.filter((row) => !row.Checked?.Value);

    if (vueComponent.rows.length < 1) return vueComponent.reset();

    vueComponent.toggleColumnHeaderCheckbox();
    vueComponent.$emit("data-change", {
      windowId: vueComponent.rWindow.id,
      newData: {
        ...vueComponent.rWindow.data,
        dirty: false,
      },
    });

    return openReturnItemScanbox();
  }
}
