import {getAction} from "../../actions/getAction";
import formatter from "../formatter.js";
import serializeForm from "../../util/serializeForm.js";
import {runMatchedClientSideActions} from "../../actions/legacy/runMatchedClientSideActions.js";
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {getRWindowById} from "../../functions/window/getRWindowById";
import {getCriteria} from "../../actions/getCriteria";

/**
 * Run action
 * @param {string} name - Action name
 * @param {string} targetTableName  - Name of target table
 * @param {string} webserviceUrl - Service URL
 * @param {string} webMethodName - Webmethod name
 * @param {boolean} doNotUseInternal - Should we use internal?
 * @returns {Promise} Promise
 */
export default async function action(
  name,
  targetTableName,
  webserviceUrl,
  webMethodName,
  doNotUseInternal,
) {
  this.toggleLoading(true);
  const translations = getTranslations();

  let actionName = name.replace(/\|/g, ":");
  if (!doNotUseInternal) {
    this.lastActionArgs = [
      actionName,
      targetTableName,
      webserviceUrl,
      webMethodName,
      doNotUseInternal,
    ];
  }

  let button = null;
  let tab = null;
  let selectedTab = null;

  const windowButtons = this.output.Buttons;

  if (windowButtons && this.output.Tabs) {
    button = windowButtons.filter((x) => x.ActionName == actionName).pop();
    tab =
      button ||
      this.output.Tabs.filter((x) => x.ActionName == actionName).pop();
  }

  if (tab) {
    this.tabs.forEach((x) => {
      x.Selected = tab === x;
    });

    // Store ActionName in input.Data.SelectedTabActionName to keep selected tab after reset
    if (this.output.Tabs.length > 0) {
      selectedTab = this.output.Tabs.filter(
        (x) => x.ActionName == actionName,
      ).pop();
      this.input.Data.SelectedTabActionName =
        selectedTab != undefined ? selectedTab.ActionName : null;
    }
  }

  let spec = button || tab;

  let customFn = this["action" + actionName];

  if (typeof customFn === "function" && !doNotUseInternal) {
    let result = customFn.call(
      this,
      targetTableName,
      webserviceUrl,
      webMethodName,
      true,
    );
    await Promise.resolve(result);
    this.toggleLoading(false);
    return;
  }

  const actionFunction = getAction({actionName, targetTableName});
  if (actionFunction) {
    const continueExecution = await actionFunction({
      window: this,
      actionName,
      targetTableName,
      webserviceUrl,
      webMethodName,
    });
    if (!continueExecution) {
      this.toggleLoading(false);
      return;
    }
  }

  // Move primitive properties from customData into actionInputArgs
  if (this.customData) {
    for (const property in this.customData) {
      if (typeof this.customData[property] !== "object") {
        this.actionInputArgs[property] = this.customData[property];
      }
    }
  }

  if (this.sub && this.sub.window) {
    if (
      this.sub.window.output &&
      this.sub.window.output.Request.Data &&
      this.sub.window.output.Request.Data.ClientCurrency
    ) {
      this.output.Request.Data.ClientCurrency =
        this.sub.window.output.Request.Data.ClientCurrency;
    }
  }

  const webMethodsWhichNeedSubWindowRequest = ["InvokeEntityMethod"];
  const optionsRequest =
    webMethodsWhichNeedSubWindowRequest.includes(webMethodName) &&
    this.sub?.window
      ? this.sub.window.output.Request
      : this.output.Request;

  const customRequestCriteria = getCriteria({
    targetTableName,
    currentCriteria: this.parent
      ? this.parent.output.Request.Criteria
      : this.output.Request.Criteria,
  });
  if (customRequestCriteria) optionsRequest.Criteria = customRequestCriteria;

  let opts = {
    selectionInfo: {
      ActionName: actionName,
      Arguments: this.actionInputArgs || {},
      Request: optionsRequest,
      Data: {
        Selection: !selectedTab ? this.selection : this.sub.window.selection,
      },
    },
  };

  this.actionInputArgs = {};

  if (this.output.Table != null) {
    let changes = [];

    for (
      let rowIndex = 0;
      rowIndex < this.output.Table.Rows.length;
      rowIndex++
    ) {
      let formData = {};
      let criteria =
        this.output.Request.Prefix != "New"
          ? this.buildCriteriaNew(this.output.FullTable.Rows[rowIndex])
          : null;

      for (let cell of this.output.Table.Rows[rowIndex]) {
        let col = cell.Column;
        if (!col) continue;
        let cname = col.Name;
        let formValue = cell.NewValue;
        let isAutoIncrementField = col.Editor === "autoIncrement";

        if (
          col.IsAutoNumber &&
          this.output.Request.Prefix === "New" &&
          !isAutoIncrementField
        ) {
          formData[cname] = null;
          continue;
        }

        if (
          isAutoIncrementField &&
          formValue === translations.NewFormAutoincrementingDescription
        ) {
          formData[cname] = "Autoincrement";
          continue;
        }

        if (col.IsReadOnly) continue;

        if (col.Name === "Color" && formValue === null) formValue = "#f26522";
        if (col.Type === "Boolean" && formValue === null) formValue = false;
        if (col.Type === "Boolean" && typeof elementValue === "string") {
          formValue = formValue === "true";
        }

        if (
          cell.IsDirty &&
          formValue !== undefined &&
          formValue !== null &&
          formValue !== ""
        ) {
          formData[cname] = formatter.serializeValue(col, formValue);
          continue;
        }

        if (
          !cell.IsDirty &&
          this.output.Request.Prefix != "New" &&
          this.output.Request.Prefix != "Preview" &&
          this.output.Request.Prefix != "Multi"
        ) {
          continue;
        }

        formData[cname] = formatter.serializeValue(col, formValue);
      }
      changes.push({Criteria: criteria, Values: formData});
    }

    opts.selectionInfo.Data.Changes = changes;
  }

  if (
    spec != null &&
    spec.SendData != null &&
    spec.SendData.indexOf("Form") >= 0
  ) {
    let form = serializeForm(this.element);

    for (let x in form) {
      if (String(form[x]).length < 1) {
        delete form[x];
        continue;
      }
    }

    if (this.output.Data != null && this.output.Data.Type == "tiles") {
      delete form.scan;
    }

    opts.selectionInfo.Data.Form = form;
  }

  opts.ExtraData = global.session.activeWindow.extraData;

  let result;
  let success = false;
  this.toggleLoading(true);
  try {
    result = await this.session.goodRequest({
      uri:
        (webserviceUrl || "/Admin/WebServices/CoreWebServices.asmx") +
        "/" +
        webMethodName,
      postData: opts,
      windowId: this.id,
    });

    if (result && !result.ContainsAnyWarnings && !result.ContainsAnyErrors) {
      success = true;
    }

    if (result?.ExtraData) {
      global.session.activeWindow.extraData = result.ExtraData;
    }
  } catch (error) {
    let errorMessage;
    if (error.message != undefined) {
      errorMessage = error.message;
    } else if (error.data.Error != undefined) {
      errorMessage = error.data.Error;
    } else if (error.data.Message != undefined) {
      errorMessage = error.data.Message;
    } else if (error.statusText != undefined) {
      errorMessage = error.statusText;
    }

    this.message("error", errorMessage);
    console.error(error);
    return;
  }

  if (success && webMethodName === "SaveData") {
    const rWindow = getRWindowById({windowId: this.sub?.window?.id ?? this.id});
    if (rWindow?.data.dirty) rWindow.data.dirty = false;
  }

  this.actionInputArgs = {};

  await runMatchedClientSideActions({
    subject: this?.output?.Request.Subject,
    targetTableName,
    webMethodName,
    webserviceUrl,
    window: this,
    afterAction: true,
    success,
  });

  await this.handleActionResponse(result, this.id);

  this.toggleLoading(false);
}
