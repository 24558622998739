<template>
  <div class="z-40" data-tooltip>
    <div v-if="!loading">{{ message }}</div>
    <div v-if="!isNonStockItem">
      <small>{{ subMessage }}</small>
    </div>
    <div class="my-2">
      <div class="!underline cursor-pointer" @click="openItemWindow">
        {{ translation("MoreInfo") }}
      </div>
    </div>
    <div v-if="conditions.length > 1" class="flex gap-3">
      <div v-for="condition in conditions" :key="condition.title" class="">
        <div class="font-semibold">{{ condition.title }}</div>
        <div class="w-full text-right">{{ condition.value }}</div>
      </div>
    </div>
    <div v-if="loading && conditions.length === 0" class="text-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </div>
</template>

<script>
import getOrderItemsConditionStockPrediction from "../../../services/v2/rental/order/getOrderItemsConditionStockPrediction.js";
import {openItemWindowHelper} from "./DatagridPopupStockPrediction/openItemWindowHelper.js";
import {createOrderItem} from "./DatagridPopupStockPrediction/createOrderItem.js";
import {generateMessage} from "./DatagridPopupStockPrediction/generateMessage.js";
import {mapCondition} from "./DatagridPopupStockPrediction/mapCondition.js";
import translation from "../../../util/translation.js";

export default {
  name: "DatagridOrderItemPopupStockPrediction",
  inject: ["translations"],
  props: {
    cell: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    processContext: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      conditions: [],
      loading: true,
    };
  },
  computed: {
    stock() {
      if (this.conditions.length > 0 && this.cell.Name === "PhysicalItemID")
        return this.totalConditionStock;
      return this.row.rowMeta?.stock ?? 0;
    },
    totalConditionStock() {
      return this.conditions.reduce(
        (acc, condition) => acc + condition.value,
        0,
      );
    },
    totalAmount() {
      return this.row.rowMeta.totalAmount ?? 0;
    },
    exceedingBy() {
      return this.row.Amount.Value - this.stock;
    },
    isNonStockItem() {
      return this.stock === 2147483647;
    },
    message() {
      return generateMessage({
        exceedingBy: this.exceedingBy,
        isNonStockItem: this.isNonStockItem,
        stock: this.stock,
        row: this.row,
      });
    },
    subMessage: () =>
      translation("Rental.OrderItem-MultiEdit-StockPopup-StockMessage"),
  },
  async created() {
    const rowsWithStock = this.rows.filter((row) => !row.rowMeta?.virtual);

    const {data} = await getOrderItemsConditionStockPrediction({
      orderId: this.processContext.order.OrderID,
      orderItems: rowsWithStock.map((orderItem) =>
        createOrderItem({...orderItem, cell: this.cell}),
      ),
    });

    this.conditions = data
      .find(
        (orderItem) =>
          orderItem.ClientSideUUID === this.row.ClientSideUUID.Value,
      )
      .Stock.map(mapCondition);

    this.loading = false;
  },
  methods: {
    translation,
    openItemWindow() {
      openItemWindowHelper({row: this.row, cell: this.cell});
    },
  },
};
</script>
