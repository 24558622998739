<template>
  <div>
    <!-- Selection indicator -->
    <div class="h-8 flex items-center justify-end pr-4 bg-gray-50 w-full">
      <span class="text-sm font-semibold text-gray-700">
        {{ selectedSlots.length }} {{ translations["ItemsSelected"] }}
      </span>
    </div>
    <div class="w-full h-full">
      <!-- Single scrollable container for the entire grid -->
      <div class="w-full h-full overflow-auto overflow-y-hidden">
        <div class="flex w-full">
          <!-- Fixed left column for resource titles -->
          <div class="w-48 flex-shrink-0 flex flex-col">
            <!-- Header cell (fixed in both directions) -->
            <div
              class="h-12 flex items-center justify-center font-semibold bg-gray-200 text-gray-700 sticky top-0 z-20"
            >
              <label
                class="flex items-center cursor-pointer margin-top[-5px] bg-gray-200"
              >
                <r-checkbox
                  :value="isAllSelected"
                  @change="toggleAllSelection"
                />
                <span class="ml-2 font-semibold">{{
                  translations["Resources"]
                }}</span>
              </label>
            </div>
            <div
              v-if="columnsHaveSubTitles"
              class="h-5 w-full bg-gray-200 mt-[-1px]"
            ></div>

            <!-- Resource rows with sticky left positioning -->
            <div
              v-for="(row, index) in rows"
              :key="'row-title-' + index"
              class="h-32 flex items-center justify-start px-4 font-medium text-gray-600"
            >
              <!-- Separate checkbox from clickable resource name -->
              <div class="flex items-center w-full">
                <!-- Checkbox in its own container -->
                <div class="flex-shrink-0 mr-2 margin-top[-4px]">
                  <r-checkbox
                    :value="isRowSelected(row)"
                    @change="(checked) => toggleRowSelection(row)"
                  />
                </div>

                <!-- Resource info slot (clickable name) in its own container -->
                <div class="flex-1">
                  <slot name="resource-info" :row="row">
                    <div
                      class="hover:text-orange-600 cursor-pointer flex items-center"
                      @click="$emit('resource-click', row)"
                    >
                      <span>{{ row.title }}</span>
                      <i
                        class="fas fa-pencil-alt ml-1 text-xs text-gray-400"
                      ></i>
                    </div>
                  </slot>
                </div>
              </div>
            </div>
          </div>

          <!-- Grid content area -->
          <div class="flex-grow flex flex-col w-full">
            <!-- Column headers with sticky top positioning -->
            <div class="flex sticky top-0 z-10 w-full">
              <div
                v-for="(column, colIndex) in columns"
                :key="'col-header-' + colIndex"
                class="flex-1 min-w-[8rem] w-full bg-gray-200"
              >
                <div
                  class="h-12 flex items-center justify-center font-semibold bg-gray-200 border-l text-gray-700 flex-col"
                >
                  <label class="flex items-center cursor-pointer">
                    <span class="mt-[-4px]">
                      <r-checkbox
                        :value="isColumnSelected(column.name)"
                        @change="
                          (checked) => toggleColumnSelection(column.name)
                        "
                      />
                    </span>
                    <span class="ml-2">{{ column.title }}</span>
                  </label>
                </div>
                <div
                  v-if="columnsHaveSubTitles"
                  class="text-gray-700 h-5 text-sm text-center font-bold"
                >
                  {{ column.subTitle }}
                </div>
              </div>
            </div>

            <!-- Grid cells -->
            <div class="flex w-full">
              <div
                v-for="(column, colIndex) in columns"
                :key="'col-' + colIndex"
                class="flex-1 min-w-[8rem] w-full"
              >
                <div
                  v-for="(row, rowIndex) in rows"
                  :key="'cell-' + rowIndex + '-' + colIndex"
                  class="relative h-32 border-l"
                >
                  <!-- Use custom tile component if provided, otherwise use default -->
                  <slot
                    name="grid-tile"
                    :time-slot="getTimeSlot(row, column.name)"
                    :is-selected="isSelected(row, column.name)"
                    :is-recently-updated="isRecentlyUpdated(row, column.name)"
                    :selection-mode="selectedSlots.length > 0"
                    :is-modified="checkIfModified(row, column.name)"
                    :handle-click="() => handleTileClick(row, column.name)"
                    :toggle-selection="() => toggleSelection(row, column.name)"
                  >
                    <GridViewTile
                      :time-slot="getTimeSlot(row, column.name)"
                      :is-selected="isSelected(row, column.name)"
                      :is-recently-updated="isRecentlyUpdated(row, column.name)"
                      :selection-mode="selectedSlots.length > 0"
                      :is-modified="checkIfModified(row, column.name)"
                      :override-classes-function="tileOverrideClassesFunction"
                      @click="handleTileClick(row, column.name)"
                      @toggle-selection="toggleSelection(row, column.name)"
                    />
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!rows.length"
          class="flex flex-col items-center justify-center w-full h-64 border-l border-gray-200 bg-gray-50 text-gray-500"
        >
          <div>
            <i
              class="fad fa-exclamation-triangle w-12 h-12 mb-3 text-gray-400 text-[38px]"
            ></i>
          </div>
          <p class="text-base font-medium">{{ translations.NoRowsFound }}</p>
        </div>
      </div>
    </div>

    <portal :to="actionPortalName">
      <actionBarActions
        :window="rWindow"
        :active-window-id="rWindow.parentId"
        :active-sub-window-id="rWindow.id"
        :sub-windowid="rWindow.id || null"
        :buttons="rWindow.buttons"
        :sub-window="rWindow"
        @new-job="handleNewJob"
      />
    </portal>
  </div>
</template>

<script>
import RButton from "../../elements/RButton.vue";
import RCheckbox from "../../elements/RCheckbox.vue";
import GridViewTile from "./GridViewTile.vue";
import {Portal} from "portal-vue";
import {getTranslations} from "@/functions/session/getTranslations.js";

const translations = getTranslations();

export default {
  name: "RGridView",
  components: {
    RButton,
    RCheckbox,
    GridViewTile,
    Portal,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    rWindow: {
      type: Object,
      required: true,
    },
    recentlyUpdated: {
      type: Array,
      default: () => [],
    },
    modifiedTiles: {
      type: Array,
      default: () => [],
    },
    tileOverrideClassesFunction: {
      type: [Function, null],
      default: null,
    },
  },
  emits: ["tile-click", "selection-change", "open-bulk-edit", "resource-click"],
  data() {
    return {
      selectedSlots: [],
      translations,
    };
  },
  computed: {
    columnsHaveSubTitles() {
      return this.columns.some((col) => col.subTitle);
    },
    getModalTitle() {
      if (this.showBulkModal) {
        return translations["BulkEdit"];
      }
      return this.selectedTimeSlot
        ? `${translations["AdjustTimes"]} - ${this.selectedTimeSlot.title}`
        : translations["AdjustTimes"];
    },
    isAllSelected() {
      return (
        this.rows.length > 0 &&
        this.columns.length > 0 &&
        this.selectedSlots.length === this.rows.length * this.columns.length
      );
    },
    actionPortalName() {
      return `action-bar-${this.rWindow.id}`;
    },
  },
  methods: {
    handleNewJob(job) {
      console.log({job});
    },

    handleTileClick(row, columnName) {
      if (this.selectedSlots.length > 0) {
        this.toggleSelection(row, columnName);
        return;
      }

      const timeSlot = this.getTimeSlot(row, columnName);
      this.$emit("tile-click", {row, column: columnName, timeSlot});
    },
    getTimeSlot(row, columnName) {
      const timeSlot = row.values.find((v) => v.column === columnName);

      // Always create a value object if it doesn't exist
      if (timeSlot && !timeSlot.value) {
        timeSlot.value = {startTime: "", endTime: ""};
      }

      return timeSlot;
    },
    toggleAllSelection() {
      if (this.isAllSelected) {
        this.clearSelection();
      } else {
        this.selectedSlots = this.rows.flatMap((row) =>
          this.columns.map((col) => ({
            row,
            column: col.name,
            value: row.values.find((value) => value.column === col.name),
          })),
        );
        this.$emit("selection-change", this.selectedSlots);
      }
    },
    saveSingleTimeChanges() {
      if (this.selectedTimeSlot && this.selectedColumn) {
        const timeSlot = this.selectedTimeSlot.values.find(
          (v) => v.column === this.selectedColumn,
        );
        if (timeSlot) {
          timeSlot.value.startTime = this.editingTime.startTime;
          timeSlot.value.endTime = this.editingTime.endTime;

          const update = {
            row: this.selectedTimeSlot,
            column: this.selectedColumn,
            newValue: {
              startTime: this.editingTime.startTime,
              endTime: this.editingTime.endTime,
            },
          };

          this.$emit("time-updated", update);
          this.markAsUpdated([update]);
        }
      }
      this.closeSingleModal();
    },
    isRowSelected(row) {
      return this.columns.every((col) => this.isSelected(row, col.name));
    },
    toggleRowSelection(row) {
      const isCurrentlySelected = this.isRowSelected(row);

      this.columns.forEach((col) => {
        const slotIndex = this.selectedSlots.findIndex(
          (slot) => slot.row.title === row.title && slot.column === col.name,
        );

        if (!isCurrentlySelected && slotIndex === -1) {
          this.selectedSlots.push({
            row,
            column: col.name,
            value: row.values.find((value) => value.column === col.name),
          });
        } else if (isCurrentlySelected && slotIndex !== -1) {
          this.selectedSlots.splice(slotIndex, 1);
        }
      });

      this.$emit("selection-change", this.selectedSlots);
    },
    isColumnSelected(columnName) {
      return this.rows?.every((row) => this.isSelected(row, columnName));
    },
    toggleColumnSelection(columnName) {
      const isCurrentlySelected = this.isColumnSelected(columnName);

      this.rows.forEach((row) => {
        const slotIndex = this.selectedSlots.findIndex(
          (slot) => slot.row.title === row.title && slot.column === columnName,
        );

        if (!isCurrentlySelected && slotIndex === -1) {
          this.selectedSlots.push({
            row,
            column: columnName,
            value: row.values.find((value) => value.column === columnName),
          });
        } else if (isCurrentlySelected && slotIndex !== -1) {
          this.selectedSlots.splice(slotIndex, 1);
        }
      });

      this.$emit("selection-change", this.selectedSlots);
    },
    toggleSelection(row, columnName) {
      const index = this.selectedSlots.findIndex(
        (slot) => slot.row.title === row.title && slot.column === columnName,
      );

      if (index === -1) {
        this.selectedSlots.push({
          row,
          column: columnName,
          value: row.values.find((value) => value.column === columnName),
        });
      } else {
        this.selectedSlots.splice(index, 1);
      }

      this.$emit("selection-change", this.selectedSlots);
    },
    clearSelection() {
      this.selectedSlots = [];
      this.$emit("selection-change", this.selectedSlots);
    },
    isSelected(row, columnName) {
      return this.selectedSlots.some(
        (slot) => slot.row.title === row.title && slot.column === columnName,
      );
    },
    isRecentlyUpdated(row, columnName) {
      return this.recentlyUpdated.some(
        (update) =>
          update.row.title === row.title && update.column === columnName,
      );
    },
    checkIfModified(row, columnName) {
      // Check if this tile is in the modifiedTiles array
      return this.modifiedTiles.some(
        (tile) =>
          tile.rowId === row.defaultPlanningResourceID &&
          tile.column === columnName,
      );
    },
  },
};
</script>
