import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Creates a list of planning DTOs
 *
 * @param {Array} data - The list of planning DTOs to create
 * @param {number} data[].DefaultPlanningID - The default planning ID
 * @param {string} data[].PlanningResourceID - The planning resource ID (UUID)
 * @param {string} data[].StartDate - The start date (ISO format)
 * @param {string} data[].EndDate - The end date (ISO format)
 * @param {string} data[].Day - The day (ISO format)
 * @param {string} data[].WarehouseID - The warehouse ID
 * @returns {Promise<Array>} - Promise containing the created planning DTOs
 */
export const createPlanning = async (data) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning", {
    method: "POST",
    data,
  });
};
