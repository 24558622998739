<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :action-functions="actionFunctions"
        :columns="columns"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :page-size="pageSize"
        :process-context="{invoice}"
        :skeleton-rows="addItemQueue.length"
        @loading="loading = $event"
        @page-change="page = $event"
        @click-row="handleRowClick"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <datagrid-header-invoice-item
            :loaded="loaded"
            :settings="settings"
            :page-size="pageSize"
            @item-select="addItem"
            @page-size-change="handlePageSizeChange"
          />
        </template>
        <template #footer>
          <datagrid-invoice-item-footer
            :rows="rows"
            :loading-page="loadingPage"
            :page="page"
            :page-size="pageSize"
            :active-row="activeRow"
            :currency="currency"
            :default-currency-id="defaultCurrencyId"
            @page-change="page = $event"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {rowsGenerateVirtualCompositionRows} from "../../../../functions/datagrid/rows/rowsGenerateVirtualCompositionRows";
import {rowsSetMetadataForCompositionRows} from "../../../../functions/datagrid/rows/rowsSetMetadataForCompositionRows";
import handleRowDeleteCompositionSupport from "../../../../functions/datagrid/row/handleRowDeleteCompositionSupport";
import {rowsSetNullValueForMissingProps} from "../../../../functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {initializeDatagridInvoiceItem} from "./utils/initializeDatagridInvoiceItem";
import {processCompositionDefinition} from "../../../../functions/datagrid/row/processCompositionDefinition";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {saveDatagridInvoiceItem} from "./utils/saveDatagridInvoiceItem";
import {setLocalStoragePageSize} from "../../../../functions/datagrid/setLocalStoragePageSize";
import {getRowsFromScannedItem} from "../../../../functions/datagrid/invoice-item/getRowsFromScannedItem";
import {mapActionComponent} from "../../../../functions/datagrid/mapActionComponent";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {renumberRows} from "../../../../functions/datagrid/rows/renumberRows";
import {getUserId} from "../../../../functions/session/getUserId";
import {sortRows} from "../../../../functions/datagrid/rows/sortRows";
import {notify} from "../../../../util/notify.js";
import DatagridHeaderInvoiceItem from "../../datagridHeaders/DatagridHeaderInvoiceItem.vue";
import DatagridInvoiceItemFooter from "../../datagridFooters/DatagridInvoiceItemFooter.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";

export default {
  name: "DatagridInvoiceItem",
  components: {
    DatagridInvoiceItemFooter,
    DatagridHeaderInvoiceItem,
    DatagridStandalone,
  },
  provide() {
    const vm = this;

    const rate = {};
    Object.defineProperty(rate, "rate", {
      enumerable: true,
      get: () => this.rate,
    });

    return {
      rate,
      translations: this.translations,
      rateCallback(row, followSetting = true) {
        if (vm.selectedCurrencyId === vm.defaultCurrencyId && followSetting)
          return 1;
        return row?.Rate?.Value ?? 1;
      },
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    loading: false,
    loadingPage: false,
    criteria: [],
    rows: [],
    originalRows: [],
    columns: {},
    invoice: {},
    invoiceId: null,
    activeRow: null,
    addItemQueue: [],
    page: 1,
    pageSize:
      JSON.parse(localStorage.getItem("datagridPageSizes"))?.InvoiceItem ?? 100,
    translations: getTranslations(),
    defaultCurrencyId: global.session?.defaultCurrencyId,
    actions: [{type: "Delete"}].map(mapActionComponent),
    actionFunctions: {
      deleteRow: handleRowDeleteCompositionSupport,
    },
  }),
  computed: {
    rate() {
      return this.rows[0]?.Rate?.Value ?? 1;
    },
    currency() {
      return (
        this.invoice?.CurrencyID?.Description ?? this.defaultCurrencyId ?? null
      );
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.InvoiceItem-MultiEdit`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);

    this.criteria = this.rWindow.criteria ?? this.rParentWindow.criteria;
    this.invoiceId = this.criteria[0].InvoiceID;

    await initializeDatagridInvoiceItem({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);
      if (this.addItemQueue.length > 1) {
        return;
      }

      while (this.addItemQueue.length > 0) {
        const queuedItem = this.addItemQueue[0];
        const newRows = await getRowsFromScannedItem({
          item: queuedItem,
          columns: this.columns,
          rows: this.rows,
          invoice: this.invoice,
          settings: this.settings,
          scanAmount: this.scanAmount,
        });

        this.updateRows(newRows);
        this.addItemQueue.shift();
      }

      let rows = rowsSetMetadataForCompositionRows({rows: this.rows});
      rows = rowsGenerateVirtualCompositionRows({rows});
      rows = rowsSetNullValueForMissingProps({rows, columns: this.columns});
      rows = rows.map((row) => processCompositionDefinition({row}));
      this.rows = rows;

      this.navigateToLastPage();
      this.updateWindowData();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        await saveDatagridInvoiceItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);
        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        await initializeDatagridInvoiceItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    renumberRows(job) {
      this.rows = renumberRows(this.rows);
      this.$emit("job-completed", job);
    },
    async sortRows(job) {
      this.rows = sortRows(this.rows);
      notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    updateRows(newRows) {
      newRows.forEach((newRow) => {
        const existingRow = this.rows.find(
          (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
        );
        if (existingRow) {
          // Update properties of the existing row
          Object.assign(existingRow, newRow);
        } else {
          // Add new row if it does not exist yet
          this.rows.push(newRow);
        }
      });
      this.updateWindowData();
    },
    updateWindowData() {
      const windowData = {
        ...this.rWindow.data,
        Columns: this.columns.reduce((acc, column) => {
          acc[column.Name] = column;
          return acc;
        }, {}),
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    handlePageSizeChange(pageSize) {
      setLocalStoragePageSize({entity: "InvoiceItem", pageSize});
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    navigateToPage({page}) {
      this.page = page;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
