var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white"},[(!_vm.loaded)?_c('div',{staticClass:"loader w-full flex justify-center"},[_c('i',{staticClass:"fas fa-spinner fa-spin text-3xl mt-3"})]):_vm._e(),(_vm.loaded && !_vm.inboundOrder.SupplierID?.Key)?_c('div',{staticClass:"w-full flex ml-3 mt-3"},[_vm._v(" "+_vm._s(_vm.translations.InboundOrderSupplierRequiredError)+" ")]):_vm._e(),(_vm.inboundOrder.SupplierID?.Key)?_c('div',{ref:"datagrid"},[(_vm.loaded && _vm.columns.length)?_c('datagrid-standalone',{attrs:{"rows":_vm.rows,"actions":_vm.actions,"columns":_vm.columns,"page":_vm.page,"page-size":_vm.pageSize,"sort-by":_vm.sortBy,"sort-direction":_vm.sortDirection,"user-metadata-key":_vm.userMetadataKey,"process-context":{
        inboundOrder: _vm.inboundOrder,
        inboundItems: _vm.inboundItems,
        priceConditions: _vm.priceConditions,
        columns: _vm.columns,
        warehouseId: _vm.warehouseId,
        vatCodes: _vm.vatCodes,
        supplier: _vm.supplier,
        costDistributionType: _vm.costDistributionType,
      },"skeleton-rows":_vm.addingItemCount},on:{"loading":function($event){_vm.loading = $event},"page-change":function($event){return _vm.handlePageChange({page: $event})},"icon-event":_vm.handleIconEvent,"click-row":_vm.updateActiveRow,"rows-updated":_vm.handleRowsUpdated},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-end mb-2"},[(_vm.loaded && _vm.showScanner)?_c('div',{staticClass:"w-full h-[33px]"},[_c('datagrid-scanner',{attrs:{"label":"ItemID","filter-by":(option, label, search) => {
                  return (
                    option.ItemID?.toLowerCase().includes(
                      search.toLowerCase(),
                    ) ||
                    option.Description?.toLowerCase().includes(
                      search.toLowerCase(),
                    )
                  );
                },"params":{orderId: _vm.inboundOrder.OrderID},"fetch-data-function":_vm.fetchInboundItemsScannerData,"settings":_vm.settings},on:{"select":_vm.addItem},scopedSlots:_vm._u([{key:"select-option",fn:function({option, searchValue}){return [_c('item-scanner-label',{attrs:{"option":option,"get-type":_vm.getTypeFromScannedRow,"get-label":_vm.getLabelFromScannedRow,"search-value":searchValue}})]}}],null,false,4210786572)})],1):_vm._e(),_c('datagrid-page-size',{staticClass:"ml-1",attrs:{"page-size":_vm.pageSize},on:{"change":_vm.handlePageSizeChange}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('datagrid-inbound-order-item-footer',{attrs:{"page":_vm.page,"page-size":_vm.pageSize,"rows":_vm.rows,"currency":_vm.selectedCurrencyId,"active-row":_vm.activeRow,"loading-page":_vm.loading},on:{"page-change":function($event){return _vm.handlePageChange({page: $event})}}})]},proxy:true}],null,false,491052635)}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }