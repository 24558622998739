<template>
  <r-modal
    :show="show"
    size="md"
    :title="isBulkEdit ? translations['BulkEdit'] : translations['AdjustTimes']"
    @hide="$emit('hide')"
  >
    <dialog-form
      :dialog-data="dialogData"
      :data-in-store="false"
      @input="handleInput"
    />

    <template #modal-footer>
      <div class="flex justify-between w-full">
        <!-- Clear button on left side -->
        <r-button
          variant="secondary"
          :title="
            isBulkEdit
              ? translations['ClearTimesForSelectedCells']
              : translations['ClearTimesForThisCell']
          "
          @click="handleClearTimes"
        >
          {{ translations["ClearTimes"] }}
        </r-button>

        <!-- Other buttons on right side -->

        <div>
          <r-button variant="secondary" class="mr-2" @click="$emit('hide')">
            {{ translations["Cancel"] }}
          </r-button>
          <r-button variant="primary" @click="handleSave">
            {{
              isBulkEdit
                ? translations["ApplyToSelected"]
                : translations["Save"]
            }}
          </r-button>
        </div>
      </div>
    </template>
  </r-modal>
</template>

<script>
import dayjs from "dayjs";
import RModal from "@/components/elements/RModal.vue";
import DialogForm from "@/components/dialog/DialogForm.vue";
import RButton from "@/components/elements/RButton.vue";
import {notify} from "@/util/notify.js";
import {getTranslations} from "@/functions/session/getTranslations.js";

const translations = getTranslations();

export default {
  name: "RGridViewPlanningWrapperTimeEditModal",
  components: {
    RButton,
    DialogForm,
    RModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isBulkEdit: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default: () => ({startTime: null, endTime: null}),
    },
  },
  data() {
    return {
      translations,
      dialogData: {
        Fields: {
          StartTime: {
            Name: "StartTime",
            Type: "time",
            Required: false,
            Value: null,
            Title: translations["StartTime"],
            Editor: "Time",
          },
          EndTime: {
            Name: "EndTime",
            Type: "time",
            Required: false,
            Value: null,
            Title: translations["EndTime"],
            Editor: "Time",
          },
        },
      },
    };
  },
  watch: {
    initialValues: {
      immediate: true,
      handler(newValues) {
        this.updateDialogFields(newValues);
      },
    },
    show(isVisible) {
      if (isVisible && this.initialValues) {
        this.updateDialogFields(this.initialValues);
      }
    },
  },
  methods: {
    updateDialogFields(values) {
      const startTime = values?.startTime || "";
      const endTime = values?.endTime || "";

      const updatedFields = {...this.dialogData.Fields};

      updatedFields.StartTime = {
        ...updatedFields.StartTime,
        Value: startTime,
      };

      updatedFields.EndTime = {
        ...updatedFields.EndTime,
        Value: endTime,
      };

      this.dialogData = {
        ...this.dialogData,
        Fields: updatedFields,
      };
    },

    validateTimeValues(startTimeValue, endTimeValue) {
      // Only validate if both fields have values
      if (!startTimeValue || !endTimeValue) {
        return true;
      }

      // Parse times using dayjs
      const startTime = dayjs(`2000-01-01T${startTimeValue}`);
      const endTime = dayjs(`2000-01-01T${endTimeValue}`);

      // Check if times are valid
      if (!startTime.isValid() || !endTime.isValid()) {
        return false;
      }

      // Start time should not be after end time
      return !startTime.isAfter(endTime);
    },

    handleInput(fields) {
      const startTimeField = fields.StartTime;
      const endTimeField = fields.EndTime;

      // Check if end time needs adjustment (if start time is after end time)
      if (startTimeField?.Value && endTimeField?.Value) {
        const startTime = dayjs(`2000-01-01T${startTimeField.Value}`);
        const endTime = dayjs(`2000-01-01T${endTimeField.Value}`);

        if (
          startTime.isValid() &&
          endTime.isValid() &&
          startTime.isAfter(endTime)
        ) {
          // Adjust end time to match start time
          this.dialogData = {
            ...this.dialogData,
            Fields: {
              ...fields,
              EndTime: {
                ...fields.EndTime,
                Value: startTimeField.Value,
              },
            },
          };
          return;
        }
      }

      // If no adjustments needed, update dialogData directly
      this.dialogData = {
        ...this.dialogData,
        Fields: fields,
      };
    },

    handleClearTimes() {
      // Send null values to parent to indicate clearing times
      this.$emit("save", {
        startTime: null,
        endTime: null,
      });

      // Close the modal
      this.$emit("hide");
    },

    handleSave() {
      const startTimeField = this.dialogData.Fields.StartTime;
      const endTimeField = this.dialogData.Fields.EndTime;

      if (!startTimeField?.Value || !endTimeField?.Value) {
        notify({
          message: translations["FillInBothTimes"],
          type: "error",
        });
        return;
      }

      // Extract time values for emission
      const timeValues = {
        startTime: startTimeField.Value,
        endTime: endTimeField.Value,
      };

      this.$emit("save", timeValues);

      // Reset field values
      this.dialogData = {
        ...this.dialogData,
        Fields: {
          StartTime: {
            ...this.dialogData.Fields.StartTime,
            Value: null,
          },
          EndTime: {
            ...this.dialogData.Fields.EndTime,
            Value: null,
          },
        },
      };
    },
  },
};
</script>
