import {getDialogFieldValues} from "../getDialogFieldValues";
import {convertQuotationToOrderService} from "../../../services/dialog/convertQuotationToOrderService";
import {notify} from "../../../util/notify";
import {getTranslations} from "../../session/localstorage/getTranslations";
import {getWindowFromWindowId} from "../../window/getWindowFromWindowId";

export const convertQuotationToOrder = async ({
  quotationId,
  dialogData,
  windowId,
}) => {
  const fields = getDialogFieldValues(dialogData);

  const {data} = await convertQuotationToOrderService({
    quotationId,
    body: fields,
  });

  if (!data)
    return notify({
      message: getTranslations().SomethingWentWrongTryAgainLater,
      type: "error",
    });

  const criteria = {OrderID: data.OrderID};
  const window = getWindowFromWindowId({
    windowId,
    session: global.session,
  });

  window.disposeParent(false);
  await window.openCriteria("Rental.Order", "Single", criteria);

  notify({
    message: getTranslations().Order_Success,
    type: "success",
  });
};
