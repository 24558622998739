import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Fetches default planning resource data with pagination, sorting, and filtering options
 *
 * @param {Object} options - Request options
 * @param {number} [options.index=1] - The index of the page
 * @param {number} [options.size=0] - The size of the page
 * @param {string} [options.search] - The string to search on
 * @param {string} [options.sortOn] - The name of the column to sort on
 * @param {boolean} [options.sortReverse=false] - True to sort in reverse order
 * @param {string} [options.defaultPlanningResourceID] - The defaultPlanningResourceID(s) to filter on (comma separated)
 * @param {string} [options.resourceID] - The resourceID(s) to filter on (comma separated)
 * @returns {Promise<Object>} - Promise containing the paginated default planning resource data
 * @returns {Array} - Promise.Collection - The array of default planning resource objects
 * @returns {number} - Promise.Index - The current page index
 * @returns {number} - Promise.Size - The page size
 * @returns {number} - Promise.Total - The total number of records
 */
export const fetchDefaultPlanningResource = async ({
  index = 1,
  size = 0,
  search,
  sortOn,
  sortReverse = false,
  defaultPlanningResourceID,
  resourceID,
} = {}) => {
  return axiosInstanceWithErrorHandling(
    "/api/v2/warehouse/default-planning-resource",
    {
      params: {
        index,
        size,
        search,
        sortOn,
        sortReverse,
        defaultPlanningResourceID,
        resourceID,
      },
    },
  );
};
