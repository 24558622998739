import {isRowDeletable} from "../../../row/isRowDeletable";

export const isCompositionDeletable = ({compositionRow, rows}) => {
  const rowsThatBelongToComposition = rows.filter((row) => {
    return (
      (row.CompositionID?.Value?.Key ?? row.CompositionID?.Value) ===
      (compositionRow.CompositionID.Value?.Key ??
        compositionRow.CompositionID.Value)
    );
  });

  return !rowsThatBelongToComposition.some((row) => !isRowDeletable({row}));
};
