<template>
  <div>
    <form-field
      v-if="field.Name"
      :field="field"
      :value="field"
      :row="formFieldObject"
      :initial-row="initialRow"
      @input="handleInput"
    />
  </div>
</template>
<script>
import FormField from "../../form/FormField.vue";

export default {
  name: "FormFieldCol",
  components: {FormField},
  props: {
    formFields: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    initialRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    field() {
      return (
        this.formFields.find((field) => field.Name === this.fieldName) ?? {}
      );
    },
    formFieldObject() {
      return this.formFields.reduce((acc, field) => {
        acc[field.Name] = field;
        return acc;
      }, {});
    },
  },
  // take the formFields, take the name as key, and the value as the value to an object

  methods: {
    handleInput(value) {
      this.$emit("field-change", {field: value, formFields: this.formFields});
    },
  },
};
</script>
