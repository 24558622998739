import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Deletes a list of default planning resources by their IDs
 *
 * @param {Array<number>} defaultPlanningResourceIDs - Array of default planning resource IDs to delete
 * @returns {Promise<Object>} - Promise containing the response from the server
 */
export const deleteDefaultPlanningResource = async (defaultPlanningResourceIDs) => {
  return axiosInstanceWithErrorHandling.delete(
    "/api/v2/warehouse/default-planning-resource",
    {
      data: defaultPlanningResourceIDs
    }
  );
};
