import {setRandomOrderItemId} from "./setRandomOrderItemId";
import {mapActionComponent} from "@/functions/datagrid/mapActionComponent";
import {compositionGroupGetTotalExcVATSum} from "../../../row/compositionGroupGetTotalExcVATSum";
import {compositionGroupGetCalculatedPriceSum} from "../../../row/compositionGroupGetCalculatedPriceSum";
import {setCorrectAmountForVirtualCompositionRow} from "@/functions/datagrid/row/setCorrectAmountForVirtualCompositionRow";
import {setCompositionDescriptionToItemDescriptionForVirtualRow} from "@/functions/datagrid/row/setCompositionDescriptionToItemDescriptionForVirtualRow";
import {isCompositionDeletable} from "../../row/composition/isCompositionDeletable";

export function createVirtualRow({row, rows}) {
  let virtualRow = {
    ItemDescription: {
      Value: row.CompositionDescription.Value,
      Name: "ItemDescription",
      IsReadOnly: true,
    },
    Composition: {
      Value: row.Composition.Value,
      Name: "Composition",
      IsReadOnly: true,
    },
    CompositionDescription: {
      Value: row.CompositionDescription.Value,
      Name: "CompositionDescription",
    },
    ItemID: {
      IsReadOnly: true,
      Value: "",
    },
    DateTimeExpectedStart: {
      Value: "",
      IsReadOnly: true,
    },
    Amount: {
      Value: "",
      Editor: "Int",
    },
    DateTimeExpectedEnd: {
      Value: "",
      IsReadOnly: true,
    },
    LockPrice: {
      Value: false,
      IsReadOnly: true,
      meta: {
        classes: {hidden: true},
      },
    },
    Discount: {
      Value: "",
      IsReadOnly: true,
      meta: {
        classes: {hidden: true},
      },
    },
    DateTimeBusinessStart: {
      Value: "",
      IsReadOnly: true,
    },
    DateTimeBusinessEnd: {
      Value: "",
      IsReadOnly: true,
    },
    SerialID: {
      Value: "",
      IsReadOnly: true,
    },
    TotalExcVAT: {
      Value: compositionGroupGetTotalExcVATSum({row, rows}),
      IsReadOnly: true,
    },
    CompositionDefinition: {
      Value: row.CompositionDefinition.Value,
      Name: "CompositionDefinition",
    },
    CalculatedPrice: {
      Value: compositionGroupGetCalculatedPriceSum({row, rows}),
      IsReadOnly: true,
    },
    PeriodPrice: {
      Value: "",
      IsReadOnly: true,
    },
    PeriodAmount: {
      Value: "",
      IsReadOnly: true,
    },
    Period: {
      Value: "",
      IsReadOnly: true,
    },
    PriceDifference: {
      Value: null,
      IsReadOnly: true,
      meta: {
        classes: {hidden: true},
      },
    },
    rowMeta: {
      styles: {
        "border-left": "2px solid #626262",
        "border-right": "2px solid #626262",
      },
      deletable: isCompositionDeletable({compositionRow: row, rows}),
      showAdvanced: false,
      virtual: true,
      actions: [{type: "CollapseCompositionRows"}].map(mapActionComponent),
    },
    Ranking: {
      IsReadOnly: true,
      meta: {
        classes: {hidden: true},
      },
    },
  };

  row.OrderItemID?.Value
    ? (virtualRow.OrderItemID = {
        Value: row.OrderItemID.Value,
        Name: "OrderItemID",
      })
    : (virtualRow = setRandomOrderItemId({row: virtualRow}));

  virtualRow = setCorrectAmountForVirtualCompositionRow({
    row: virtualRow,
    rows,
  });
  virtualRow = setCompositionDescriptionToItemDescriptionForVirtualRow({
    row: virtualRow,
  });
  return virtualRow;
}
