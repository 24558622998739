import {cancelSerialAmountPickedChangeAboveOne} from "./row/cancelSerialAmountPickedChangeAboveOne";
import {clearSerialIdWhenPickedQuantityIsZero} from "./row/clearSerialIdWhenPickedQuantityIsZero";
import {rowCheckSelectedSerialId} from "./row/rowCheckSelectedSerialId.js";
import {setCorrectStatusWhenPicked} from "./row/setCorrectStatusWhenPicked.js";
import {setSerialPickedQuantityReadOnlyWhenZero} from "./row/setSerialPickedQuantityReadOnlyWhenZero";
import {togglePickedQuantityBasedOnSerialScannedStatus} from "./row/togglePickedQuantityBasedOnSerialScannedStatus";
import {updateRowSerialPickedQuantity} from "./row/updateRowSerialPickedQuantity";
import {blockRowSameSerialSelection} from "./row/blockRowSameSerialSelection";
import {processRowDateTimePicked} from "./row/processRowDateTimePicked";

export function processColumnsMetaData({columns}) {
  const newColumns = columns.slice();
  newColumns.forEach((newColumn) => {
    newColumn.processValueChangeFunctions = [];
    newColumn.processValueChangeFunctionsForRows = [];

    if (newColumn.Name === "PickedQuantity") {
      newColumn.processValueChangeFunctions.push(
        cancelSerialAmountPickedChangeAboveOne,
        clearSerialIdWhenPickedQuantityIsZero,
        setSerialPickedQuantityReadOnlyWhenZero,
        setCorrectStatusWhenPicked,
        processRowDateTimePicked,
      );
    }
    if (newColumn.Name === "SerialID") {
      newColumn.processValueChangeFunctions.push(
        rowCheckSelectedSerialId,
        updateRowSerialPickedQuantity,
        blockRowSameSerialSelection,
        togglePickedQuantityBasedOnSerialScannedStatus,
        processRowDateTimePicked,
      );
    }
  });
  return newColumns;
}
