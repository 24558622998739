import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Fetches default planning data with pagination, sorting, and filtering options
 *
 * @param {Object} options - Request options
 * @param {Object} options.params - Query parameters for the request
 * @param {number} [options.params.index=1] - The index of the page
 * @param {number} [options.params.size=0] - The size of the page
 * @param {string} [options.params.search] - The string to search on
 * @param {string} [options.params.sortOn] - The name of the column to sort on
 * @param {boolean} [options.params.sortReverse=false] - True to sort in reverse order
 * @param {string} [options.params.defaultPlanningID] - The defaultPlanningID(s) to filter on (comma separated)
 * @param {string} [options.params.defaultPlanningResourceID] - The defaultPlanningResourceID(s) to filter on (comma separated)
 * @param {string} [options.params.warehouseID] - The warehouseID(s) to filter on (comma separated)
 * @returns {Promise<Object>} - Promise containing the paginated default planning data
 * @returns {Array} - Promise.Collection - The array of default planning objects
 * @returns {number} - Promise.Index - The current page index
 * @returns {number} - Promise.Size - The page size
 * @returns {number} - Promise.Total - The total number of records
 */
export const fetchDefaultPlanning = async ({params} = {}) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/default-planning", {
    params,
  });
};
