<!-- GridViewTile.vue -->
<template>
  <div
    class="absolute inset-2 rounded-lg flex items-center justify-center text-xs font-medium transition-all duration-200 cursor-pointer group"
    :class="tileClasses"
    @click.stop="handleClick"
  >
    <!-- Modification indicator (pencil icon) -->
    <div
      v-if="isModified"
      class="absolute top-2 left-2 text-orange-500"
      title="This value has been modified"
    >
      <i class="fas fa-pencil-alt text-xs"></i>
    </div>

    <!-- Selection checkbox -->
    <div
      class="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10"
      @click.stop
    >
      <div
        class="relative h-4 w-4 cursor-pointer"
        @click.stop="$emit('toggle-selection')"
      >
        <input
          type="checkbox"
          :checked="isSelected"
          class="peer absolute h-4 w-4 opacity-0"
        />
        <div
          class="absolute inset-0 rounded-full border border-gray-400 bg-white group-hover:bg-gray-100 peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all"
        ></div>
        <div
          class="absolute inset-0 flex items-center justify-center text-gray-700"
        >
          <i v-if="isSelected" class="fas fa-check"></i>
        </div>
      </div>
    </div>

    <!-- Display time slot -->
    <div class="w-full h-full flex items-center justify-center text-base">
      {{ displayValue }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {getTranslations} from "../../../functions/session/localstorage/getTranslations.js";

// Initialize dayjs plugins
dayjs.extend(customParseFormat);
const translations = getTranslations();

export default {
  name: "GridViewTile",
  props: {
    timeSlot: {
      type: Object,
      required: false,
      default: () => ({
        value: {
          startTime: null,
          endTime: null,
        },
      }),
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isRecentlyUpdated: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: Boolean,
      required: true,
    },
    isModified: {
      type: Boolean,
      default: false,
    },
    overrideClassesFunction: {
      type: [Function, null],
      default: null,
    },
  },
  emits: ["click", "toggle-selection"],
  computed: {
    tileClasses() {
      if (this.overrideClassesFunction)
        return this.overrideClassesFunction({
          isSelected: this.isSelected,
          isRecentlyUpdated: this.isRecentlyUpdated,
          isModified: this.isModified,
          timeSlot: this.timeSlot,
        });

      return {
        "bg-orange-100 border-2 border-blue-400": this.isSelected,
        "bg-gray-50 hover:bg-gray-100": !this.isSelected,
        "bg-orange-200 animate-flash font-bold": this.isRecentlyUpdated,
      };
    },
    displayValue() {
      // Special handling for cleared/empty slots
      if (!this.timeSlot?.value?.startTime && !this.timeSlot?.value?.endTime) {
        return translations["TileNotConfigured"];
      }

      const start = this.formatTimeValue(this.timeSlot?.value?.startTime);
      const end = this.formatTimeValue(this.timeSlot?.value?.endTime);
      return `${start} - ${end}`;
    },
  },
  methods: {
    handleClick() {
      if (this.selectionMode) {
        this.$emit("toggle-selection");
      } else {
        this.$emit("click", this.timeSlot);
      }
    },
    formatTimeValue(timeValue) {
      if (!timeValue) return "Nvt.";

      try {
        // Try different time formats and return a standardized HH:MM format
        if (timeValue.includes("T")) {
          // ISO datetime format
          return dayjs(timeValue).format("HH:mm");
        }

        // Various time formats (HH:MM:SS, HH:MM:SS.SSS, HH:MM)
        const formats = ["HH:mm:ss.SSS", "HH:mm:ss", "HH:mm"];

        for (const format of formats) {
          if (dayjs(timeValue, format, true).isValid()) {
            return dayjs(timeValue, format).format("HH:mm");
          }
        }

        // If we get here, just return the original value as fallback
        return timeValue;
      } catch (e) {
        console.error("Error formatting time value:", e);
        return timeValue;
      }
    },
  },
};
</script>
