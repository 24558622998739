const axios = require("axios");
import store from "../../state/store";

const axiosInstanceWithErrorHandling = axios.create({
  baseURL: "/",
});

axiosInstanceWithErrorHandling.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${store.state.accessToken}`;
  config.headers.SessionID = store.state.sessionId;
  config.headers["Content-Language"] = store.state.language;
  config.headers["NormalizeEnums"] = true;

  return config;
});

axiosInstanceWithErrorHandling.interceptors.response.use(async function (
  response,
) {
  if (response.data?.GlobalActions) {
    for (const action of response.data.GlobalActions) {
      const importedModule = await import(`../actions/${action.Function}.js`);
      importedModule.default(action.Data);
    }
  }

  const {windowId} = response.config;
  const windowType = store.getters["windows/windowType"](windowId);

  if (windowId && windowType && response.data?.ComponentActions?.length) {
    // loop over every action in result.ComponentActions
    response.data?.ComponentActions.forEach((action) => {
      // call function updateRow
      store.dispatch(`${windowType}/${action.Function}`, {
        data: action.Data,
        windowId,
      });
    });
  }

  if (response.data?.IsActionResponse) {
    global.session.activeWindow.handleActionResponse(response.data);
  }

  return response;
});

export {axiosInstanceWithErrorHandling};
