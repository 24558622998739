import DiagramDashboard from "../../components/dashboard/diagramDashboard.vue";
import KanbanTaskWrapper from "../../components/kanban/KanbanTaskWrapper.vue";
import TableTaskList from "../../components/table/TableTaskList.vue";
import {getInboundInvoiceItemButtons} from "../../functions/datagrid/inbound-invoice-item/getInboundInvoiceItemButtons";
import {getButtonsForWindow} from "../../functions/window/getButtonsForWindow";
import DatagridInboundInvoiceItem from "../../components/datagrid/datagridWrappers/datagridInboundInvoiceItem/DatagridInboundInvoiceItem.vue";
import DatagridStockTransferItem from "../../components/datagrid/datagridWrappers/datagridStockTransferItem/DatagridStockTransferItem.vue";
import DatagridInboundOrderItem from "../../components/datagrid/datagridWrappers/datagridInboundOrderItem/DatagridInboundOrderItem.vue";
import DatagridInboundDelivery from "../../components/datagrid/datagridWrappers/datagridInboundDelivery/DatagridInboundDelivery.vue";
import DatagridPartialDelivery from "../../components/datagrid/datagridWrappers/datagridPartialDelivery/DatagridPartialDelivery.vue";
import RFormRentalReservation from "../../components/window-types/r-form-wrappers/RFormRentalReservation.vue";
import DatagridQuotationItem from "../../components/datagrid/datagridWrappers/datagridQuotationItem/DatagridQuotationItem.vue";
import RFormRentalQuotation from "../../components/window-types/r-form-wrappers/RFormRentalQuotation.vue";
import DatagridPickListItem from "../../components/datagrid/datagridWrappers/datagridPickListItem/DatagridPickListItem.vue";
import DatagridInvoiceItem from "../../components/datagrid/datagridWrappers/datagridInvoiceItem/DatagridInvoiceItem.vue";
import DatagridReturnItem from "../../components/datagrid/datagridWrappers/datagridReturnItem/DatagridReturnItem.vue";
import DatagridOrderItem from "../../components/datagrid/datagridWrappers/datagridOrderItem/DatagridOrderItem.vue";
import RFormRentalOrder from "../../components/window-types/r-form-wrappers/RFormRentalOrder.vue";
import RFormDefault from "../../components/window-types/r-form-wrappers/RFormDefault.vue";
import iframe from "../../components/window-types/iframe.vue";
import RGridViewDefaultPlanningWrapper from "../../components/window-types/r-grid-view/wrappers/RGridViewDefaultPlanningWrapper.vue";
import RGridViewPlanningWrapper from "../../components/window-types/r-grid-view/wrappers/RGridViewPlanningWrapper.vue";

export default [
  {
    Subject: "Rental.Order",
    Prefix: ["New", "Single", "Detail"],
    vueComponent: RFormRentalOrder,
    process: false,
  },
  {
    Subject: "Warehouse.DefaultPlanning",
    Prefix: ["Single"],

    vueComponent: RGridViewDefaultPlanningWrapper,
    process: false,
  },
  {
    Subject: "Warehouse.Planning",
    Prefix: ["Single"],
    template: "calendar",
    vueComponent: RGridViewPlanningWrapper,
    process: false,
  },

  {
    Subject: "Rental.Quotation",
    Prefix: ["New", "Single", "Detail"],
    vueComponent: RFormRentalQuotation,
    process: false,
  },
  {
    Subject: "Rental.Reservation",
    Prefix: ["New", "Single", "Detail"],
    vueComponent: RFormRentalReservation,
    process: false,
  },
  {
    Subject: "*",
    ExcludedSubjects: [
      "Core.virtual_Iframe",
      "Rental.virtual_QuickRent",
      "Rental.virtual_Return",
      "Rental.Reservation",
    ],
    Prefix: ["New", "Single", "Detail", "Preview"],
    vueComponent: RFormDefault,
    process: false,
  },
  {
    Subject: "Rental.OrderItem",
    Prefix: ["MultiEdit"],
    template: "DatagridOrderItem",
    vueComponent: DatagridOrderItem,
    process: false,
  },
  {
    Subject: "Rental.QuotationItem",
    Prefix: ["MultiEdit"],
    template: "DatagridQuotationItem",
    vueComponent: DatagridQuotationItem,
    process: false,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Inbound.OrderItem",
    Prefix: ["MultiEdit"],
    template: "DatagridInboundOrderItem",
    vueComponent: DatagridInboundOrderItem,
    process: false,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Rental.InvoiceItem",
    Prefix: ["MultiEdit"],
    template: "DatagridInvoiceItem",
    vueComponent: DatagridInvoiceItem,
    process: false,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Rental.PickListItem",
    Prefix: ["Multi"],
    template: "DatagridPickListItem",
    vueComponent: DatagridPickListItem,
    process: false,
  },
  {
    Subject: "Warehouse.StockTransferItem",
    Prefix: ["Multi"],
    template: "DatagridStockTransferItem",
    vueComponent: DatagridStockTransferItem,
    process: false,
  },
  {
    Subject: "Inbound.virtual_Delivery",
    Prefix: ["Multi"],
    template: "DatagridInboundDelivery",
    vueComponent: DatagridInboundDelivery,
    process: false,
  },
  {
    Subject: "Inbound.InvoiceItem",
    Prefix: ["Multi"],
    template: "DatagridInboundInvoiceItem",
    vueComponent: DatagridInboundInvoiceItem,
    process: false,
    getWindowButtonsFunction: getInboundInvoiceItemButtons,
  },
  {
    Subject: "Rental.virtual_PartialDelivery",
    Prefix: ["Multi"],
    template: "DatagridPartialDelivery",
    vueComponent: DatagridPartialDelivery,
    process: false,
  },
  {
    Subject: "Rental.virtual_Return",
    Prefix: ["New"],
    template: "DatagridReturnItem",
    vueComponent: DatagridReturnItem,
    process: false,
  },
  {
    Subject: "Core.vw_Tasks",
    Prefix: ["View"],
    template: "TableTaskList",
    process: false,
    vueComponent: TableTaskList,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Core.virtual_Iframe",
    Prefix: ["Single", "Multi"],
    vueComponent: iframe,
  },
  {
    Subject: "Rental.OrderItem",
    Prefix: ["MultiEdit"],
    template: "DatagridOrderItem",
    vueComponent: DatagridOrderItem,
    process: false,
  },
  {
    Subject: "Rental.QuotationItem",
    Prefix: ["MultiEdit"],
    template: "DatagridQuotationItem",
    vueComponent: DatagridQuotationItem,
    process: false,
  },
  {
    Subject: "Core.DiagramDashboard",
    Prefix: ["Multi"],
    vueComponent: DiagramDashboard,
    process: false,
  },
  {
    Subject: "Core.RailRoadTask",
    Prefix: ["Multi"],
    vueComponent: KanbanTaskWrapper,
    process: false,
  },
];
