export const hideSalesItemRows = ({rows}) => {
  return rows.map((row) => {
    return {
      ...row,
      rowMeta: {
        ...row.rowMeta,
        classes: {
          ...row.rowMeta?.classes,
          hidden: (row.ItemType?.Value ?? row.ItemType) === "Sales",
        },
      },
    };
  });
};
