<template>
  <div>
    <div>
      <v-select
        ref="scanbox"
        v-model="selectedValue"
        :options="filter.options"
        :placeholder="`${translations.Search}`"
        :taggable="true"
        label="label"
        name="scanbox"
        class="form-select"
        :reset-on-options-change="true"
        :dropdown-should-open="dropdownShouldOpen"
        @search:focus="fetchOptions"
        @search="fetchOptions"
        @option:selected="selectOption"
      >
        <template #selected-option="{value}">
          <div>
            {{ replaceUrlInString(value) }}
          </div>
        </template>
        <template #option="option">
          <select-scan-order-item-label
            :option="option"
            :search-value="searchValue"
          />
        </template>

        <template #no-options>{{ translations.NoRowsFound }}</template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {alertPopup} from "../../../interface/alertPopup/alertPopup.js";
import {getScanBoxItems} from "../../../services/item";
import {getSerialInfo} from "../../../services/rentWebServices";
import Formatter from "../../../model/formatter";
import ComboboxUI from "../../../interface/combobox";
import Combobox from "../../../interface/combobox.class";
import SelectScanOrderItemLabel from "./SelectScanOrderItemLabel.vue";
import {unixMilisecondsToDateString} from "../../../util/formatting/unix";
import dayjs from "dayjs";
import {notify} from "../../../util/notify";
import $ from "jquery";
import {getHeader} from "../../../hooks/rentOrderItem";
import getStatusIcon from "../../../functions/getStatusIcon";

export default {
  components: {
    "v-select": vSelect,
    SelectScanOrderItemLabel,
  },
  props: {
    windowId: {
      required: true,
      type: String,
    },
    opts: {
      required: true,
      type: Object,
    },
    customerID: {
      required: false,
      type: String,
      default: "",
    },
    contactID: {
      required: false,
      type: String,
      default: "",
    },
    reference: {
      required: false,
      type: String,
      default: "",
    },
    dateTimeExpectedStart: {
      required: false,
      type: String,
      default: "",
    },
    dateTimeExpectedEnd: {
      required: false,
      type: String,
      default: "",
    },
    categoryID: {
      required: false,
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      filter: {
        options: [],
      },
      loading: false,
      selectedValue: null,
      requestData: null,
      newFetchData: null,
      searchValue: "",
      window: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    settings() {
      return this.$store.state.settings;
    },
    replaceString() {
      return this.$store.state.settingsScanFieldFilterValue;
    },
  },
  watch: {
    searchValue(val) {
      // check if val is string
      if (typeof val === "string" && val.includes(this.replaceString)) {
        this.searchValue = val.replace(this.replaceString, "");
      }
    },
  },
  created() {
    this.setWindow();
  },
  methods: {
    dropdownShouldOpen({open, search}) {
      if (this.settings.FetchScannerItemsByClicking && open) return true;

      if (!search) return false;

      return true;
    },
    setWindow() {
      this.window = global.session.windows[this.windowId];
    },
    replaceUrlInString(value) {
      return value?.replace(this.replaceString, "");
    },
    fetchOptions: async function (event) {
      if (!event && !this.settings.FetchScannerItemsByClicking) return;
      let searchValue = event ?? "";

      this.searchValue = searchValue;
      if (this.loading) {
        this.newFetchData = searchValue;
        return;
      }
      this.loading = true;

      const requestData = await getScanBoxItems({
        searchValue: searchValue,
        params: this.getFormParameters(),
      });

      this.filter.options;

      const newOptions = [];

      for (const searchResult of requestData) {
        newOptions.push({
          label: searchResult.Text,
          value: searchResult.Value,
          type: searchResult.Attributes.Type,
          stock: searchResult.Attributes.Stock,
        });
      }

      this.filter.options = newOptions;

      this.loading = false;
      if (this.newFetchData !== null) {
        const newFetchData = this.newFetchData;
        this.newFetchData = null;

        await this.fetchOptions(newFetchData);
      }
    },
    getFormParameters: function () {
      const serverData = this.window.output.serverData;

      const params = {
        customerID: serverData?.Customer?.Value,
        contactID: serverData?.ContactID?.Value,
        transactionTypeID: serverData?.TransactionTypeID?.Value,
        reference: serverData?.Reference,
        dateTimeExpectedStart: serverData.DateTimeExpectedStart,
        dateTimeExpectedEnd: serverData.DateTimeExpectedEnd,
        categoryID: serverData.CategoryID,
        subject: session.activeWindow.output.Request.Subject,
      };

      if (
        $(this.window.element).find('.form-view [name="CustomerID"]').length
      ) {
        params.customerID = $(this.window.element)
          .find('.form-view [name="CustomerID"]')
          .val();
      }

      if ($(this.window.element).find('.form-view [name="ContactID"]').length) {
        params.contactID = $(this.window.element)
          .find('.form-view [name="ContactID"]')
          .val();
      }

      if ($(this.window.element).find('.form-view [name="Reference"]').length) {
        params.reference = $(this.window.element)
          .find('.form-view [name="Reference"]')
          .val();
      }

      if (
        $(this.window.element).find('.form-view [name="DateTimeExpectedStart"]')
          .length
      ) {
        params.dateTimeExpectedStart = $(this.window.element)
          .find('.form-view [name="DateTimeExpectedStart"]')
          .val();
      }

      if (
        $(this.window.element).find('.form-view [name="DateTimeExpectedEnd"]')
          .length
      ) {
        params.dateTimeExpectedEnd = $(this.window.element)
          .find('.form-view [name="DateTimeExpectedEnd"]')
          .parent()
          .attr("data-set");
      }

      if (
        $(this.window.element).find('.form-view [name="CategoryID"]').length
      ) {
        params.categoryID = $(this.window.element)
          .find('.form-view [name="CategoryID"]')
          .val();
      }

      return params;
    },
    correctTableHeaders: function (window) {
      $(".table-view", window.element).each(function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let tableElement = this;
        let headerRow = $(
          ".table-index .table-row:first-child .table-cell",
          tableElement,
        );
        let firstContentRow = $(
          ".table-body .table-row:first-child .table-cell",
          tableElement,
        );

        headerRow.each(function () {
          let index = $(this).index();
          let cell = firstContentRow.eq(index);

          if (!cell || !cell.length) {
            return;
          }
          let width = cell.outerWidth();
          $(this).css({
            width: width,
            "min-width": width,
            "max-width": width,
          });
        });
      });
    },
    fixRowNumbers: function (window) {
      let min = 0;

      $(window.element)
        .find(".table-body .table-row-group [name='Ranking[]']")
        .each(function () {
          let $row = $(this).closest(".table-row");
          if (!$row.is(".added-row")) {
            return;
          }
          let n = $(this).text();
          n = typeof n !== "undefined" ? n.trim() : n;
          n = typeof n === "undefined" || n === "" ? 0 : Number(n);
          if (n <= min) {
            min = Math.floor(min / 10) * 10 + 10;
            $(this).text(min);
          } else {
            min = n;
          }
        });

      this.calculateTotals(window);
    },
    processScanResult: async function (data, selectValue) {
      let otherSerials = $(global.session.activeWindow.element)
        .find("[name='SerialID']")
        .toArray();

      let serialAlreadyOnOrder = false;

      for (let serialInput of otherSerials) {
        const statusCode = $(serialInput)
          .closest(".table-row-group")
          .find("[name='StatusCode']")
          .val();
        // the status is open or active or when there is no statuscode then the row is probably new
        if (
          $(serialInput).val()?.trim() == selectValue.trim() &&
          (!statusCode || statusCode < 200)
        ) {
          notify({
            type: "warning",
            message: this.translations.SerialAlreadyOnOrder,
          });
          //$(this).text($(this).data("server-value") || "")
          serialAlreadyOnOrder = true;
        }
      }

      if (serialAlreadyOnOrder) {
        return {serialAlreadyOnOrder};
      }

      if (data.Rows[0].SerialID) {
        // Find an order item with the same ItemID but dont hava a SerialID.
        let sameItemNoSerials = $("body")
          .find(".table-row-group")
          .filter(function () {
            let serialID = $(this).find("[name='SerialID']").val()?.trim();
            return (
              $(this).find("[name='ItemID[]']").text()?.trim() ==
                data.Rows[0].ItemID &&
              (!serialID || serialID === "") && // $(this).find("[name='SerialID']").val().trim()
              $(this).find("[name='Amount[]']").text()?.trim() == "1.00"
            );
          });

        data.Metadata[0].Amount.IsReadOnly = true;

        if (sameItemNoSerials.length > 0) {
          // If an item which uses serials is found but without a serial and the amount is 1,
          // enter the serialID and set Amount to read only.
          sameItemNoSerials
            .eq(0)
            .find("[name='SerialID']")
            .parents(".table-cell")
            .find("input")
            .val(data.Rows[0].SerialID);
          sameItemNoSerials
            .eq(0)
            .find("[name='SerialID']")
            .find("button.float-left")
            .remove();
          sameItemNoSerials
            .eq(0)
            .find("[name='SerialID']")
            .parents(".table-cell")
            .prepend(
              getStatusIcon(
                data.Metadata[0].SerialID.Status.Type,
                data.Metadata[0].SerialID.Status.Description,
              ),
            );
          sameItemNoSerials
            .eq(0)
            .find("[name='SerialID']")
            .parents(".table-cell")
            .addClass("flex items-center");
          sameItemNoSerials
            .eq(0)
            .find("[name='SerialID']")
            .parents(".table-cell")
            .css({"padding-left": "20px", display: "flex"});
          sameItemNoSerials
            .eq(0)
            .find("[name='Amount[]']")
            .attr("contenteditable", false);
          return;
        } else if (!(await this.allowScannedSerial())) {
          return;
        }
      } else if (!(await this.allowScannedSerial())) {
        return;
      }

      this.addRows(
        this.window.element,
        this.window.output.Data.Columns,
        data.DiSerialInfo,
        data.Metadata,
        this,
        window,
      );

      this.fixRowNumbers(this.window);

      $(this.window.element).find("[name=scannedSerial]").focus();

      this.calculateTotals(this.window);
      this.updateSelectCount(this.window);
    },
    updateSelectCount: function (window) {
      let rows = this.getRows(window);

      $("[data-select-count]").text(rows.length);
    },

    getRows: function (window) {
      let rows = [];

      const getRow = function (el, window) {
        const getTypes = function (window) {
          let columnInfo = {};
          if (window.output.Table?.Columns) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (let info of window.output.Table?.Columns) {
              columnInfo[info.Name] = info;
            }
          }
          return columnInfo;
        };

        let data = {};
        let dirty = false;
        let dimensions = [];
        const columnInfo = getTypes(window);

        const dateTimeColumns = [
          "DateTimeExpectedStart",
          "DateTimeExpectedEnd",
          "DateTimeBusinessStart",
          "DateTimeBusinessEnd",
        ];

        for (const dateTimeColumn of dateTimeColumns) {
          columnInfo[dateTimeColumn] = {
            ...columnInfo[dateTimeColumn],
            Name: dateTimeColumn,
            Type: "DateTime",
          };
        }

        $(el)
          .find("[name]")
          .each(function () {
            let name = $(this).attr("name").split(/\[|\]/g).shift();
            if (!name) {
              return;
            }

            let value = !$(this).is("input") ? $(this).text() : $(this).val();

            if (
              (name === "TotalVATForeignCurrency" ||
                name === "TotalIncVATForeignCurrency" ||
                name === "TotalExcVATForeignCurrency") &&
              value === ""
            ) {
              value = 0;
            }

            if (name == "SubStatus") {
              if (value == "") {
                value = $(this).data("override-value");
              }
            } else if (typeof $(this).data("override-value") != "undefined") {
              value = $(this).data("override-value");
            }

            if (value) {
              dirty = true;
            }

            if (value == undefined) {
              value = "";
            }

            if (value.indexOf && value.indexOf("OBJECTDEF:") != 0) {
              let info = columnInfo[name] || {};

              if (
                info.Type === "Decimal" ||
                info.Type === "Money" ||
                info.Type === "Money"
              ) {
                value = value != "" ? Number(value) : null;
              } else if (info.Type === "DateTime" || info.Type === "Date") {
                let parsedValue = value
                  ? dayjs(value, "DD-MM-YYYY").format("YYYY-MM-DD")
                  : null;

                // Sometimes the value is already formatted as YYYY-MM-DD

                if (parsedValue !== undefined && parsedValue !== null) {
                  value = parsedValue;
                } else {
                  value = null;
                }
              } else if (info.Type === "Boolean") {
                value = $(this).is(":checked");
              } else {
                value =
                  value ||
                  (value === false || value === "false" ? false : null);
                value = typeof value === "string" ? value?.trim() : value;
              }
            } else if (value.slice) {
              value = JSON.parse(value.slice("OBJECTDEF:".length));
            }

            data[name] = value;

            if (name.indexOf("DimensionID-") !== -1 && value != null) {
              let dimension = {DimensionObjectID: value};
              dimensions.push(dimension);
              delete data[name];
              data.Dimensions = dimensions;
            }
          });

        if (
          !data.DateTimeExpectedStart &&
          window.output.Data.DateTimeExpectedStart
        ) {
          data.DateTimeExpectedStart = unixMilisecondsToDateString(
            window.output.Data.DateTimeExpectedStart,
          );
        }

        if (
          !data.DateTimeExpectedEnd &&
          window.output?.Data?.DateTimeExpectedEnd
        ) {
          data.DateTimeExpectedEnd = unixMilisecondsToDateString(
            window.output.Data.DateTimeExpectedEnd,
          );
        }
        return dirty && data;
      };
      $(window.element)
        .find(".table-body .table-row-group")
        .each(function () {
          if (
            (window.output.Data?.CanAddNewItems &&
              $(this).is(".table-row-group:last-child")) ||
            !$(this).find(".table-row").is(".added-row")
          ) {
            return;
          }
          rows = rows.concat(getRow(this, window) || []);
        });
      return rows;
    },

    getRowData(window) {
      let rows = this.getRows(window);
      let liRowData = [];
      for (let row of rows) {
        if (row.Status === "Open") {
          for (let key in row) {
            if (
              row[key] &&
              row[key].indexOf &&
              row[key].indexOf("/Date(") === 0
            ) {
              row[key] = dayjs
                .unix(Formatter.unixMilisecondsToUnix(row[key]))
                .format("YYYY-MM-DD HH:mm:ss");
            }

            if (row[key] === "on") {
              row[key] = true;
            }

            if (row[key] === "off") {
              row[key] = false;
            }
          }
          if (window.output.Table?.Columns) {
            for (let column of window.output.Table.Columns) {
              if (column.IsRequired) {
                // dimension specific logic, because when an dimension value is entered, a dimensions object is created instead of copying the column to the row with a value
                if (
                  // eslint-disable-next-line no-prototype-builtins
                  row.hasOwnProperty(column.Name) &&
                  column.Name.indexOf("DimensionID-") === 0
                ) {
                  let requiredFieldMessage =
                    window.session.translations.ExceptionRequiredFieldNotGiven;
                  window.message(
                    "error",
                    requiredFieldMessage.replace("{}", column.Title),
                  );
                  return;
                }
              }
            }
          }
          liRowData.push(row);
        }
      }

      return liRowData;
    },

    sumTotal: function (window, name) {
      let total = 0;

      $(window.element)
        .find("[name='" + name + "[]'], [name='" + name + "']")
        .each(function () {
          total +=
            Number($(this).is("input") ? $(this).val() : $(this).text()) || 0;
        });

      return total;
    },
    calculateTotals: function (window) {
      $("[data-total-price]").text(
        this.sumTotal(window, "TotalExcVAT").toFixed(2),
      );
      $("[data-total-btw]").text(
        Math.max(
          0,
          this.sumTotal(window, "TotalIncVAT") -
            this.sumTotal(window, "TotalExcVAT"),
        ).toFixed(2),
      );
      $("[data-total-btw-price]").text(
        this.sumTotal(window, "TotalIncVAT").toFixed(2),
      );
    },
    selectOption: async function (event) {
      let requestData = this.opts;
      const subject = global.session.activeWindow.output.Request.Subject;

      // .view-quickrent [name='CustomerID']
      const customerID = $(".active-window")
        .find(".view-quickrent")
        .find("[name='CustomerID']")
        .val();

      if (subject === "Rental.virtual_QuickRent" && !customerID) {
        notify({
          type: "warning",
          message:
            this.translations["Rental.Order.QuickRent.CustomerID-not-selected"],
        });
        return;
      }

      // .view-quickrent [name='Period']
      const Period = $(".active-window")
        .find(".view-quickrent")
        .find("[name='Period']");

      if (
        this.$store.state.settings.PeriodSelection ===
          "ItemSinglePeriodCalculateEndDateEnforced" ||
        this.$store.state.settings.PeriodSelection ===
          "ItemSinglePeriodEnforced"
      ) {
        if (Period.length > 0) {
          const PeriodValue = Period.val();

          if (subject === "Rental.virtual_QuickRent" && !PeriodValue) {
            notify({
              type: "warning",
              message:
                this.translations["Rental.Order.QuickRent.Period-not-selected"],
            });
            return;
          }
        }

        // .view-quickrent [name='PeriodAmount']
        const PeriodAmount = $(".active-window")
          .find(".view-quickrent")
          .find("[name='PeriodAmount']");

        if (PeriodAmount.length > 0) {
          const PeriodAmountValue = PeriodAmount.val();

          if (subject === "Rental.virtual_QuickRent" && !PeriodAmountValue) {
            notify({
              type: "warning",
              message:
                this.translations[
                  "Rental.Order.QuickRent.PeriodAmount-not-selected"
                ],
            });
            return;
          }
        }
      }

      $('div[class="col col-xs-12 col-sm-6 col-md-3"]')
        .find(".combobox input")
        .each((index, element) => {
          if (element.value !== "" && element.name !== "") {
            requestData.headerData[element.name] = element.value;
          }
        });

      let serialID = event.value ?? this.searchValue;

      requestData.headerData = getHeader();

      requestData.liRowData = this.getRowData(this.window);

      requestData.scannedSerial = serialID?.replace(
        this.$store.state.settings.ScanFieldFilterValue,
        "",
      );

      const result = await getSerialInfo({opts: requestData});
      if (!result) return;

      let processResult = null;

      if (result.Rows?.length) {
        processResult = await this.processScanResult(
          result,
          requestData.scannedSerial,
        );
      }

      this.selectedValue = "";
      this.$refs.scanbox.$refs.search.focus();

      if (processResult?.serialAlreadyOnOrder === true) {
        return;
      }
      this.filter.options = [];
      if (result.IsActionResponse) {
        this.window.handleActionResponse(result);
        return;
      }
      this.adjustDivWidths(global.session.activeWindow.element);
    },
    addRows(
      container,
      columnInfo,
      DiSerialInfo,
      metadata,
      source,
      window,
      initialData,
    ) {
      const amountMultiplier =
        $(this.window.element).find("[name='Amount']").val() || 1;

      for (let i = 0; i < DiSerialInfo.length; i++) {
        let row = DiSerialInfo[i];
        const amount = row.SerialID ? 1 : (amountMultiplier ?? 0);
        row.Amount = row.Amount * amount;
        row.TotalExcVAT = row.TotalExcVAT * amount;
        row.TotalVAT = row.TotalVAT * amount;
        row.TotalIncVAT = row.TotalIncVAT * amount;
        row.TotalExcVATForeignCurrency =
          row.TotalExcVATForeignCurrency * amount;
        row.TotalVATForeignCurrency = row.TotalVATForeignCurrency * amount;
        row.TotalIncVATForeignCurrency =
          row.TotalIncVATForeignCurrency * amount;

        if (
          row.Amount &&
          String(row.Amount).indexOf(",") !== -1 &&
          String(row.Amount).indexOf(".") === -1
        ) {
          row.Amount = row.Amount?.replace(/,/g, ".");
        }

        let $lastRow = $(container).find(".table-row-group:last-child");
        let $newRow = $lastRow.clone();
        let $placerow =
          source && $(source).attr("name") === "ItemID[]"
            ? $(source).closest(".table-row-group")
            : $lastRow;

        this.fillInputs(
          $newRow.get(0),
          row,
          Formatter.applyMetadataRow(columnInfo, metadata && metadata[i]),
          window,
          initialData,
        );

        $newRow.find(".dropdown.combobox").each(function () {
          // Create new combobox object for every new row, every dropdown
          let previousCb = ComboboxUI.getClass($(this));

          let newCb = Combobox.new(null, {
            name: previousCb.specification.name,
            nullable: previousCb.specification.nullable,
            type: previousCb.specification.type,
            tableName: previousCb.specification.tableName,
            columnName: previousCb.specification.columnName,
            openRef: previousCb.specification.openRef,
            items: previousCb.specification.items,
          });

          if (newCb.specification.items) {
            newCb.populate(newCb.specification.items);
          }

          newCb.startingValue = $(this).find("input.combobox-input").val();

          $(this).attr("data-id", newCb.id);
        });

        // $newRow.find(".selection-box input").prop("checked", true)
        $newRow
          .find(".table-row")
          .removeClass("new-row")
          .addClass("added-row")
          .addClass("dirty-row");
        $newRow.insertBefore($placerow);
      }

      // Remove 'dirty-row', all new rows have been created
      $(container).find(".dirty-row").removeClass("dirty-row");
      this.adjustDivWidths(global.session.activeWindow.element);
      this.correctTableHeaders(window);
    },
    adjustDivWidths(rootElement) {
      // Object to keep track of the max width for each name
      let maxWidths = {};
      // Ensure rootElement is a jQuery object
      rootElement = $(rootElement);
      // Iterate over the div elements within .table-cell class
      rootElement.find("div[data-name].table-cell").each(function () {
        let $this = $(this);

        let name = $this.attr("data-name");
        let width = $this.outerWidth(); // Get the current width
        // Update max width if this element is wider
        if (!maxWidths[name] || width > maxWidths[name]) {
          maxWidths[name] = width;
        }
      });
      // Iterate over the div elements again, setting the min-width to the max found
      rootElement.find("div[data-name].table-cell ").each(function () {
        let $this = $(this);
        let name = $this.attr("data-name");
        $this.css("min-width", maxWidths[name]);
      });
    },
    fillInputs: function (el, row, columnInfo, window, initialData) {
      let fullReadOnly =
        Object.keys(columnInfo || {}).filter((x) => !columnInfo[x].IsReadOnly)
          .length == 0;
      $(el).find(".delete-row").toggleClass("disabled", fullReadOnly);
      $(el).find(".table-row").removeClass("new-row").addClass("added-row");

      let supportedNames = [];

      // Fills each cell in the table for bulk edit screens
      $(el)
        .find("[name]")
        .each(function () {
          let name = $(this).attr("name").replace(/\[\]$/, "");

          if (!name && $(this).find("[name='SerialID']").length > 0) {
            name = $(this).find("[name='SerialID']").attr("name");

            if (columnInfo[name] && columnInfo[name].Status) {
              $(this).prepend(
                getStatusIcon(
                  columnInfo[name].Status.Type,
                  columnInfo[name].Status.Description,
                ),
              );

              $(this).css({"padding-left": "20px", display: "flex"});
            }
            return;
          }

          if (
            !name ||
            (name.indexOf("DimensionID-") != -1 && $(this).attr("value") != "")
          ) {
            // if no name is found, or if the name is DimensionID AND it already has a value, do not overwrite.
            return;
          }

          let column = columnInfo[name] || {};
          let value =
            (row[name] != null && typeof row[name] == "object"
              ? "OBJECTDEF:" + JSON.stringify(row[name])
              : Formatter.parseValue(column, row[name])) || String();

          supportedNames.push(name);

          if (name == "Composition" && value.endsWith(" (0)")) {
            // if composition ends with (0), remove (0) and use an unused number
            value = value.slice(0, " (0)".length * -1);
            let counter = 0;
            let $rowsWithThisComposition = null;

            do {
              counter = counter + 1;

              $rowsWithThisComposition = $(window.element)
                .find(".table-row:not(.dirty-row)")
                .parent();

              $rowsWithThisComposition = $rowsWithThisComposition
                .find(":contains('" + value + " (" + counter + ")" + "')")
                .add(
                  $rowsWithThisComposition
                    .find("[name='Composition']")
                    .filter(function () {
                      return this.value == value + " (" + counter + ")";
                    }),
                );
            } while ($rowsWithThisComposition[0]);

            value = value + " (" + counter + ")";
            if (row["CompositionRanking"] != null) {
              row["CompositionRanking"] = counter;
            }
          }

          if ($(this).parent().is(".combobox")) {
            $(this)
              .parent()
              .find(".combobox-input")
              .val(column.Description || value);

            $(this)
              .parent()
              .toggleClass("disabled", column.IsReadOnly === true);
            $(this)
              .parent()
              .find(".combobox-input")
              .prop("disabled", column.IsReadOnly);
          }

          // Set min and max value
          if (column.MinNumber) {
            $(this).attr("min", column.MinNumber);
          }
          if (column.MaxNumber) {
            $(this).attr("max", column.MaxNumber);
          }

          // Set Custom class
          if (column.CustomClass) {
            $(this).addClass(column.CustomClass);
          }

          // Set's the value and text on the cells
          if (
            column.Dropdown != null &&
            column.Dropdown.Items != null &&
            value != ""
          ) {
            $(this).data("override-value", value);
            let description = (
              column.Dropdown.Items.filter((x) => x.Value == value)[0] || {}
            ).Text;
            $(this).text(description);
          } else if ($(this).is("input") && $(this)[0].type === "checkbox") {
            $(this).prop("checked", value);
            $(this).prop("disabled", Boolean(column.IsReadOnly));
          } else if ($(this).is("input")) {
            $(this).val(value);
            $(this).prop("disabled", Boolean(column.IsReadOnly));
          } else {
            // If we have a status property in our metadata for this cell, we append a status button to the value
            if (
              typeof columnInfo[name].Status !== "undefined" &&
              columnInfo[name].Status
            ) {
              if (column.Dropdown != null) {
                $(this).data("override-value", value);
                $(this).prepend(
                  getStatusIcon(
                    columnInfo[name].Status.Type,
                    columnInfo[name].Status.Description,
                  ),
                );
                $(this).parents(".table-cell").addClass("flex items-center");
                $(this).css({"padding-left": "20px", display: "flex"});
              } else {
                $(this).html(value);
                $(this).parents(".table-cell").addClass("flex items-center");
                $(this).prepend(
                  getStatusIcon(
                    columnInfo[name].Status.Type,
                    columnInfo[name].Status.Description,
                  ),
                );
              }
            } else {
              $(this).text(value);
            }
            $(this).prop("contenteditable", !column.IsReadOnly);
          }

          $(this).data("server-value", value);

          if (initialData) {
            $(this).data("initial-data", true);
          }
        });

      // Fill row cells with data
      for (let name in row) {
        if (supportedNames.indexOf(name) === -1) {
          let value =
            (row[name] != null && typeof row[name] == "object"
              ? "OBJECTDEF:" + JSON.stringify(row[name])
              : Formatter.parseValue(columnInfo[name] || {}, row[name])) ||
            String();
          $(el).append(
            $("<input>").prop("type", "hidden").prop("name", name).val(value),
          );
          if (name === "SerialID") {
            $(el).append(
              "<i data-toggle-remove='data-toggle-remove' class='fas fa-times pull-right'></i>",
            );
          }
        }
      }
    },

    allowScannedSerial: async function () {
      if (this.window.output.Data.CurrentSerialsOnly) {
        let alertObject = {
          text: this.window.session.translations[
            "CurrentSerialsOnlyAppendOrder?"
          ],
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: this.window.session.translations.Cancel,
            confirm: this.window.session.translations.Insert,
          },
        };

        return await alertPopup(alertObject);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../../node_modules/vue-select/src/scss/vue-select";
.v-select {
  .vs__search,
  .vs__search:focus {
    color: #242424 !important;
    line-height: 1.2;
  }

  .vs__dropdown-toggle {
    height: 27px;
    background: white;
    border-radius: 3px;
  }
  .vs__dropdown-menu {
    z-index: 2900;
  }
  .vs__dropdown-option {
    padding: 3px 9px;
  }
  .vs__dropdown-option--highlight {
    background-color: #e1e1e1 !important;
    color: black;
  }

  .vs__open-indicator {
    color: black;
    fill: black;
    transform: scale(0.65);

    width: 15px !important;
  }
}
.form-select,
.form-label-select {
  padding: 0px 8px !important;
}
.form-select {
  margin-top: 8px !important;
  .vs__dropdown-menu {
    min-width: 25vw !important;
    margin-left: 8px;
  }
}
.form-select > .vs__dropdown-toggle {
  height: 31px !important;
}
</style>
