export const setLocalStoragePageSize = ({entity, pageSize}) => {
  const storedPageSizes = JSON.parse(localStorage.getItem("datagridPageSizes"));

  localStorage.setItem(
    "datagridPageSizes",
    JSON.stringify({
      ...storedPageSizes,
      [entity]: pageSize,
    }),
  );
};
