import Hook from "../hook";

import store from "../../state/store";
import dayjs from "dayjs";
import delayedCorrectTableHeaders from "../functions/window/delayedCorrectTableHeaders";

/** Table Hook */
class TableHook2 extends Hook {
  /**
   * Use dates on tables
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    /*
      Filters
    */

    if (
      window.output.Request.Prefix == "Multi" ||
      window.output.Request.Subject == "Core.vw_Tasks"
    ) {
      if (window.output.Options && window.output.Filters != null) {
        window.filters = {
          date: [],
          other: [],
          selectedOthers: 0,
        };

        window.output.Filters = window.output.Filters.map((filter) => ({
          ...filter,
          value: window.output.Request.Data.Filters[filter.Target],
        }));

        if (
          window.output.Filters.filter(function (filter) {
            return (
              filter.Type == "DateEquals" ||
              filter.Type == "Date" ||
              filter.Type == "DateFrom" ||
              filter.Type == "PeriodStart"
            );
          }).length > 0
        ) {
          window.filters.date.push({
            text: window.session.translations.FilterDateStart,
            suffix: ">=",
            value: this.formatFilterValue(
              window.output.Request.Data.Filters[">="],
            ),
          });
        }

        if (
          window.output.Filters.filter(function (filter) {
            return (
              filter.Type == "Date" ||
              filter.Type == "DateTo" ||
              filter.Type == "PeriodEnd"
            );
          }).length > 0
        ) {
          window.filters.date.push({
            text: window.session.translations.FilterDateEnd,
            suffix: "<=",
            value: this.formatFilterValue(
              window.output.Request.Data.Filters["<="],
            ),
          });
        }
      }
    }
  }

  formatFilterValue(value) {
    if (value != null) {
      value = dayjs(value).format("DD-MM-YYYY");
    }

    return value;
  }
}

class TableHook extends Hook {
  async afterRender(window) {
    //await new Promise(resolve => setTimeout(resolve, 1000))
    if (window.output.Request.Prefix === "Multi") {
      global.session.canvas.correctTableHeaders(window.element);

      store.dispatch("initializeTableElements", {window});
      // get element with class search-input-container in the window.element
      const searchInputContainer = window.element?.querySelector(
        ".search-input-container",
      );
      // get first input field found in searchInputContainer
      const searchInput = searchInputContainer?.querySelector("input");
      // focus the search input field
      searchInput?.focus();
      delayedCorrectTableHeaders(window);
    }
  }
  async afterFocus(window) {
    if (
      window.output.Request.Prefix === "Multi" ??
      window.output.Data?.Type === "table"
    ) {
      // get element with class search-input-container in the window.element

      const searchInputContainer = window.element?.querySelector(
        ".search-input-container",
      );
      // get first input field found in searchInputContainer
      const searchInput = searchInputContainer?.querySelector("input");
      // focus the search input field
      searchInput?.focus();
      delayedCorrectTableHeaders(window);
    }
  }
}

export default [new TableHook(), new TableHook2()];
