import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Creates a list of default planning DTOs
 *
 * @param {Array} defaultPlanningList - The list of default planning DTOs to create
 * @param {Object} defaultPlanningList[].DefaultPlanningResourceID - The ID of the default planning resource
 * @param {string} defaultPlanningList[].Day - The day in ISO format (e.g., "2025-03-05T12:31:04.183Z")
 * @param {string} defaultPlanningList[].StartTime - The start time
 * @param {string} defaultPlanningList[].EndTime - The end time
 * @param {string} defaultPlanningList[].WarehouseID - The ID of the warehouse
 * @returns {Promise<Array>} - Promise containing the created default planning DTOs with assigned DefaultPlanningIDs
 */
export const createDefaultPlanning = async (defaultPlanningList) => {
  return axiosInstanceWithErrorHandling.post(
    "/api/v2/warehouse/default-planning",
    defaultPlanningList,
  );
};
