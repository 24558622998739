import {convertQuotationToOrder} from "./convertQuotationToOrder";
import {getQuotationItems} from "../../../services/quotations/getQuotationItems";
import {getTranslations} from "../../session/localstorage/getTranslations";
import {getQuotation} from "../../../services/quotations/getQuotation";
import {getSettings} from "../../../util/getSettings";
import openDialog from "../../../actions/openDialog";
import store from "../../../../state/store";
import dayjs from "dayjs";

export const openConvertQuotationToOrderDialog = async ({window}) => {
  const translations = getTranslations();
  const {DisplayInvoiceDates, EndDateRequired} = getSettings();

  const quotationId = window.output.Request.Criteria[0].QuotationID;
  let [quotationResponse, quotationItemResponse] = await Promise.all([
    getQuotation({quotationId}),
    getQuotationItems({quotationId}),
  ]);

  const quotationItems = quotationItemResponse?.data?.Collection;
  const quotation = quotationResponse?.data;

  const quotationItemsHaveDeviatingDates =
    quotationItems?.length > 0
      ? !quotationItems.every((row) =>
          dayjs(row.DateTimeExpectedStart).isSame(
            dayjs(quotation.DateTimeExpectedStart),
            "day",
          ),
        )
      : false;

  const dialogData = {
    RawTitle: "ConvertQuotationToOrder",
    Data: {
      Actions: [
        {
          RawTitle: "Cancel",
          ButtonVariant: "secondary",
          Callback: () => {
            store.commit("dialog/hideDialog");
          },
        },
        {
          RawTitle: "Save",
          ButtonVariant: "primary",
          Callback: async (dialogData) => {
            await convertQuotationToOrder({
              dialogData,
              quotationId,
              windowId: window.id,
            });
            store.commit("dialog/hideDialog");
          },
        },
      ],
      Fields: [
        {
          Name: "DateTimeExpectedStart",
          RawTitle: "DateTimeExpectedStart",
          IsRequired: true,
          Type: "Date",
          Value: quotation.DateTimeExpectedStart,
        },
        {
          Name: "DateTimeExpectedEnd",
          RawTitle: "DateTimeExpectedEnd",
          IsRequired: false,
          Type: "Date",
          Value: quotation.DateTimeExpectedEnd,
        },
      ],
    },
  };

  if (DisplayInvoiceDates)
    dialogData.Data.Fields.push(
      {
        Name: "DateTimeBusinessStart",
        RawTitle: "DateTimeBusinessStart",
        IsRequired: true,
        Type: "Date",
        Value: quotation.DateTimeBusinessStart,
      },
      {
        Name: "DateTimeBusinessEnd",
        RawTitle: "DateTimeBusinessEnd",
        IsRequired: EndDateRequired,
        Type: "Date",
        Value: quotation.DateTimeBusinessEnd,
      },
    );

  if (quotationItemsHaveDeviatingDates)
    dialogData.Data.Fields.push({
      Name: "CopyDatesToQuotationItems",
      RawTitle: "ModifyDeviatingDates",
      Description: translations.AlertDeviatingQuotationItemDates,
      Type: "Boolean",
      Value: false,
    });

  openDialog(dialogData);
};
