<template>
  <ul>
    <li @click="toggleVisiblity()">
      {{ menuItem.Text }}
    </li>

    <ul>
      <li
        v-for="menuItem of subSubMenuItems"
        :key="menuItem.ID"
        class="menu-sub-item"
        :data-menu-id="menuItem.ID"
        :data-value="menuItem.Value"
        :data-text="menuItem.Text"
        @click="$store.commit('hideSubMenu')"
      >
        {{ menuItem.Text }}
      </li>
    </ul>
  </ul>
</template>

<script>
export default {
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    subSubMenuItems() {
      return this.menuItem.children.filter((menuItem) => {
        if (
          this.$store.getters.settings.CapacityEntity ===
          "CapacityEntityResource"
        ) {
          return menuItem;
        }
        return (
          menuItem.Value !==
          "table:Warehouse.DefaultPlanning;prefix:Calendar;criteria.0.DefaultPlanningID:-1"
        );
      });
    },
  },
  methods: {
    toggleVisiblity: function () {
      if (this.visible) {
        this.visible = false;
        return;
      }

      this.visible = true;
    },
  },
};
</script>

<style scoped lang="scss">
.menu-sub-item {
  padding-left: 20px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s;
}

.menu-sub-item:hover {
  color: #f26522 !important;
}

ul {
  list-style: none;
  padding-left: 0px;
  li {
    font-size: 16.5px;
    padding: 5px 15px;

    font-weight: 600;
  }
}

.smooth-enter-active,
.smooth-leave-active {
  transition: height 0.5s;
  -webkit-transition: all 0.5s ease;
  overflow: hidden;
}
.smooth-enter,
.smooth-leave-to {
  height: 0;
}
</style>
