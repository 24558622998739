import { render, staticRenderFns } from "./DatagridRow.vue?vue&type=template&id=0a55677a&scoped=true&"
import script from "./DatagridRow.vue?vue&type=script&lang=js&"
export * from "./DatagridRow.vue?vue&type=script&lang=js&"
import style0 from "./DatagridRow.vue?vue&type=style&index=0&id=0a55677a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a55677a",
  null
  
)

export default component.exports