import {api} from "@/services/api";

export default async function getQuotationItemsConditionStockPrediction({
  quotationItems,
  quotationId,
}) {
  return await api.post(
    `/v2/quotations/${quotationId}/_stock-prediction/conditions`,
    quotationItems,
  );
}
