import {fieldContainsExtraCriteria} from "../../form/fieldContainsExtraCriteria.js";
import {fieldCriteriaIsLinkedToChangedField} from "./fieldCriteriaIsLinkedToChangedField.js";
import {processDialogLinkedFormFieldFromCriteria} from "./processDialogLinkedFormFieldFromCriteria.js";

export const processDialogLinkedFieldsExtraCriteria = async ({
  fields,
  changedField,
}) => {
  let newFields = [...fields];

  for (const field of fields) {
    if (
      fieldContainsExtraCriteria({field}) &&
      fieldCriteriaIsLinkedToChangedField({field, changedField})
    )
      newFields = await processDialogLinkedFormFieldFromCriteria({
        fields,
        changedField,
        linkedField: field,
      });
  }

  return newFields;
};
