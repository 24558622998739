import dayjs from "dayjs";

export const rowsSetDateTimePickedToNow = ({rows}) => {
  return rows.map((row) => {
    // Ensure the row is picked
    if (!row.PickedQuantity.Value > 0) return row;
    return {
      ...row,
      DateTimePicked: {
        Name: "DateTimePicked",
        Value: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      },
    };
  });
};
