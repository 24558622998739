import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";

export function handleSubStatusOk({row}) {
  const newRow = {...row};
  if (getRawValueFromDropdownValue(newRow.SubStatus?.Value) === "OK") {
    const exemptedColumns = [
      "Notes",
      "Ranking",
      "DateTimeRentalStart",
      "DateTimeBusinessStart",
      "DateTimeBusinessEnd",
      "DateTimeExpectedEnd",
      "Period",
      "PeriodAmount",
      "PeriodPrice",
      "VATCodeID",
      "Discount",
      "VisibilityPolicy",
      "PriceCalcReason",
      "ItemDescription",
    ];

    const isStockItem =
      newRow.PhysicalItem?.Value?.StockType === "BulkStock" ||
      newRow.PhysicalItem?.Value?.StockType === "Serial";

    if (
      !isStockItem ||
      getRawValueFromDropdownValue(newRow.ItemType.Value) === "Deposit"
    ) {
      exemptedColumns.push(
        "WarehouseID",
        "ReturnWarehouseID",
        "ThemeGroupID",
        "ThemeGroupDescription",
      );
    }

    for (const column of Object.values(newRow)) {
      if (column && !exemptedColumns.includes(column.Name)) {
        column.IsReadOnly = true;
      }
    }
  }
  return newRow;
}
