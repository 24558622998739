import {setClientSideUUIDToRows} from "../rows/setClientSideUUIDToRows.js";
import {convertKeyValueRowsToCellRows} from "../rows/convertKeyValueRowsToCellRows.js";
import {getTranslations} from "../../session/localstorage/getTranslations.js";
import {groupReturnItemRows} from "./groupReturnItemRows";

export function processReturnItemRowsMetaData({rows, vueComponent}) {
  const translations = getTranslations();

  rows = convertKeyValueRowsToCellRows(rows);
  rows = setClientSideUUIDToRows({rows: rows});
  rows = groupReturnItemRows({rows, vueComponent});

  return rows.map((row) => {
    if (row.rowMeta?.virtual) return row;

    const incrementAmountOnScan =
      vueComponent?.OnlyAutoCheckIfAmountEqualsAmountToReturn &&
      !row?.IsSerial.Value;

    const rowHasServiceCheckbox =
      "ServiceBeforeReturnType" in row &&
      row.ServiceBeforeReturnType?.Value !== 0
        ? {
            ShowCheckbox: true,
            Icon: "fa-tools",
            IconColor: "black",
            IconMessage: translations.ExecuteService,
          }
        : {ShowCheckbox: false};

    return {
      ...row,
      Checked: {
        Name: "Checked",
        Value: row.Checked?.Value ?? false,
        DisableDefaultCheckboxBehaviour: true,
      },
      DateTimeRentalEnd: {
        Name: "DateTimeRentalEnd",
        Value: row.DateTimeRentalEnd?.Value,
        OriginalValue: row.DateTimeRentalEnd?.Value,
        CellStyle: null,
      },
      DateTimeRentalStart: {
        ...row.DateTimeRentalStart,
        Value:
          row.DateTimeRentalStart.Value ??
          row.DateTimeBusinessStart.Value ??
          null,
      },
      Amount: {
        ...row.Amount,
        IsReadOnly: incrementAmountOnScan ? false : row.IsSerial.Value,
        Value: incrementAmountOnScan ? 0 : row.Amount.Value,
        Editor: row.IsSerial?.Value ? "Int" : "Decimal",
      },
      MaxAmountToReturn: {
        Name: "MaxAmountToReturn",
        Value: row.Amount.Value,
      },
      Counter: {
        ...row.Counter,
        Value: row?.MinCounterValue.Value ?? null,
        IsRequired: row.IsCounter.Value,
        IsReadOnly: !row.IsCounter.Value,
      },
      PerformServiceBeforeReturn: {
        ...row.PerformServiceBeforeReturn,
        ...rowHasServiceCheckbox,
        DisableDefaultCheckboxBehaviour: true,
        IsReadOnly: row.ServiceBeforeReturnType?.Value === 2,
        Value: row.ServiceBeforeReturnType?.Value === 2,
      },
    };
  });
}
