import {cloneDeep} from "lodash";
import templateMapping from "../../interface/templater/templateMapping";

const templateMappings = templateMapping;

export const convertLegacyWindowToRWindow = ({
  id,
  sub,
  output,
  tabs,
  vueComponent,
  actionMapping,
  customTitle,
  parent,
  data,
  jobs,
  initialData,
}) => {
  function findTemplateMapping(templateMappings, output) {
    // Safely access potentially undefined properties
    const outputType = output?.Data?.Type;
    const requestSubject = output?.Request?.Subject;
    const requestPrefix = output?.Request?.Prefix;

    for (const template of templateMappings) {
      // Condition 1: Direct match on Type
      if (outputType && template.template === outputType) {
        return template;
      }

      // Condition 2: Matches both Subject and Prefix
      if (
        template.Subject === requestSubject &&
        template.Prefix?.includes(requestPrefix)
      ) {
        return template;
      }

      // Condition 3: Wildcard Subject match with Prefix, but not in excluded subjects
      if (
        template.Prefix?.includes(requestPrefix) &&
        template.Subject === "*" &&
        !template.ExcludedSubjects?.includes(requestSubject)
      ) {
        return template;
      }
    }

    // No matching template found
    return undefined;
  }

  // Usage
  const templateMapping = findTemplateMapping(templateMappings, output);

  const getInitialData = ({initialData, output}) => {
    if (initialData && initialData.Rows?.length) {
      return initialData;
    }
    return cloneDeep(output?.Data);
  };

  const subWindowId = sub?.window?.id;
  const parentWindowId = parent?.id;
  const windowTitle =
    parent?.customTitle ?? parent?.output?.Title ?? customTitle ?? output.Title;

  const rows = data?.Rows?.length ? data?.Rows : (output?.Data?.Rows ?? []);

  return {
    id,
    title: windowTitle,
    buttons: output?.Buttons,
    filters: output?.Filters,
    fullTable: output?.FullTable,
    criteria: output?.Request?.Criteria,
    prefix: output?.Request?.Prefix,
    subject: output?.Request?.Subject,
    tabs,
    subWindowId,
    jobs: jobs ?? [],
    parentWindowId,
    request: output.Request,
    data: {
      ...output.Data,
      Rows: rows,
    },
    initialData: getInitialData({initialData, output}),
    actionMapping,
    vueComponent: templateMapping
      ? templateMapping.vueComponent
      : (vueComponent ?? null),
  };
};
