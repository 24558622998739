import {fieldContainsExtraCriteria} from "../../form/fieldContainsExtraCriteria.js";
import {processDialogChangedFieldExtraCriteria} from "./processDialogChangedFieldExtraCriteria.js";
import {processDialogLinkedFieldsExtraCriteria} from "./processDialogLinkedFieldsExtraCriteria.js";

export const processDialogFormExtraCriteria = async ({
  fields,
  changedField,
}) => {
  const changedFieldHasExtraCriteria = fieldContainsExtraCriteria({
    field: changedField,
  });

  if (changedFieldHasExtraCriteria)
    return await processDialogChangedFieldExtraCriteria({fields, changedField});

  if (!changedFieldHasExtraCriteria)
    return await processDialogLinkedFieldsExtraCriteria({fields, changedField});
};
