import {sumKeysFromArray} from "../../../sumKeysFromArray";
import dayjs from "dayjs";

export const handlePickedQuantityDecrement = ({
  row,
  trackedBulkPicksFromRow,
  totalPicked,
}) => {
  const sortedBulkPicks = sortArrayOnDates(trackedBulkPicksFromRow);
  let newTotalPicked = totalPicked;

  for (let i = 0; i < sortedBulkPicks.length; i++) {
    const bulkPickQuantity = Number(Object.keys(sortedBulkPicks[i])[0]);

    if (newTotalPicked === row.PickedQuantity.Value) break;

    if (newTotalPicked - bulkPickQuantity >= row.PickedQuantity.Value) {
      row.TrackedBulkPicks.Value.pop();
      newTotalPicked -= bulkPickQuantity;
      continue;
    }

    if (newTotalPicked - bulkPickQuantity < row.PickedQuantity.Value) {
      const sumToSubtract = newTotalPicked - row.PickedQuantity.Value;

      const lastElement = row.TrackedBulkPicks.Value.pop();
      const lastElementKey = Number(Object.keys(lastElement)[0]);
      const lastElementValue = Object.values(lastElement)[0];
      const newQuantity = lastElementKey - sumToSubtract;
      row.TrackedBulkPicks.Value.push({
        [newQuantity]: lastElementValue,
      });

      newTotalPicked = sumKeysFromArray(row.TrackedBulkPicks.Value);
    }
  }

  return row;
};

const sortArrayOnDates = (array) => {
  return [...array].sort((a, b) => {
    const dateA = Object.values(a)[0];
    const dateB = Object.values(b)[0];
    return dayjs(dateB).diff(dayjs(dateA));
  });
};
