import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";
import {getSettings} from "../../../../util/getSettings";

export const handleAmountChange = ({row, oldRow}) => {
  const {UseLocations} = getSettings();
  const isNonSerialSalesItem =
    getRawValueFromDropdownValue(row.ItemType.Value) === "Sales" &&
    !row.UseSerials.Value;

  if (isNonSerialSalesItem && row.Amount.Value <= 0)
    return UseLocations
      ? row
      : {
          ...row,
          SubStatus: {
            ...row.SubStatus,
            Value:
              typeof row.SubStatus.Value === "object" &&
              "Key" in row.SubStatus.Value
                ? {...row.SubStatus.Value, Key: "OK"}
                : "OK",
          },
        };

  if (isNonSerialSalesItem && oldRow.Amount.Value < 0)
    return {
      ...row,
      SubStatus: {
        ...row.SubStatus,
        Value:
          typeof row.SubStatus.Value === "object" &&
          "Key" in row.SubStatus.Value
            ? {...row.SubStatus.Value, Key: "Need to pick"}
            : "Need to pick",
      },
    };

  if (row.Amount.Value <= 0)
    return {
      ...row,
      Amount: {
        ...row.Amount,
        Value: 1,
      },
    };

  return row;
};
