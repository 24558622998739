import {bulkCapacityMutation} from "./bulkCapacityMutation";
import {getDialogFieldValues} from "../getDialogFieldValues";
import {getTranslations} from "../../session/localstorage/getTranslations";
import {getSettings} from "../../../util/getSettings";
import {alertPopup} from "../../../interface/alertPopup/alertPopup";
import openDialog from "../../../actions/openDialog";
import store from "../../../../state/store";

export const openBulkCapacityMutationDialog = async ({window}) => {
  const translations = getTranslations();
  const selectedBusinessMoments = window.selection;
  const {CapacityEntity} = getSettings();

  if (CapacityEntity === null) {
    alertPopup({
      text: translations.NoCapacityEntity,
      icon: "warning",
    });
    return false;
  }
  if (selectedBusinessMoments?.length < 1) {
    alertPopup({
      text: translations.AdminDesktop_AlertNoRowsSelected,
      icon: "warning",
    });
    return false;
  }

  const mutationTypes = [
    {Text: translations.IncreaseCapacity, Value: "IncreaseCapacity"},
    {Text: translations.DecreaseCapacity, Value: "DecreaseCapacity"},
  ];

  const dialogData = {
    RawTitle: "BulkCapacityMutation",
    Data: {
      Actions: [
        {
          RawTitle: "Cancel",
          ButtonVariant: "secondary",
          Callback: () => {
            store.commit("dialog/hideDialog");
          },
        },
        {
          RawTitle: "Save",
          ButtonVariant: "primary",
          Callback: async (dialogData) => {
            const fields = getDialogFieldValues(dialogData);
            await bulkCapacityMutation({
              fields,
              businessMoments: selectedBusinessMoments,
            });
            store.commit("dialog/hideDialog");
            window.reload();
          },
        },
      ],
      Fields: [
        {
          Name: "MutationType",
          RawTitle: "MutationType",
          Description: translations.MutationTypeDescription,
          IsRequired: true,
          Type: "Select",
          Value: null,
          Dropdown: {
            Items: [...mutationTypes],
          },
        },
        {
          Name: "MutationCapacity",
          RawTitle: "Quantity",
          IsRequired: true,
          Type: "Number",
          Value: null,
        },
        {
          Name: "MutationReason",
          RawTitle: "Reason",
          IsRequired: false,
          Type: "String",
          Value: null,
        },
      ],
    },
  };

  openDialog(dialogData);
};
