import {getSerialInfo} from "@/services/rent-web-services/getSerialInfo";
import handleActionResponse from "@/model/window-actions/handleActionResponse";
import {checkServerErrorNotification} from "../datagrid/checkServerErrorNotification";
import {processScanResult} from "../datagrid/processScanResult";

export async function fetchScannedValueRowData({scannedValue, criteria}) {
  const getSerialInfoInput = {
    headerData: {
      RentalType: "",
      CategoryID: "",
    },
    liRowData: [],
    request: {
      Criteria: criteria,
      Data: {
        Search: [],
        Filters: {},
        SortOn: null,
        SortReverse: false,
        SelectedTabActionName: null,
        PageNumber: 1,
        PageSize: null,
        ShowCurrencyToggle: true,
        ToggleCurrency: false,
        ClientCurrency: "EUR",
        ClientCurrencySymbol: "€",
        OldClientCurrency: null,
        ForeignCurrency: null,
      },
      IsSubWindow: false,
      HideEmptyPlaceHolder: true,
      HideDefaultFilters: false,
      Subject: "Rental.virtual_QuickRent",
      TableToSelect: null,
      ParentSubject: "",
      TitleResourceID: "HandOut",
      SubTitleResourceID: null,
      LinkedSubject: null,
      Prefix: "Multi",
      EntityTranslationLanguage: null,
      TableValuedFunctionParameters: [],
      PreviousPostBackValues: null,
      PostBackValues: null,
    },
    rowRanking: null,
    scannedSerial: scannedValue,
  };

  const result = await getSerialInfo(getSerialInfoInput);
  const serverResponse = checkServerErrorNotification({result});

  if (serverResponse === "error") {
    console.error("Error");
  }

  await handleActionResponse(result);

  return processScanResult(result);
}
