<template>
  <tbody class="bg-white divide-y divide-gray-300">
    <template v-for="(row, index) in visibleRows">
      <datagrid-row
        v-if="shouldRenderRow(row)"
        :key="`1-${row.ClientSideUUID.Value}-${index}`"
        :row-columns="columnsPerRow[0]"
        :columns="columns"
        :actions="actions"
        :show-action-column="showActionColumn"
        :row="row"
        :process-context="processContext"
        :index="index"
        :contains-advanced-columns="containsAdvancedColumns"
        :value="visibleRows[index]"
        :extra-rows="columnsPerRow.length"
        :rows="rows"
        @action="$emit('action', $event)"
        @click-row="$emit('click-row', $event)"
        @cell-click="$emit('cell-click', $event)"
        @cell-icon-click="$emit('cell-icon-click', $event)"
        @icon-event="$emit('icon-event', $event)"
        @change="
          processFieldUpdate({
            row,
            index,
            fieldName: $event.Name,
            cell: $event,
            value: $event.Value,
          })
        "
      />
      <datagrid-row
        v-if="columnsPerRow[1] && shouldRenderRow(row)"
        :key="`2-${row.ClientSideUUID.Value}-${index}`"
        :row-columns="columnsPerRow[1]"
        :columns="columns"
        :row="row"
        :process-context="processContext"
        :index="index"
        :value="visibleRows[index]"
        :is-extra-row="true"
        :rows="rows"
        @change="
          processFieldUpdate({
            row,
            index,
            fieldName: $event.Name,
            cell: $event,
            value: $event.Value,
          })
        "
        @click-row="$emit('click-row', $event)"
        @cell-click="$emit('cell-click', $event)"
        @icon-event="$emit('icon-event', $event)"
      />
      <datagrid-row
        v-if="columnsPerRow[2] && shouldRenderRow(row)"
        :key="`3-${row.ClientSideUUID.Value}-${index}`"
        :row-columns="columnsPerRow[2]"
        :columns="columns"
        :row="row"
        :process-context="processContext"
        :index="index"
        :value="visibleRows[index]"
        :is-extra-row="true"
        :rows="rows"
        @change="
          processFieldUpdate({
            row,
            index,
            fieldName: $event.Name,
            cell: $event,
            value: $event.Value,
          })
        "
        @click-row="$emit('click-row', $event)"
        @cell-click="$emit('cell-click', $event)"
        @icon-event="$emit('icon-event', $event)"
      />
      <datagrid-row
        v-if="columnsPerRow[3] && shouldRenderRow(row)"
        :key="`4-${row.ClientSideUUID.Value}-${index}`"
        :row-columns="columnsPerRow[3]"
        :columns="columns"
        :row="row"
        :process-context="processContext"
        :index="index"
        :value="visibleRows[index]"
        :is-extra-row="true"
        :rows="rows"
        @change="
          processFieldUpdate({
            row,
            index,
            fieldName: $event.Name,
            value: $event.Value,
            cell: $event,
          })
        "
        @click-row="$emit('click-row', $event)"
        @cell-click="$emit('cell-click', $event)"
        @icon-event="$emit('icon-event', $event)"
      />
      <datagrid-row-advanced-fields
        v-if="shouldRenderRow(row)"
        :key="`advanced-${row.ClientSideUUID.Value}-${index}`"
        :row="row"
        :index="index"
        :columns="columns"
        :value="visibleRows[index]"
        :process-context="processContext"
        @change="
          processFieldUpdate({
            row,
            index,
            cell: $event,
            fieldName: $event.Name,
            value: $event.Value,
          })
        "
      />
    </template>
    <datagrid-row-skeleton
      v-for="index in skeletonRows * columnsPerRow.length"
      :key="index"
      :column-count="columnsPerRow[0].length + 2"
    />
  </tbody>
</template>
<script>
import {cloneDeep} from "lodash";
import DatagridRow from "./DatagridRow.vue";
import DatagridRowAdvancedFields from "./DatagridRowAdvancedFields.vue";
import DatagridRowSkeleton from "./DatagridRowSkeleton.vue";
import {processFieldUpdate} from "../../functions/datagrid/row-wrapper/processFieldUpdate";

export default {
  components: {
    DatagridRow,
    DatagridRowAdvancedFields,
    DatagridRowSkeleton,
  },
  props: {
    columnsPerRow: {
      type: Array,
      required: true,
    },
    processContext: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    visibleRows: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    showActionColumn: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    skeletonRows: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    row() {
      return this.rows[this.index];
    },
    containsAdvancedColumns() {
      return this.columns.some(
        (column) =>
          column.IsAdvancedColumn === true && column.IsVisible === true,
      );
    },
  },
  methods: {
    async processFieldUpdate({row, fieldName, value, cell}) {
      const oldRows = cloneDeep(this.rows);
      this.$emit("loading", true);
      try {
        const newRows = await processFieldUpdate({
          row,
          fieldName,
          value,
          cell,
          rows: this.rows,
          columns: this.columns,
          processContext: this.processContext,
        });

        this.$emit("input", {rows: newRows, oldRows});
      } catch (error) {
        console.error(`Something went wrong: ${error}`);
      } finally {
        this.$emit("loading", false);
      }
    },
    shouldRenderRow(row) {
      return row.rowMeta?.classes?.hidden !== true;
    },
  },
};
</script>
