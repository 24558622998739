import {rowsVirtualResumTotalExcVat} from "@/functions/datagrid/order-item/rows/rowsVirtualResumTotalExcVat";
import {rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow} from "@/functions/datagrid/rows/rowsSetCorrectAmountForVirtualCompositionAfterAmountChangeRow";
import {rowsStockFetch} from "../rowsStockFetch";
import {rowsProcessPopupStockPredictions} from "../rowsProcessPopupStockPredictions";

export const rowsAmountChangeWrapper = async ({row, rows, quotation}) => {
  let modifiedRows = rows.slice();

  modifiedRows = await rowsStockFetch({rows: modifiedRows, quotation});
  modifiedRows = await rowsProcessPopupStockPredictions({
    rows: modifiedRows,
  });
  modifiedRows = await rowsVirtualResumTotalExcVat({
    row,
    rows: modifiedRows,
  });

  modifiedRows = rowSetCorrectAmountForVirtualCompositionAfterAmountChangeRow({
    row,
    rows: modifiedRows,
  });

  return modifiedRows;
};
