import DatagridQuotationItemPopupStockPrediction from "../../../../components/datagrid/datagridPopups/DatagridQuotationItemPopupStockPrediction.vue";

export function processPopupStockPrediction({row}) {
  if (row.rowMeta?.stock - row.Amount.Value >= 0) {
    row.ItemID = {
      ...row.ItemID,
      ...setSuccessIconMetaDataStock(),
    };
  }

  if (row.rowMeta?.stock - row.Amount.Value < 0) {
    row.ItemID = {
      ...row.ItemID,
      ...setWarningIconMetaDataStock(),
    };
  }

  return row;
}

function setSuccessIconMetaDataStock() {
  return {
    Icon: "fa-check",
    IconColor: "success",
    IconComponent: DatagridQuotationItemPopupStockPrediction,
  };
}

function setWarningIconMetaDataStock() {
  return {
    Icon: "fa-exclamation-triangle",
    IconColor: "danger",
    IconComponent: DatagridQuotationItemPopupStockPrediction,
  };
}
