export function setReturnItemFormFieldsFromOrder({
  vueComponent,
  mockValueFieldEvent,
}) {
  vueComponent.formFields = {
    ...vueComponent.formFields,
    Value: {
      ...vueComponent.formFields.Value,
      Value: mockValueFieldEvent?.Value,
      ReturnType: "Order",
      IsReadOnly: false,
      Label: mockValueFieldEvent?.Text,
    },
    Type: {
      ...vueComponent.formFields.Type,
      Value: "Order",
    },
  };
}
