import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Updates a list of planning resource DTOs
 *
 * @param {Array} data - The list of planning resource DTOs to update
 * @param {string} data[].PlanningResourceID - The planning resource ID (UUID)
 * @param {number} data[].DefaultPlanningResourceID - The default planning resource ID
 * @param {number} data[].ResourceID - The resource ID
 * @param {string} data[].StartDate - The start date (ISO format)
 * @param {string} data[].EndDate - The end date (ISO format)
 * @param {number} data[].HourCapacity - The hour capacity
 * @param {string} data[].Description - The description
 * @returns {Promise<Array>} - Promise containing the updated planning resource DTOs
 */
export const updatePlanningResource = async (data) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning-resource", {
    method: "PATCH",
    data,
  });
};
