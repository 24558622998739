import {getRawValueFromDropdownValue} from "../../../util/parsing/getRawValueFromDropdownValue";

export function processRowDropdownCells({row}) {
  const modifiedRow = {...row};

  for (let key in modifiedRow) {
    if (
      key === "ItemUnitID" &&
      getRawValueFromDropdownValue(modifiedRow[key].Value) !== null
    ) {
      modifiedRow[key] = {
        ...row[key],
        eagerOptionsFetch: true,
      };
    }
  }

  return modifiedRow;
}
