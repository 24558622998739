import {processPeriodColumnChange} from "./row/processPeriodColumnChange.js";
import {rowsProcessPopupPriceCalculations} from "./rows/rowsProcessPopupPriceCalculations.js";
import {rowsProcessPrices} from "./rows/rowsProcessPrices.js";
import {rowsSetInitialMinDates} from "./rows/rowsSetInitialMinDates.js";
import {rowsStockSerialFetch} from "./rows/rowsStockSerialFetch.js";
import {getSettings} from "../../../util/getSettings";

export const loadRowDataForPageProcessVisibleRows = async ({rows, order}) => {
  const {FrontendRentalObjectPriceCalculation} = getSettings();

  rows = rows.map((row) => {
    return processPeriodColumnChange({row});
  });

  if (
    FrontendRentalObjectPriceCalculation === "PerformAutomaticPriceCalculation"
  )
    rows = await rowsProcessPrices({
      rows: rows,
      order,
    });

  const stockSerialRows = await rowsStockSerialFetch({
    rows,
    order,
  });
  const minDateRows = await rowsSetInitialMinDates({rows: stockSerialRows});

  return await rowsProcessPopupPriceCalculations({
    rows: minDateRows,
  });
};
