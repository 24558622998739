<template>
  <r-modal
    :show="show"
    size="md"
    :title="isBulkEdit ? translations['BulkEdit'] : translations['AdjustTimes']"
    @hide="$emit('hide')"
  >
    <dialog-form
      :dialog-data="dialogData"
      :data-in-store="false"
      @input="handleInput"
    />

    <template #modal-footer>
      <div class="flex justify-between w-full">
        <!-- Clear button on left side -->
        <r-button
          variant="secondary"
          :title="
            isBulkEdit
              ? translations['ClearTimesForSelectedCells']
              : translations['ClearTimesForThisCell']
          "
          @click="handleClearTimes"
        >
          {{ translations["ClearTimes"] }}
        </r-button>

        <!-- Other buttons on right side -->
        <div>
          <r-button variant="secondary" class="mr-2" @click="$emit('hide')">
            {{ translations["Cancel"] }}
          </r-button>
          <r-button variant="primary" @click="handleSave">
            {{
              isBulkEdit
                ? translations["ApplyToSelected"]
                : translations["Save"]
            }}
          </r-button>
        </div>
      </div>
    </template>
  </r-modal>
</template>

<script>
import RModal from "@/components/elements/RModal.vue";
import DialogForm from "@/components/dialog/DialogForm.vue";
import RButton from "@/components/elements/RButton.vue";
import {getTranslations} from "@/functions/session/getTranslations.js";
import dayjs from "dayjs";

const translations = getTranslations();

export default {
  name: "RGridViewPlanningWrapperTimeEditModal",
  components: {
    RButton,
    DialogForm,
    RModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isBulkEdit: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default: () => ({startTime: null, endTime: null, hourCapacity: 0}),
    },
  },
  data() {
    return {
      translations,
      dialogData: {
        Fields: {
          StartTime: {
            Name: "StartTime",
            Type: "time",
            Required: false,
            Value: null,
            Title: translations["StartTime"],
            Editor: "Time",
          },
          EndTime: {
            Name: "EndTime",
            Type: "time",
            Required: false,
            Value: null,
            Title: translations["EndTime"],
            Editor: "Time",
          },
          HourCapacity: {
            Name: "HourCapacity",
            Type: "number",
            Required: false,
            Value: 0,
            Title: translations["HourCapacity"],
            Placeholder: translations["EnterNumberOfHours"],
          },
        },
      },
    };
  },
  watch: {
    initialValues: {
      immediate: true,
      handler(newValues) {
        this.updateDialogFields(newValues);
      },
    },

    // Add a watch on show to ensure values update when modal opens
    show(isVisible) {
      if (isVisible && this.initialValues) {
        this.updateDialogFields(this.initialValues);
      }
    },
  },
  methods: {
    updateDialogFields(values) {
      const startTime = values?.startTime || "";
      const endTime = values?.endTime || "";
      const hourCapacity = values?.hourCapacity || 0;

      this.dialogData = {
        ...this.dialogData,
        Fields: {
          StartTime: {
            ...this.dialogData.Fields.StartTime,
            Value: startTime,
          },
          EndTime: {
            ...this.dialogData.Fields.EndTime,
            Value: endTime,
          },
          HourCapacity: {
            ...this.dialogData.Fields.HourCapacity,
            Value: hourCapacity,
          },
        },
      };
    },
    handleInput(fields) {
      const startTimeField = fields.StartTime;
      const endTimeField = fields.EndTime;

      // Check if end time needs adjustment (if start time is after end time)
      if (startTimeField?.Value && endTimeField?.Value) {
        const startTime = dayjs(`2000-01-01T${startTimeField.Value}`);
        const endTime = dayjs(`2000-01-01T${endTimeField.Value}`);

        if (
          startTime.isValid() &&
          endTime.isValid() &&
          startTime.isAfter(endTime)
        ) {
          // Adjust end time to match start time
          this.dialogData = {
            ...this.dialogData,
            Fields: {
              ...fields,
              EndTime: {
                ...fields.EndTime,
                Value: startTimeField.Value,
              },
            },
          };
          return;
        }
      }

      // If no adjustments needed, update dialogData directly
      this.dialogData = {
        ...this.dialogData,
        Fields: fields,
      };
    },
    handleClearTimes() {
      // Send null values to parent to indicate clearing times
      this.$emit("save", {
        startTime: "00:00:00",
        endTime: "00:00:00",
        hourCapacity: this.dialogData.Fields.HourCapacity.Value, // Keep hourCapacity
        clientSideUUID: this.initialValues.clientSideUUID,
      });

      // Close the modal
      this.$emit("hide");
    },
    handleSave() {
      const timeValues = {
        startTime: this.dialogData.Fields.StartTime.Value,
        endTime: this.dialogData.Fields.EndTime.Value,
        hourCapacity: this.dialogData.Fields.HourCapacity.Value,
        clientSideUUID: this.initialValues.clientSideUUID,
      };

      this.$emit("save", timeValues);

      // Reset time values after emitting but keep hourCapacity
      this.dialogData.Fields.StartTime.Value = null;
      this.dialogData.Fields.EndTime.Value = null;
    },
  },
};
</script>
