export const createOrderItem = ({
  Amount,
  ConditionID,
  DateTimeExpectedEnd,
  DateTimeExpectedStart,
  ItemID,
  PhysicalItemID,
  OrderItemID,
  Ranking,
  ClientSideUUID,
  WarehouseID,
  cell,
}) => ({
  Amount: Amount.Value,
  ConditionID: ConditionID.Value,
  DateTimeExpectedEnd: DateTimeExpectedEnd.Value ?? null,
  DateTimeExpectedStart: DateTimeExpectedStart.Value,
  ItemID: cell.Name === "PhysicalItemID" ? PhysicalItemID.Value : ItemID.Value,
  OrderItemID: OrderItemID.Value,
  Ranking: Ranking.Value,
  WarehouseID: WarehouseID.Value?.Key ?? WarehouseID.Value,
  ClientSideUUID: ClientSideUUID.Value,
});
