import {getSettings} from "@/util/getSettings";

const {PeriodSelection} = getSettings();

const ItemSinglePeriodEnforced = PeriodSelection === "ItemSinglePeriodEnforced";
const ItemSinglePeriodCalculateEndDate =
  PeriodSelection === "ItemSinglePeriodCalculateEndDateEnforced";

export function handleStatusClosed({row}) {
  if (row.StatusCode?.Value === "200") {
    const newRow = {...row};
    const exemptedColumns = [
      "Notes",
      "Ranking",
      "DateTimeRentalEnd",
      "PeriodPrice",
      "Period",
      "PeriodAmount",
      "VATCodeID",
      "VisibilityPolicy",
      "PriceCalcReason",
      "Discount",
    ];

    if (ItemSinglePeriodEnforced || ItemSinglePeriodCalculateEndDate) {
      exemptedColumns.splice(exemptedColumns.indexOf("DateTimeRentalEnd"), 1);
    }

    for (const column of Object.values(row)) {
      if (column) {
        column.IsReadOnly = !exemptedColumns.includes(column.Name);
      }
    }
    return newRow;
  }
  return row;
}
