import {processJob} from "./processJob";

export const handleJobExecution = async ({job, vueInstance}) => {
  const camelCaseActionName = job.actionName.replace(/-([a-z])/g, function(
    g
  ) {
    return g[1].toUpperCase();
  });
  if (
    vueInstance[camelCaseActionName] &&
    typeof vueInstance[camelCaseActionName] === "function"
  ) {
    await processJob({job, vueInstance});
  }
};
