import {getTranslations} from "../functions/session/localstorage/getTranslations";
import {alertPopup} from "../interface/alertPopup/alertPopup";

export function cancelOrders({window}) {
  const translations = getTranslations();
  const selectedOrderIds = window.selection;
  const tableRows = window.output.Data.Rows;

  const cancellationAllowed = selectedOrderIds.every((row) => {
    const fullRow = tableRows.find(
      (tableRow) => tableRow.OrderID === row.OrderID,
    );
    return fullRow.Status === translations.Open;
  });

  if (!cancellationAllowed) {
    const alertObject = {
      text: translations.CanOnlyCancelOpenOrders,
      icon: "warning",
    };
    alertPopup(alertObject);
  }

  return cancellationAllowed;
}
