import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Deletes planning resources by their IDs
 *
 * @param {Array<string>} planningResourceIDs - Array of planning resource IDs (UUIDs) to delete
 * @returns {Promise<Object>} - Promise containing the response
 */
export const deletePlanningResources = async (planningResourceIDs) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning-resource", {
    method: "DELETE",
    data: planningResourceIDs,
  });
};
