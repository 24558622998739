import {getTranslations} from "../../session/localstorage/getTranslations";
import {v4 as uuidv4} from "uuid";

export const createVirtualHeaderRow = ({columns, referencedRow}) => {
  const translations = getTranslations();
  const visibleColumnCount = columns.filter(
    (column) => column.IsVisible && !column.IsAdvancedColumn,
  ).length;

  return {
    rowMeta: {
      virtual: true,
      classes: {"border-gray-950 border-l-2 ": true},
      styles: {
        "border-left": "2px solid #626262",
        "border-right": "2px solid #626262",
      },
    },
    Checked: {
      Name: "Checked",
      Value: "",
      Editor: "String",
      IsReadOnly: true,
      meta: {
        classes: {
          hidden: true,
        },
      },
    },
    ItemID: {
      Name: "ItemID",
      Value: `${translations.Order} ${referencedRow.OrderID.Value}  |  ${referencedRow.CustomerID.Value}`,
      Colspan: visibleColumnCount,
      meta: {
        classes: {
          "font-semibold": true,
          "!text-[#626262]": true,
        },
      },
    },
    OrderID: {
      Name: "OrderID",
      Value: referencedRow.OrderID.Value,
      IsVisible: false,
    },
    Ranking: {
      Name: "Ranking",
      Value: referencedRow.Ranking.Value - 1,
    },
    ClientSideUUID: {
      Name: "ClientSideUUID",
      Value: uuidv4(),
    },
    ItemDescription: {
      Name: "ItemDescription",
      Value: "",
      IsVisible: false,
    },
    SerialID: {
      Name: "SerialID",
      Value: "",
      IsVisible: false,
    },
    Amount: {
      Name: "Amount",
      Value: "",
      IsVisible: false,
    },
    AmountReturned: {
      Name: "AmountReturned",
      Value: "",
      IsVisible: false,
    },
    DateTimeShipped: {
      Name: "DateTimeShipped",
      Value: "",
      IsVisible: false,
    },
    DateTimeRentalStart: {
      Name: "DateTimeRentalStart",
      Value: "",
      IsVisible: false,
    },
    DateTimeRentalEnd: {
      Name: "DateTimeRentalEnd",
      Value: "",
      IsVisible: false,
    },
  };
};
