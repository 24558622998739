import {handlePickedQuantityDecrement} from "./handlePickedQuantityDecrement";
import {sumKeysFromArray} from "../../../sumKeysFromArray";
import dayjs from "dayjs";

export const processRowDateTimePicked = ({row}) => {
  if (!row.PickedQuantity.Value || typeof row.PickedQuantity.Value !== "number")
    return {
      ...row,
      DateTimePicked: {
        Name: "DateTimePicked",
        Value: null,
      },
      TrackedBulkPicks: {
        Name: "TrackedBulkPicks",
        Value: [],
      },
    };

  if (row.UseSerials.Value)
    return {
      ...row,
      DateTimePicked: {
        Name: "DateTimePicked",
        Value: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      },
    };

  const trackedBulkPicksFromRow = row.TrackedBulkPicks?.Value ?? [];
  const totalPicked = sumKeysFromArray(trackedBulkPicksFromRow);

  if (totalPicked > row.PickedQuantity.Value)
    return handlePickedQuantityDecrement({
      row,
      trackedBulkPicksFromRow,
      totalPicked,
    });

  if (trackedBulkPicksFromRow.length < 1)
    return {
      ...row,
      TrackedBulkPicks: {
        Name: "TrackedBulkPicks",
        Value: [
          {[row.PickedQuantity.Value]: dayjs().format("YYYY-MM-DDTHH:mm:ss")},
        ],
      },
    };

  if (trackedBulkPicksFromRow.length > 0) {
    row.TrackedBulkPicks.Value.push({
      [row.PickedQuantity.Value - totalPicked]: dayjs().format(
        "YYYY-MM-DDTHH:mm:ss",
      ),
    });
    return row;
  }

  return row;
};
