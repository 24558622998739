<template>
  <div>
    <div v-if="!loaded" class="flex items-center justify-center h-64">
      <div class="flex flex-col items-center">
        <div
          class="w-12 h-12 border-4 border-gray-200 border-t-primary rounded-full animate-spin mb-3"
        ></div>
        <div class="text-gray-600 font-medium">
          {{ translations["LoadingPlanningData"] }}
        </div>
      </div>
    </div>
    <r-grid-view
      v-if="loaded"
      ref="gridView"
      :columns="columns"
      :rows="sortedGridRows"
      :r-window="rWindow"
      :recently-updated="recentlyUpdated"
      :modified-tiles="modifiedTiles"
      :tile-override-classes-function="overrideClasses"
      @tile-click="handleTileClick"
      @open-bulk-edit="handleOpenBulkEdit"
      @selection-change="handleSelectionChange"
      @resource-click="handleResourceClick"
    >
      <!-- Custom template for resource names -->
      <template #resource-info="{row}">
        <div
          class="ml-2 flex flex-col cursor-pointer group"
          @click="handleResourceClick(row)"
        >
          <div class="flex items-center group-hover:text-orange-600">
            <span>{{ row.title }}</span>
            <i
              class="fas fa-pencil-alt ml-1 text-xs text-gray-400 group-hover:text-orange-500"
            ></i>
          </div>
          <div class="text-xs text-gray-500 mt-1">
            {{ row.hourCapacity || 0 }}
            {{ translations["CapacityPointsPerHour"] }}
          </div>
        </div>
      </template>
    </r-grid-view>

    <RGridViewDefaultPlanningWrapperAddResourceModal
      :show="showAddResourceDialog"
      :resources="resources"
      :selected-resource-ids="presentResourceIds"
      @hide="showAddResourceDialog = false"
      @add-resource="handleAddResource"
    />

    <RGridViewDefaultPlanningWrapperTimeEditModal
      :show="showTimeEditModal"
      :is-bulk-edit="isBulkEdit"
      :initial-values="timeEditInitialValues"
      @hide="closeTimeEditModal"
      @save="handleSaveTimeChanges"
    />

    <RGridViewDefaultPlanningWrapperEditResourceModal
      v-if="selectedResource"
      :show="showEditResourceModal"
      :resource="selectedResource"
      @hide="showEditResourceModal = false"
      @save="handleSaveResource"
    />
  </div>
</template>
<script>
import RGridView from "../RGridView.vue";
import {handleJobExecution} from "@/functions/datagrid/actions/handleJobExecution";
import RGridViewDefaultPlanningWrapperAddResourceModal from "./RGridViewDefaultPlanningWrapperAddResourceModal.vue";
import RGridViewDefaultPlanningWrapperTimeEditModal from "./RGridViewDefaultPlanningWrapperTimeEditModal.vue";
import RGridViewDefaultPlanningWrapperEditResourceModal from "./RGridViewDefaultPlanningWrapperEditResourceModal.vue";
import {fetchResources} from "@/services/v2/settings/fetchResources.js";
import {getTranslations} from "@/functions/session/getTranslations.js";
import {fetchDefaultPlanning} from "@/services/v2/warehouse/default/fetchDefaultPlanning.js";
import {fetchDefaultPlanningResource} from "@/services/v2/warehouse/default/fetchDefaultPlanningResource.js";
import {createDefaultPlanning} from "@/services/v2/warehouse/default/createDefaultPlanning.js";
import {createDefaultPlanningResource} from "@/services/v2/warehouse/default/createDefaultPlanningResource.js";
import {updateDefaultPlanning} from "@/services/v2/warehouse/default/updateDefaultPlanning.js";
import {updateDefaultPlanningResource} from "@/services/v2/warehouse/default/updateDefaultPlanningResource.js";
import {deleteDefaultPlanning} from "@/services/v2/warehouse/default/deleteDefaultPlanning.js";
import {deleteDefaultPlanningResource} from "@/services/v2/warehouse/default/deleteDefaultPlanningResource.js";
import {getActiveWarehouse} from "@/util/getActiveWarehouse.js";
import {notify} from "@/util/notify.js";
import {prompt} from "@/functions/prompt.js";

const translations = getTranslations();

export default {
  name: "RGridViewDefaultPlanningWrapper",
  components: {
    RGridViewDefaultPlanningWrapperTimeEditModal,
    RGridViewDefaultPlanningWrapperAddResourceModal,
    RGridViewDefaultPlanningWrapperEditResourceModal,
    RGridView,
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentJob: null,
    warehouseID: null,
    showAddResourceDialog: false,
    showTimeEditModal: false,
    isBulkEdit: false,
    selectedTimeSlot: null,
    selectedColumn: null,
    selectedSlots: [],
    timeEditInitialValues: {
      startTime: null,
      endTime: null,
    },
    loaded: false,
    recentlyUpdated: [],
    resources: [],
    defaultPlanningResources: [],
    defaultPlanningEntries: [],
    loading: false,
    originalValues: {},
    columns: [
      {name: "Monday", title: translations["DescriptionMonday"]},
      {name: "Tuesday", title: translations["DescriptionTuesday"]},
      {name: "Wednesday", title: translations["DescriptionWednesday"]},
      {name: "Thursday", title: translations["DescriptionThursday"]},
      {name: "Friday", title: translations["DescriptionFriday"]},
      {name: "Saturday", title: translations["DescriptionSaturday"]},
      {name: "Sunday", title: translations["DescriptionSunday"]},
    ],
    rows: [],
    showEditResourceModal: false,
    selectedResource: null,
    translations,
  }),
  computed: {
    sortedGridRows() {
      return this.rows.slice().sort((a, b) => {
        const aName = a.title.toLowerCase();
        const bName = b.title.toLowerCase();
        return aName.localeCompare(bName);
      });
    },
    presentResourceIds() {
      return this.rows.map((row) => row.resourceId);
    },

    apiDefaultPlanningResources() {
      return this.rows.map((row) => ({
        DefaultPlanningResourceID: row.defaultPlanningResourceID,
        ResourceID: row.resourceId,
        Description: row.title,
        HourCapacity: row.hourCapacity || 0,
      }));
    },

    apiDefaultPlanningEntries() {
      const entries = [];

      this.rows.forEach((row) => {
        row.values.forEach((valueItem) => {
          const dayIndex = this.getDayIndexFromName(valueItem.column);
          if (dayIndex < 0) return;

          const hasDefaultPlanningId = !!valueItem.defaultPlanningID;
          const hasTimeValues =
            valueItem.value &&
            (valueItem.value.startTime || valueItem.value.endTime);
          const isMarkedForDeletion =
            hasDefaultPlanningId &&
            (!valueItem.value ||
              (!valueItem.value.startTime && !valueItem.value.endTime));

          if ((hasDefaultPlanningId || hasTimeValues) && !isMarkedForDeletion) {
            entries.push({
              DefaultPlanningID: valueItem.defaultPlanningID || undefined,
              DefaultPlanningResourceID: row.defaultPlanningResourceID,
              Day: this.getDayFromIndex(dayIndex),
              StartTime: valueItem.value?.startTime || null,
              EndTime: valueItem.value?.endTime || null,
              WarehouseID: row.warehouseID || this.warehouseID,
            });
          }
        });
      });

      return entries;
    },

    dayNames() {
      return this.columns.map((col) => col.name);
    },

    modifiedTiles() {
      const modified = [];

      if (!Object.keys(this.originalValues).length) return modified;

      this.rows.forEach((row) => {
        row.values.forEach((valueItem) => {
          const rowId = row.defaultPlanningResourceID;
          const day = valueItem.column;

          const originalValue = this.originalValues[`${rowId}-${day}`] || {
            startTime: null,
            endTime: null,
          };

          const currentValue = valueItem.value || {
            startTime: null,
            endTime: null,
          };

          if (
            originalValue.startTime !== currentValue.startTime ||
            originalValue.endTime !== currentValue.endTime
          ) {
            modified.push({
              rowId,
              day,
              row,
              column: day,
            });
          }
        });
      });

      return modified;
    },
  },
  watch: {
    "rWindow.jobs": {
      handler: function (newJobs) {
        if (this.currentJob) {
          return;
        }

        if (newJobs && Object.keys(newJobs).length > 0) {
          const firstJob = Object.values(newJobs)[0];
          this.currentJob = firstJob;
          this.handleJobExecution(firstJob);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.loaded = false;
    this.$store.state.loading = true;
    await this.loadResources();
    await this.loadPlanningData();
    this.warehouseID = getActiveWarehouse();
    this.$store.state.loading = false;
    this.loaded = true;
  },
  methods: {
    getDayIndexFromName(dayName) {
      return this.dayNames.indexOf(dayName);
    },
    overrideClasses({isSelected, isRecentlyUpdated, isModified, timeSlot}) {
      if (isSelected) {
        return {"bg-orange-100 border-2 border-blue-400": true};
      }

      if (!timeSlot.value?.startTime && !timeSlot.value?.endTime) {
        return {
          "bg-gray-50 opacity-50 hover:opacity-100 hover:bg-orange-100": true,
        };
      }

      if (isRecentlyUpdated) {
        return {"bg-orange-200 animate-flash font-bold": true};
      }

      return {"bg-gray-50 hover:bg-gray-100": true};
    },
    getDayNameFromIndex(index) {
      return this.dayNames[index] || null;
    },

    getDayFromIndex(dayIndex) {
      const fixedDates = [
        "2000-01-03T00:00:00", // Monday (Jan 3)
        "2000-01-04T00:00:00", // Tuesday (Jan 4)
        "2000-01-05T00:00:00", // Wednesday (Jan 5)
        "2000-01-06T00:00:00", // Thursday (Jan 6)
        "2000-01-07T00:00:00", // Friday (Jan 7)
        "2000-01-08T00:00:00", // Saturday (Jan 8)
        "2000-01-02T00:00:00", // Sunday (Jan 2)
      ];

      return fixedDates[dayIndex];
    },

    storeOriginalValues() {
      const originals = {};

      this.rows.forEach((row) => {
        row.values.forEach((valueItem) => {
          const rowId = row.defaultPlanningResourceID;
          const day = valueItem.column;
          const value = valueItem.value || {startTime: null, endTime: null};

          originals[`${rowId}-${day}`] = {
            startTime: value.startTime,
            endTime: value.endTime,
          };
        });
      });

      this.originalValues = originals;
    },

    getEntriesToDelete() {
      const idsToDelete = [];

      this.rows.forEach((row) => {
        row.values.forEach((valueItem) => {
          if (
            valueItem.defaultPlanningID &&
            (!valueItem.value ||
              (!valueItem.value.startTime && !valueItem.value.endTime))
          ) {
            idsToDelete.push(valueItem.defaultPlanningID);
          }
        });
      });

      return idsToDelete;
    },

    async deleteEntries(entriesToDelete) {
      if (entriesToDelete.length > 0) {
        await deleteDefaultPlanning(entriesToDelete);
      }
    },

    async createNewEntries(newEntries) {
      if (newEntries.length > 0) {
        await createDefaultPlanning(newEntries);
      }
    },

    async updateExistingEntries(existingEntries) {
      if (existingEntries.length > 0) {
        await updateDefaultPlanning(existingEntries);
      }
    },

    async save(job) {
      this.$store.state.loading = true;

      try {
        const entriesToDelete = this.getEntriesToDelete();

        const entries = this.apiDefaultPlanningEntries;

        const newEntries = entries.filter((entry) => !entry.DefaultPlanningID);
        const existingEntries = entries.filter(
          (entry) =>
            entry.DefaultPlanningID &&
            !entriesToDelete.includes(entry.DefaultPlanningID),
        );

        // Perform operations sequentially
        await this.deleteEntries(entriesToDelete);
        await this.createNewEntries(newEntries);
        await this.updateExistingEntries(existingEntries);

        this.storeOriginalValues();

        setTimeout(() => {
          this.recentlyUpdated = [];
        }, 1000);
        await this.loadResources();
        await this.loadPlanningData();
        this.$emit("job-completed", job);
        notify({
          type: "success",
          message: translations["PlanningSavedSuccessfully"],
        });
      } catch (error) {
        this.$emit("job-error", job, error);
        notify({type: "error", message: translations["FailedToSavePlanning"]});
      } finally {
        this.$store.state.loading = false;
      }
      return false;
    },

    async loadResources() {
      this.$store.state.loading = true;

      try {
        const response = await fetchResources();
        this.resources = response.data?.Collection ?? [];
      } catch (error) {
        this.resources = [];
        notify({type: "error", message: translations["FailedToLoadResources"]});
      } finally {
        this.$store.state.loading = false;
      }
    },

    async loadPlanningData() {
      this.$store.state.loading = true;
      this.loading = true;

      try {
        const [planningResponse, resourcesResponse] = await Promise.all([
          fetchDefaultPlanning({}),
          fetchDefaultPlanningResource({}),
        ]);

        this.defaultPlanningEntries = planningResponse.data?.Collection || [];
        this.defaultPlanningResources =
          resourcesResponse.data?.Collection || [];

        this.parseDataForGrid();
        this.storeOriginalValues();
      } catch (error) {
        notify({
          type: "error",
          message: translations["FailedToLoadPlanningData"],
        });
      } finally {
        this.loading = false;
        this.$store.state.loading = false;
      }
    },

    parseDataForGrid() {
      const planningEntriesByResource = {};

      this.defaultPlanningEntries.forEach((entry) => {
        if (!planningEntriesByResource[entry.DefaultPlanningResourceID]) {
          planningEntriesByResource[entry.DefaultPlanningResourceID] = [];
        }
        planningEntriesByResource[entry.DefaultPlanningResourceID].push(entry);
      });

      this.rows = this.defaultPlanningResources.map((resource) => {
        const planningEntries =
          planningEntriesByResource[resource.DefaultPlanningResourceID] || [];

        const values = this.dayNames.map((day) => {
          const dayEntry = planningEntries.find(
            (entry) => this.getDayNameFromDate(entry.Day) === day,
          );

          return {
            column: day,
            defaultPlanningID: dayEntry?.DefaultPlanningID || undefined,
            value: dayEntry
              ? {
                  startTime: dayEntry.StartTime,
                  endTime: dayEntry.EndTime,
                }
              : {
                  startTime: null,
                  endTime: null,
                },
          };
        });

        return {
          name: resource.Description,
          title: resource.Description,
          resourceId: resource.ResourceID,
          defaultPlanningResourceID: resource.DefaultPlanningResourceID,
          hourCapacity: resource.HourCapacity,
          warehouseID:
            planningEntries.length > 0
              ? planningEntries[0].WarehouseID
              : this.warehouseID,
          values,
        };
      });
    },

    addResource(job) {
      this.showAddResourceDialog = true;
      this.$emit("job-completed", job);
      return false;
    },

    async handleAddResource(resources) {
      this.$store.state.loading = true;
      const newResources = resources.filter(
        (resource) => !this.presentResourceIds.includes(resource.ResourceID),
      );

      if (newResources.length === 0) {
        this.showAddResourceDialog = false;
        this.$store.state.loading = false;
        return;
      }

      try {
        const resourcesForAPI = newResources.map((resource) => ({
          DefaultPlanningResourceID: 0,
          ResourceID: resource.ResourceID,
          Description: resource.Description ?? resource.ShortCode,
          HourCapacity: resource.HourCapacity || 0,
        }));

        const response = await createDefaultPlanningResource(resourcesForAPI);
        const createdResources = response.data;

        this.addNewResourcesToRows(createdResources, newResources);

        const newRows = this.getNewlyAddedRows(createdResources);

        if (newRows.length > 0) {
          this.markAsUpdated(
            newRows.map((row) => ({
              row,
              resourceUpdated: true,
            })),
          );
        }

        if (createdResources.length > 0 && resources[0]?.days.length > 0) {
          const defaultPlanningsToCreate = [];
          for (const resource of createdResources) {
            defaultPlanningsToCreate.push(
              ...resources[0].days.map((day) => ({
                DefaultPlanningResourceID: resource.DefaultPlanningResourceID,
                WarehouseID: this.warehouseID,
                Day: this.getDateFromDay(day),
                StartTime: resources[0].startTime,
                EndTime: resources[0].endTime,
              })),
            );
          }

          if (defaultPlanningsToCreate.length > 0) {
            await createDefaultPlanning(defaultPlanningsToCreate);
          }
          await this.loadResources();
          await this.loadPlanningData();
        }

        notify({
          type: "success",
          message: translations["ResourceAddedSuccessfully"],
        });
      } catch (error) {
        console.error(error);
        notify({type: "error", message: translations["FailedToAddResources"]});
      } finally {
        this.showAddResourceDialog = false;
        this.$store.state.loading = false;
      }
    },

    getDateFromDay(day) {
      const dayIndex = this.getDayIndexFromName(day);
      return this.getDayFromIndex(dayIndex);
    },

    addNewResourcesToRows(createdResources, originalResources) {
      createdResources.forEach((createdResource) => {
        const originalResource = originalResources.find(
          (r) => r.ResourceID === createdResource.ResourceID,
        );

        if (originalResource) {
          this.rows.push({
            name: originalResource.Description ?? originalResource.ShortCode,
            title: originalResource.Description ?? originalResource.ShortCode,
            resourceId: originalResource.ResourceID,
            defaultPlanningResourceID:
              createdResource.DefaultPlanningResourceID,
            hourCapacity: originalResource.HourCapacity || 0,
            warehouseID: this.warehouseID,
            values: this.columns.map((column) => ({
              column: column.name,
              value: {
                startTime: null,
                endTime: null,
              },
            })),
          });
        }
      });
    },

    getNewlyAddedRows(createdResources) {
      return this.rows.filter((row) =>
        createdResources.some(
          (cr) =>
            cr.DefaultPlanningResourceID === row.defaultPlanningResourceID,
        ),
      );
    },

    changeTimeRanges(job) {
      if (!this.selectedSlots?.length) {
        notify({
          type: "warning",
          message: translations["NoTilesSelectedSelectOneBeforeEditing"],
        });
        this.$emit("job-completed", job);
        return false;
      }

      this.handleOpenBulkEdit(this.selectedSlots);

      this.$emit("job-completed", job);
      return false;
    },

    clearSelection(job) {
      this.selectedSlots = [];

      if (this.$refs.gridView) {
        this.$refs.gridView.clearSelection();
      }

      this.$emit("job-completed", job);
      return false;
    },

    handleTileClick({row, column, timeSlot}) {
      this.selectedTimeSlot = row;
      this.selectedColumn = column;
      this.isBulkEdit = false;

      this.timeEditInitialValues = {
        startTime: timeSlot?.value?.startTime || "",
        endTime: timeSlot?.value?.endTime || "",
      };

      this.showTimeEditModal = true;
    },

    handleOpenBulkEdit(selectedSlots) {
      this.selectedSlots = selectedSlots;
      this.isBulkEdit = true;

      if (selectedSlots.length > 0) {
        const firstSlot = selectedSlots[0];
        const timeSlot = firstSlot.row.values.find(
          (v) => v.column === firstSlot.column,
        );

        this.timeEditInitialValues = {
          startTime: timeSlot?.value?.startTime || "",
          endTime: timeSlot?.value?.endTime || "",
        };
        this.showTimeEditModal = true;
      } else {
        notify({
          type: "error",
          message: translations["FailedToSaveTimeChanges"],
        });
        this.timeEditInitialValues = {
          startTime: "",
          endTime: "",
        };
      }
    },

    handleSelectionChange(slots) {
      this.selectedSlots = slots;
    },

    closeTimeEditModal() {
      this.showTimeEditModal = false;
      this.selectedTimeSlot = null;
      this.selectedColumn = null;
      this.selectedSlots = [];
    },
    handleSaveTimeChanges(timeValues) {
      this.$store.state.loading = true;

      try {
        if (this.isBulkEdit) {
          this.saveBulkChanges(timeValues);
        } else {
          this.saveSingleTimeChanges(timeValues);
        }

        this.closeTimeEditModal();
        setTimeout(() => {
          this.save();
        });
      } catch (error) {
        notify({
          type: "error",
          message: translations["FailedToSaveTimeChanges"],
        });
        this.$store.state.loading = false;
      }
    },
    saveSingleTimeChanges(timeValues) {
      if (this.selectedTimeSlot && this.selectedColumn) {
        const timeSlot = this.selectedTimeSlot.values.find(
          (v) => v.column === this.selectedColumn,
        );

        if (timeSlot) {
          timeSlot.value.startTime = timeValues.startTime;
          timeSlot.value.endTime = timeValues.endTime;

          const update = {
            row: this.selectedTimeSlot,
            column: this.selectedColumn,
            newValue: {
              startTime: timeValues.startTime,
              endTime: timeValues.endTime,
            },
          };

          this.markAsUpdated([update]);
        }
      }
    },

    saveBulkChanges(timeValues) {
      const updates = this.selectedSlots
        .map((slot) => {
          const timeSlot = slot.row.values.find(
            (v) => v.column === slot.column,
          );

          if (timeSlot) {
            timeSlot.value.startTime = timeValues.startTime;
            timeSlot.value.endTime = timeValues.endTime;

            return {
              row: slot.row,
              column: slot.column,
              newValue: {
                startTime: timeValues.startTime,
                endTime: timeValues.endTime,
              },
            };
          }
          return null;
        })
        .filter(Boolean);

      this.markAsUpdated(updates);
    },

    markAsUpdated(updates) {
      this.recentlyUpdated = [...updates];

      setTimeout(() => {
        this.recentlyUpdated = [];
      }, 3000);
    },

    async handleJobExecution(job) {
      this.$store.state.loading = true;

      try {
        this.$emit("job-completed", job);
        await handleJobExecution({job, vueInstance: this});
      } catch (error) {
        notify({type: "error", message: translations["FailedToExecuteJob"]});
      } finally {
        this.currentJob = null;
        this.$store.state.loading = false;
      }
    },

    handleResourceClick(row) {
      this.selectedResource = row;
      this.showEditResourceModal = true;
    },

    async handleSaveResource(updatedResource) {
      this.$store.state.loading = true;

      try {
        const rowIndex = this.rows.findIndex(
          (row) =>
            row.defaultPlanningResourceID ===
            updatedResource.defaultPlanningResourceID,
        );

        if (rowIndex >= 0) {
          this.rows[rowIndex].hourCapacity = updatedResource.hourCapacity;

          await updateDefaultPlanningResource([
            {
              DefaultPlanningResourceID:
                updatedResource.defaultPlanningResourceID,
              ResourceID: updatedResource.resourceId,
              Description: updatedResource.title,
              HourCapacity: updatedResource.hourCapacity,
            },
          ]);

          this.markAsUpdated([
            {
              row: this.rows[rowIndex],
              resourceUpdated: true,
            },
          ]);

          notify({
            type: "success",
            message: translations["ResourceUpdatedSuccessfully"],
          });
        }
      } catch (error) {
        notify({
          type: "error",
          message: translations["FailedToUpdateResource"],
        });
      } finally {
        this.showEditResourceModal = false;
        this.selectedResource = null;
        this.$store.state.loading = false;
      }
    },

    async deleteResources(job) {
      if (!this.selectedSlots?.length) {
        notify({
          type: "warning",
          message: translations["NoTilesSelectedSelectOneBeforeEditing"],
        });
        this.$emit("job-completed", job);
        return false;
      }

      const resourceIds = [
        ...new Set(
          this.selectedSlots.map((slot) => slot.row.defaultPlanningResourceID),
        ),
      ];

      if (resourceIds.length === 0) {
        this.$emit("job-completed", job);
        return false;
      }

      this.$store.state.loading = true;

      // Show confirmation dialog
      const confirmed = await prompt({
        text:
          translations["AreYouSureDeleteResources"] ||
          "Are you sure you want to delete the selected resources?",
        confirmText: translations["Yes"] || "Yes",
        cancelText: translations["No"] || "No",
      });

      if (!confirmed) {
        this.$emit("job-completed", job);
        this.$store.state.loading = false;
        return false;
      }

      try {
        await deleteDefaultPlanningResource(resourceIds);

        this.rows = this.rows.filter(
          (row) => !resourceIds.includes(row.defaultPlanningResourceID),
        );

        this.selectedSlots = [];
        if (this.$refs.gridView) {
          this.$refs.gridView.clearSelection();
        }

        notify({
          type: "success",
          message: translations["ResourcesDeletedSuccessfully"],
        });
      } catch (error) {
        notify({
          type: "error",
          message: translations["FailedToDeleteResources"],
        });
        this.$emit("job-error", job, error);
      }

      this.$emit("job-completed", job);
      this.$store.state.loading = false;
      return false;
    },

    getDayNameFromDate(dateString) {
      const dateMap = {
        "2000-01-03T00:00:00": "Monday",
        "2000-01-04T00:00:00": "Tuesday",
        "2000-01-05T00:00:00": "Wednesday",
        "2000-01-06T00:00:00": "Thursday",
        "2000-01-07T00:00:00": "Friday",
        "2000-01-08T00:00:00": "Saturday",
        "2000-01-02T00:00:00": "Sunday",
      };

      if (dateMap[dateString]) {
        return dateMap[dateString];
      }

      try {
        const date = new Date(dateString);
        const dayOfWeek = date.getDay();
        const adjustedIndex = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        return this.columns[adjustedIndex].name;
      } catch (e) {
        notify({type: "error", message: translations["InvalidDateFormat"]});
        return null;
      }
    },
  },
};
</script>
