export const clearInvalidDialogLinkedField = ({fields, linkedField}) => {
  const index = fields.indexOf(linkedField);

  if ("NewValue" in fields[index]) {
    fields[index].NewValue = null;
  } else if ("Value" in fields[index]) {
    fields[index].Value = null;
  }

  if (linkedField.Dropdown?.Items) fields[index].Dropdown.Items = [];

  return fields;
};
