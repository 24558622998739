<template>
  <div class="w-100">
    <div class="select-scan-label">
      <i
        v-if="type"
        :class="{
          'fas fa-tag pl-1': type === 'ItemSerial',
          'fas fa-cube': type === 'Item' || type === 'RentalItem',
          'fas fa-cubes': type === 'Composition' || type === 'ContentItem',
          'fas fa-window-restore': type === 'Placeholder',
        }"
      ></i>
      <span
        :class="{
          italic: type === 'Composition',
          'pl-1 serial': type === 'ItemSerial',
        }"
        v-html="highlightSearchCharacters(label)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    getType: {
      required: false,
      type: Function,
      default: ({row}) => {
        return row.type;
      },
    },
    getLabel: {
      required: false,
      type: Function,
      default: ({row}) => {
        return row.label;
      },
    },
    searchValue: {
      required: true,
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      iconTypes: {
        ItemSerial: "fas fa-tag pl-1",
        Item: "fas fa-cube",
        Composition: "fas fa-cubes",
      },
    };
  },
  computed: {
    type() {
      return this.getType({row: this.option});
    },
    label() {
      return this.getLabel({row: this.option});
    },
  },
  methods: {
    getIconClassByType(type) {
      return this.iconTypes[type] ?? "";
    },
    highlightSearchCharacters(text) {
      const regex = new RegExp(this.searchValue, "gi");
      return text.replace(regex, (matched) => `<strong>${matched}</strong>`);
    },
  },
};
</script>

<style lang="scss">
.select-scan-label {
  display: grid;
  grid-template-columns: 20px 1fr;

  .serial {
    font-size: 14.3px;
  }
}
.italic {
  font-style: italic;
}
</style>
