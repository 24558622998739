import {notify} from "../../../util/notify.js";

export const checkRowsPrepared = ({rows, translations}) => {
  const isAnyRowChecked = rows.some((row) => row.Checked?.Value === true);

  if (!isAnyRowChecked) {
    notify({message: translations["NothingToSave"], type: "warning"});
    return false;
  }

  const isAnyRowCheckedWithQuantity = rows.some(
    (row) =>
      row.Checked?.Value &&
      ((row.DeliveryAmount?.Value > 0 && row.Amount?.Value >= 0) ||
        (row.DeliveryAmount?.Value < 0 && row.Amount?.Value < 0)),
  );

  if (!isAnyRowCheckedWithQuantity) {
    notify({
      message: translations["OneOrMoreRowsNeedQuantity"],
      type: "warning",
    });
    return false;
  }

  return true;
};
