import {openChangeBusinessMomentsAvailabilityDialog} from "../functions/dialog/change-businessmoment-availability/openChangeBusinessMomentsAvailabilityDialog";
import {openBulkCapacityMutationDialog} from "../functions/dialog/bulk-mutate-capacity/openBulkCapacityMutationDialog";
import {cancelOrders} from "./cancelOrders";
import {deleteOrder} from "./deleteOrder";
import {openConvertQuotationToOrderDialog} from "../functions/dialog/convert-quotation-to-order/openConvertQuotationToOrderDialog";

export const getAction = ({actionName, targetTableName}) => {
  const actions = [
    {
      actionName: "Delete",
      targetTableName: "Rental.Order",
      actionFunction: deleteOrder,
    },
    {
      actionName: "ChangeBusinessMomentsAvailability",
      targetTableName: "Settings.BusinessMoment",
      actionFunction: openChangeBusinessMomentsAvailabilityDialog,
    },
    {
      actionName: "BulkCapacityMutation",
      targetTableName: "Settings.BusinessMoment",
      actionFunction: openBulkCapacityMutationDialog,
    },
    {
      actionName: "SetStatusCancel",
      targetTableName: "Rental.Order",
      actionFunction: cancelOrders,
    },
    {
      actionName: "CreateOrder",
      targetTableName: "Rental.Quotation",
      actionFunction: openConvertQuotationToOrderDialog,
    },
  ];

  const action = actions.find(
    (action) =>
      action.actionName === actionName &&
      action.targetTableName === targetTableName,
  );
  return action?.actionFunction ?? null;
};
