export const getExtraCriteriaValues = ({column, window}) => {
  const row = window.output.Table?.Rows[0];
  if (!row) return {};

  const inputCriteria = {};

  column.Dropdown.ExtraCriteria.forEach(
    ({SendRowValue, PrimaryKeyName, FormColumnName, Value}) => {
      const cell = row.find((cell) => cell.Column?.Name === FormColumnName);
      const rowValue = cell ? (cell.NewValue ?? cell.Value) : null;

      if (SendRowValue && rowValue) inputCriteria[PrimaryKeyName] = rowValue;

      if (!SendRowValue && Value) inputCriteria[PrimaryKeyName] = Value;
    },
  );

  return inputCriteria;
};
