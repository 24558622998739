import {getQuotationItems} from "../../../../../services/quotations/getQuotationItems";
import {getColumns} from "../../../../../functions/datagrid/columns/getColumns";
import {cloneDeep} from "lodash";
import {getQuotation} from "../../../../../services/quotations/getQuotation";
import {convertKeyValueRowsToCellRows} from "../../../../../functions/datagrid/rows/convertKeyValueRowsToCellRows";
import {processQuotationItemRowMetaData} from "../../../../../functions/datagrid/quotation-item/row/processQuotationItemRowMetaData";
import {setClientSideUUIDToRows} from "../../../../../functions/datagrid/rows/setClientSideUUIDToRows";
import {processColumnSettings} from "../../../../../functions/datagrid/columns/processColumnSettings";
import {sortColumns} from "../../../../../functions/datagrid/columns/sortColumns";
import {processDatagridDatepickers} from "../../../../../functions/datagrid/columns/processDatagridDatepickers";
import {sanitizeServerRows} from "../../../../../functions/datagrid/sanitizeServerRows";
import {setCustomRowsPropertiesFromCustomPropertyObject} from "../../../../../functions/datagrid/rows/setCustomRowsPropertiesFromCustomPropertyObject";
import {applyRentalQuotationItemPricesUpdatedDraftHook} from "../../../../../functions/datagrid/quotation-item/rows/applyRentalQuotationItemPricesUpdatedDraftHook";
import {prepareRowsForRender} from "../../../../../functions/datagrid/rows/prepareRowsForRender";
import {sortRows} from "../../../../../functions/datagrid/rows/sortRows";
import {processColumnsMetaData} from "../../../../../functions/datagrid/quotation-item/processColumnsMetaData";
import {getExtraTotalValues} from "../../../../../functions/datagrid/quotation-item/rows/getExtraTotalValues";
import {addCustFieldsAsRootPropertiesToRows} from "../../../../../functions/datagrid/quotation-item/rows/addCustFieldsAsRootPropertiesToRows";
import {setInitialDatagridDataToWindow} from "../../../../../functions/datagrid/data/setInitialDatagridDataToWindow";
import {rowsStockFetch} from "../../../../../functions/datagrid/quotation-item/rows/rowsStockFetch";

export const initializeDatagridQuotationItem = async ({vueInstance}) => {
  let [quotationItemReponse, columns, quotationResult] = await Promise.all([
    getQuotationItems({
      quotationId: vueInstance.quotationId,
      params: {
        include: `CompositionItem${
          !vueInstance.rentalQuotationItemPricesUpdatedDraftWebhookEvent
            ? ",PeriodPrices"
            : ""
        }`,
      },
    }),
    getColumns({
      table: "Rental.QuotationItem",
      prefix: "MultiEdit",
      primaryKey: "QuotationID",
      request: vueInstance.criteria,
    }),
    getQuotation({quotationId: vueInstance.quotationId}),
  ]);

  const quotation = quotationResult.data;

  let rows = await Promise.all(
    convertKeyValueRowsToCellRows(quotationItemReponse.data?.Collection).map(
      (x) => processQuotationItemRowMetaData({row: x, quotation}),
    ),
  );

  rows = setClientSideUUIDToRows({rows});
  rows = await rowsStockFetch({rows, quotation});

  vueInstance.rows = rows;

  columns = processColumnSettings(columns);
  columns = sortColumns(columns);
  columns = processDatagridDatepickers({columns});

  await sanitizeServerRows({rows, columns});

  vueInstance.columns = columns;

  rows = setCustomRowsPropertiesFromCustomPropertyObject({rows: rows});
  rows = await applyRentalQuotationItemPricesUpdatedDraftHook({
    rows,
    quotation,
    dataWebHooks: vueInstance.dataWebhooks,
  });

  rows = prepareRowsForRender({rows, columns});
  vueInstance.rows = rows;

  vueInstance.quotation = quotation;
  rows = await vueInstance.loadAndApplyRowDataForPage({
    rows: vueInstance.rows,
    page: vueInstance.page,
    pageSize: vueInstance.pageSize,
    quotation: vueInstance.quotation,
  });

  vueInstance.rows = sortRows(rows);
  vueInstance.loaded = true;

  await new Promise((r) => setTimeout(r, 0));
  vueInstance.columns = [].concat(
    await processColumnsMetaData({
      columns: vueInstance.columns,
      dataWebHooks: vueInstance.dataWebhooks,
    }),
  );

  vueInstance.originalRows = cloneDeep(vueInstance.rows);
  vueInstance.extraTotalValues = await getExtraTotalValues({
    rows: await addCustFieldsAsRootPropertiesToRows({
      rows: vueInstance.rows.filter((row) => !row.rowMeta?.virtual),
    }),
    quotation: vueInstance.quotation,
    dataWebHooks: vueInstance.dataWebhooks,
  });

  setInitialDatagridDataToWindow({
    window: vueInstance.rWindow,
    columns: vueInstance.columns.reduce((acc, column) => {
      acc[column.Name] = column;
      return acc;
    }, {}),
    rows: vueInstance.rows,
    vueInstance,
  });
};
