import {api} from "@/services/api";

export default async function getQuotationItemsStockPrediction({
  quotationItems,
  quotationId,
}) {
  const result = await api.post(
    `/v2/quotations/${quotationId}/_stock-prediction`,
    quotationItems,
  );

  return result.data;
}
