import {patchBusinessMoments} from "../../../services/dialog/patchBusinessMoments";

export const changeBusinessMomentsAvailability = async ({
  fields,
  businessMoments,
}) => {
  const availabilityPolicy = fields.AvailabilityPolicy;
  const patchBusinessMomentsData = businessMoments.map((businessMoment) => {
    return {
      BusinessMomentID: businessMoment.BusinessMomentID,
      AvailabilityPolicy: availabilityPolicy,
    };
  });

  return await patchBusinessMoments(patchBusinessMomentsData);
};
