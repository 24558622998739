<template>
  <div v-if="showDialog">
    <r-modal
      v-model="showDialog"
      :show="showDialog"
      :size="size"
      :title="title"
      :variant="variant"
      @close="hideDialog"
      @hide="hideDialog"
    >
      <component
        :is="template"
        v-if="showDialog && dialogData"
        :dialog-data="dialogData"
        @input="handleDialogDataChange"
      />
      <template #modal-footer>
        <component
          :is="footerTemplate"
          v-if="footerTemplate && dialogData"
          :dialog-data="dialogData"
          class="flex justify-end"
        />
        <dialog-close-button v-else class="w-100" />
      </template>
    </r-modal>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import {delay} from "../../util/delay";
import DialogCloseButton from "./DialogCloseButton.vue";
import RModal from "../elements/RModal.vue";

export default {
  name: "Dialog",
  components: {DialogCloseButton, RModal},
  provide() {
    return {
      translations: global.session.translations,
    };
  },
  data() {
    return {
      dialogData: {},
    };
  },
  computed: {
    showDialog() {
      return this.$store.getters["dialog/show"];
    },
    templateName() {
      return this.$store.getters["dialog/getTemplate"] ?? "Form";
    },
    template() {
      if (this.templateName === "") return null;
      return defineAsyncComponent(
        () => import(`./Dialog${this.templateName}.vue`),
      );
    },
    footerTemplate() {
      if (this.templateName === "") return null;

      return defineAsyncComponent({
        loader: async () => {
          try {
            // First, try to load the specific footer
            return await import(`./Dialog${this.templateName}Footer.vue`);
          } catch (e) {
            // If the specific footer doesn't exist and the template name contains "Form"
            if (this.templateName.includes("Form")) {
              try {
                // Try to load the generic FormFooter
                return await import(`./DialogFormFooter.vue`);
              } catch (e) {
                // If even the generic FormFooter doesn't exist, return null
                return {render: () => null};
              }
            }
            // If it's not a Form or the generic FormFooter doesn't exist, return null
            return {render: () => null};
          }
        },
        error: () => ({render: () => null}),
        delay: 200,
        timeout: 3000,
      });
    },
    title() {
      return this.$store.getters["dialog/getTitle"];
    },

    size() {
      return this.dialogData.DialogSize ?? "sm";
    },
    variant() {
      return this.dialogData.DialogVariant ?? "primary";
    },
  },
  async created() {
    global.eventBus.on("openDialog", this.setDialogData);
  },
  methods: {
    setDialogData({data}) {
      this.dialogData = {...data};
    },
    handleDialogDataChange(data) {
      this.dialogData = {...data};
    },
    async hideDialog() {
      if (
        this.dialogData.OnCloseGlobalActions &&
        this.dialogData.OnCloseGlobalActions.length
      ) {
        await delay(200);
        for (const globalAction of this.dialogData.OnCloseGlobalActions) {
          const importedModule = await import(
            `@/actions/${globalAction.Function}.js`
          );

          await importedModule.default(globalAction.Data);
        }
      }

      this.$store.commit("dialog/hideDialog");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
