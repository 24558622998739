import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Creates a list of default planning resource DTOs
 *
 * @param {Array} defaultPlanningResourceList - The list of default planning resource DTOs to create
 * @param {number} defaultPlanningResourceList[].ResourceID - The ID of the resource
 * @param {string} defaultPlanningResourceList[].Description - The description of the default planning resource
 * @param {number} defaultPlanningResourceList[].HourCapacity - The hour capacity of the resource
 * @returns {Promise<Array>} - Promise containing the created default planning resource DTOs with assigned DefaultPlanningResourceIDs
 */
export const createDefaultPlanningResource = async (
  defaultPlanningResourceList,
) => {
  return axiosInstanceWithErrorHandling(
    "/api/v2/warehouse/default-planning-resource",
    {
      method: "POST",
      data: defaultPlanningResourceList,
    },
  );
};
