import getQuotationItemsStockPrediction from "../../../../services/v2/rental/quotation/getQuotationItemsStockPrediction";
import {createQuotationItem} from "../../../../components/datagrid/datagridPopups/DatagridPopupStockPrediction/createQuotationItem";

export async function getStockForQuotationItems({rowsToLoad, quotation}) {
  const quotationItems = rowsToLoad.map((row) => {
    return createQuotationItem(row);
  });

  const stockPredictions = await getQuotationItemsStockPrediction({
    quotationItems,
    quotationId: quotation.QuotationID,
  });

  return stockPredictions;
}
