<template>
  <tr
    ref="row"
    :class="{
      ...rowClasses,
      'bg-white': odd && !rowClasses,
      'bg-gray-100': !odd && !rowClasses,
    }"
    class="normal-row"
    :style="styles"
    @click="$emit('click-row', value)"
  >
    <datagrid-row-action
      v-for="action in rowActions"
      v-if="!isExtraRow && actions.length"
      :value="row"
      :columns="rowColumns"
      :action="action"
      :index="index"
      :rowspan="extraRows"
      @action="onAction"
      @row-update="updateRow($event, column)"
    />

    <datagrid-cell-show-advanced
      v-if="!isExtraRow && advancedCells.length && !hideAdvanced"
      :value="(row.rowMeta || {}).showAdvanced"
      :rowspan="extraRows"
      @change="updateRowMeta(row, 'showAdvanced', $event)"
    />
    <datagrid-cell-wrapper
      v-for="column in rowColumns"
      :key="column.Name"
      :value="row"
      :column="column"
      :rows="rows"
      :columns="columns"
      :process-context="processContext"
      @change="handleChange"
      @cell-click="$emit('cell-click', $event)"
      @cell-icon-click="$emit('cell-icon-click', $event)"
      @icon-event="$emit('icon-event', $event)"
    />
  </tr>
</template>

<script>
import DatagridRowAction from "./DatagridRowAction.vue";
import DatagridCellWrapper from "./DatagridCellWrapper.vue";
import DatagridCellShowAdvanced from "@/components/datagrid/DatagridCellShowAdvanced";

export default {
  name: "DatagridRow",
  components: {
    DatagridCellShowAdvanced,
    DatagridRowAction,
    DatagridCellWrapper,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    rowColumns: {
      type: Array,
      required: true,
    },
    showActionColumn: {
      type: Boolean,
      default: true,
    },
    extraRows: {
      type: Number,
      default: 0,
    },
    isExtraRow: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    containsAdvancedColumns: {
      type: Boolean,
      default: false,
    },
    processContext: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rowActions() {
      const actions = this.actions;
      const rowActions = this.row.rowMeta?.actions ?? [];

      return actions.concat(rowActions);
    },
    row() {
      return this.value;
    },
    odd() {
      return this.index % 2 === 0;
    },
    hideAdvanced() {
      if (!this.containsAdvancedColumns) {
        return true;
      }
      return this.row.rowMeta && this.row.rowMeta.virtual === true;
    },
    rowClasses() {
      if (this.row.rowMeta) {
        return this.row.rowMeta.classes;
      }
      return undefined; //TODO: Temporary fix for row background colors on quotations, original: '{}'
    },
    styles() {
      if (this.row.rowMeta) {
        return this.row.rowMeta.styles;
      }
      return {};
    },
    advancedCells() {
      return this.columns.filter((column) => column.IsAdvancedColumn);
    },
  },
  methods: {
    onAction(action) {
      this.$emit("action", action);
    },
    handleChange(value) {
      this.$emit("change", value);
    },
    updateRow({row}, column) {
      const value = row[column.Name].Value;
      this.$emit("change", {Name: column.Name, Value: value});
    },
    updateRowMeta(row, key, value) {
      return this.$emit("change", {
        Name: "rowMeta",
        Value: {...row.rowMeta, [key]: value},
      });
    },
  },
};
</script>

<style scoped>
tr {
  font-size: 15.4px;
}
</style>
