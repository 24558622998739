<template>
  <r-modal
    :show="show"
    size="md"
    :title="translations['AddNewResource']"
    @hide="$emit('hide')"
  >
    <dialog-form
      :dialog-data="dialogData"
      :data-in-store="false"
      @input="handleInput"
    />

    <template #modal-footer>
      <div class="flex justify-end">
        <r-button variant="secondary" class="mr-2" @click="$emit('hide')">
          {{ translations["Cancel"] }}
        </r-button>
        <r-button variant="primary" @click="handleAddResource">
          {{ translations["AddResource"] }}
        </r-button>
      </div>
    </template>
  </r-modal>
</template>

<script>
import RButton from "../../../elements/RButton.vue";
import DialogForm from "../../../dialog/DialogForm.vue";
import RModal from "../../../elements/RModal.vue";
import {getTranslations} from "../../../../functions/session/getTranslations.js";

const translations = getTranslations();

export default {
  name: "RGridViewPlanningWrapperAddResourceModal",
  components: {
    RButton,
    DialogForm,
    RModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    // Pass the days of the week for the MultiSelect
    weekDays: {
      type: Array,
      default: () => [
        {name: "Monday", title: translations["DescriptionMonday"]},
        {name: "Tuesday", title: translations["DescriptionTuesday"]},
        {name: "Wednesday", title: translations["DescriptionWednesday"]},
        {name: "Thursday", title: translations["DescriptionThursday"]},
        {name: "Friday", title: translations["DescriptionFriday"]},
        {name: "Saturday", title: translations["DescriptionSaturday"]},
        {name: "Sunday", title: translations["DescriptionSunday"]},
      ],
    },
  },
  data() {
    // Create day options for the MultiSelect
    const dayOptions = this.weekDays.map((day) => ({
      Value: day.name,
      Text: day.title,
    }));

    return {
      translations,
      dialogData: {
        Fields: {
          Description: {
            Name: "Description",
            Type: "text",
            Required: true,
            Value: "",
            IsRequired: true,
            Title: translations["Description"],
            Placeholder: translations["EnterDescription"],
          },
          HourCapacity: {
            Name: "HourCapacity",
            Type: "number",
            Required: true,
            Value: 8,
            Title: translations["HourCapacity"],
            Placeholder: translations["EnterNumberOfHours"],
          },
          StartTime: {
            Name: "StartTime",
            Type: "time",
            Required: false,
            IsRequired: false,
            Value: null,
            Title: translations["StartTime"],
            Editor: "Time",
            Description:
              translations["OptionalForPlanning"] ||
              "Optional for creating plannings",
          },
          EndTime: {
            Name: "EndTime",
            Type: "time",
            Required: false,
            IsRequired: false,
            Value: null,
            Title: translations["EndTime"],
            Editor: "Time",
            Description:
              translations["OptionalForPlanning"] ||
              "Optional for creating plannings",
          },
          Days: {
            Name: "Days",
            Type: "select",
            Required: true,
            IsRequired: true,
            Value: [], // Default to no selection
            Title: translations["Days"],
            Editor: "MultiSelect",
            Options: dayOptions,
          },
        },
      },
    };
  },
  watch: {
    show(isVisible) {
      if (isVisible) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      this.dialogData.Fields.Description.Value = "";
      this.dialogData.Fields.HourCapacity.Value = 0;
      this.dialogData.Fields.Days.Value = []; // Reset to no selection
    },
    handleInput(fields) {
      this.dialogData.Fields = fields;
    },
    handleAddResource() {
      const description = this.dialogData.Fields.Description.Value;
      const hourCapacity = this.dialogData.Fields.HourCapacity.Value;
      const selectedDays = this.dialogData.Fields.Days.Value || ["Monday"];

      if (!description || !selectedDays.length) return;

      // Create a resource object with the selected days
      const newResource = {
        Description: description,
        HourCapacity: hourCapacity,
        ResourceID: undefined,
        Days: selectedDays,
        StartTime: this.dialogData.Fields.StartTime.Value,
        EndTime: this.dialogData.Fields.EndTime.Value,
      };

      this.$emit("add-resource", newResource);
      this.resetForm();
    },
  },
};
</script>
