<template>
  <div>
    <div v-html="markdownParsedDescription" />
    <div v-for="(field, key) in fields" :key="key">
      <form-field
        :key="key"
        :value="fields[key]"
        :field="fields[key]"
        :row="row"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import {formContainsFieldWithExtraCriteria} from "../../functions/form/formContainsFieldWithExtraCriteria.js";
import {createRowFromFieldsObject} from "../../functions/fields/createRowFromFieldsObject.js";
import {processDialogFormExtraCriteria} from "../../functions/dialog/form/processDialogFormExtraCriteria.js";
import FormField from "../form/FormField.vue";
import store from "../../../state/store";
import nmd from "nano-markdown";

export default {
  name: "DialogForm",
  components: {FormField},
  provide() {
    return {
      translations: global.session.translations,
    };
  },
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
    dataInStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: this.dialogData.Fields,
    };
  },
  computed: {
    actions() {
      return this.dialogData?.GlobalActions;
    },
    row() {
      return createRowFromFieldsObject({fields: this.fields});
    },
    markdownParsedDescription() {
      if (!this.dialogData?.Description) {
        return null;
      }
      return nmd(this.dialogData.Description);
    },
  },
  watch: {
    dialogData: {
      handler() {
        this.fields = {...this.dialogData.Fields};
      },
    },
  },
  created() {
    // mutate all fields, if name property is empty set the key of the property to it
    if (this.fields)
      this.fields = Object.keys(this.fields).reduce((acc, key) => {
        const field = this.fields[key];
        if (!field.Name) {
          field.Name = key;
        }
        acc[field.Name] = field;
        return acc;
      }, {});
  },
  methods: {
    async handleInput(field) {
      this.fields = {
        ...this.fields,
        [field.Name]: field,
      };

      if (formContainsFieldWithExtraCriteria({fields: this.fields})) {
        const newFields = await processDialogFormExtraCriteria({
          changedField: field,
          fields: Object.values(this.fields),
        });

        this.fields = newFields.reduce((acc, field) => {
          acc[field.Name] = field;
          return acc;
        }, {});
      }

      if (
        store.state.dialog?.data &&
        Object.keys(store.state.dialog?.data?.Fields)?.length > 0
      ) {
        Object.keys(store.state.dialog.data.Fields).forEach((key) => {
          const field = store.state.dialog.data.Fields[key];
          return (field.Value = this.row[field.Name]);
        });
      }
      if (!this.dataInStore) {
        this.$emit("input", this.fields);
      }
    },
  },
};
</script>
