import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Fetches planning resource data with pagination, sorting, and filtering options
 *
 * @param {Object} options - Request options
 * @param {Object} options.params - Query parameters for the request
 * @param {string} [options.params.startDate] - The start date to filter on (ISO format)
 * @param {string} [options.params.endDate] - The end date to filter on (ISO format)
 * @param {number} [options.params.index=1] - The index of the page
 * @param {number} [options.params.size=0] - The size of the page
 * @param {string} [options.params.search] - The string to search on
 * @param {string} [options.params.sortOn] - The name of the column to sort on
 * @param {boolean} [options.params.sortReverse=false] - True to sort in reverse order
 * @param {string} [options.params.planningResourceID] - The planningResourceID(s) to filter on (comma separated)
 * @param {string} [options.params.defaultPlanningResourceID] - The defaultPlanningResourceID(s) to filter on (comma separated)
 * @param {string} [options.params.resourceID] - The resourceID(s) to filter on (comma separated)
 * @returns {Promise<Object>} - Promise containing the paginated planning resource data
 * @returns {Array} - Promise.Collection - The array of planning resource objects
 * @returns {number} - Promise.Index - The current page index
 * @returns {number} - Promise.Size - The page size
 * @returns {number} - Promise.Total - The total number of records
 */
export const fetchPlanningResource = async ({params} = {}) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning-resource", {
    params,
  });
};
