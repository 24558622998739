import {handleNegativeDeliveryAmountChange} from "./handleNegativeDeliveryAmountChange";

export function handleDeliveryAmountChange({row, oldRow}) {
  if (row.Amount.Value < 0)
    return handleNegativeDeliveryAmountChange({row, oldRow});

  if (row.DeliveryAmount.Value < 0) return oldRow;

  if (row.DeliveryAmount.Value === 0)
    return {
      ...row,
      Checked: {
        ...row.Checked,
        Name: "Checked",
        Value: false,
      },
    };

  if (row.DeliveryAmount.Value > row.Amount.Value)
    return {
      ...row,
      Checked: {
        ...row.Checked,
        Name: "Checked",
        Value: true,
      },
      DeliveryAmount: {
        ...row.DeliveryAmount,
        Name: "DeliveryAmount",
        Value: row.Amount.Value,
      },
    };

  if (
    row.DeliveryAmount.Value > 0 &&
    row.DeliveryAmount.Value <= row.Amount.Value
  )
    return {
      ...row,
      Checked: {
        ...row.Checked,
        Name: "Checked",
        Value: true,
      },
    };

  return oldRow;
}
