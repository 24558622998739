import {getDateTimePickedMapping} from "./getDateTimePickedMapping";

export const createRowsBasedOnQuantity = ({formattedRow}) => {
  // Process tracked bulk picks to determine which date to use for each item
  const dateTimePickedMap = getDateTimePickedMapping({row: formattedRow});

  let rowsToReturn = [];
  for (let i = 0; i < formattedRow.OrderedQuantity; i++) {
    const dateTimePicked =
      i < dateTimePickedMap.length ? dateTimePickedMap[i] : null;

    rowsToReturn.push({
      ...formattedRow,
      OrderedQuantity: 1,
      PickedQuantity: i < formattedRow.PickedQuantity ? 1 : 0,
      Status: i < formattedRow.PickedQuantity ? "Picked" : "Open",
      StatusCode: i < formattedRow.PickedQuantity ? 150 : 100,
      PickListItemID:
        formattedRow.PickListItemIDs[i - 1] ?? formattedRow.PickListItemID,
      DateTimePicked:
        i < formattedRow.PickedQuantity
          ? dateTimePickedMap.length
            ? dateTimePicked
            : formattedRow.DateTimePicked
          : null,
    });
  }

  return rowsToReturn;
};
