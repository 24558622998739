<template>
  <div class="h-[32.5px]">
    <div class="flex">
      <input
        :id="fieldName"
        ref="autoIncrementField"
        v-model="fieldValue"
        type="text"
        class="form-control editable-text"
        :maxlength="field.Length"
        :readonly="readonly"
        :disabled="readonly"
        :title="fieldValue"
        @change="handleChange"
      />
      <div v-if="!field.IsReadOnly">
        <div class="absolute right-[53.5px] top-[35.5px] cursor-pointer">
          <span
            variant="link"
            @keyup.enter.prevent="console.log()"
            @click="toggleAutoNumber"
            ><i class="fas fa-repeat"></i
          ></span>
        </div>
        <div class="absolute right-[28.5px] top-[34.5px] cursor-pointer">
          <span
            variant="link"
            @keyup.enter.prevent="console.log()"
            @click="toggleReadOnly"
            ><i class="fas fa-edit text-black"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoIncrementField",
  inject: ["translations"],
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    initialRow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      fieldValue: null,
      fieldName: this.field.Name ?? "",
      autoNumber: false,
      originalString: this.field.Value,
      readonly: true,
      autoIncrementDescription:
        this.translations.NewFormAutoincrementingDescription,
    };
  },
  watch: {
    "field.Value": {
      handler(newValue) {
        this.fieldValue = newValue;
      },
    },
    initialRow: {
      handler() {
        this.originalString = this.initialRow[this.field.Name];
      },
    },
  },
  created() {
    this.fieldValue = this.originalString;
  },
  methods: {
    toggleAutoNumber() {
      this.autoNumber = !this.autoNumber;

      if (this.originalString === this.autoIncrementDescription) {
        this.autoNumber = true;
      }

      if (this.autoNumber) {
        this.readonly = true;
        this.fieldValue = this.autoIncrementDescription;
      } else {
        this.fieldValue = this.originalString;
      }

      this.handleChange(this.fieldValue);
      event.preventDefault();
    },
    toggleReadOnly(event) {
      this.readonly = !this.readonly;
      this.autoNumber = false;

      if (this.readonly) {
        this.fieldValue = this.originalString;
      }

      // For an existing form
      if (
        !this.readonly &&
        this.originalString === this.autoIncrementDescription
      ) {
        this.fieldValue = "";
        this.$refs.autoIncrementField.focus();
      }

      // For a new form
      if (
        !this.readonly &&
        this.originalString !== this.autoIncrementDescription
      ) {
        this.fieldValue = this.originalString;
        this.$refs.autoIncrementField.focus();
      }

      this.handleChange(this.fieldValue);
      event.preventDefault();
    },
    handleChange(event) {
      this.$emit("input", {
        Value: event?.target?.value.toString() ?? this.fieldValue,
      });
    },
  },
};
</script>

<style></style>
