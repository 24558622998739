import templateMapping from "../../interface/templater/templateMapping";

export function getWindowTemplateMapping({window}) {
  if (!window) {
    return undefined;
  }
  const {Subject, Prefix} = window.output.Request;

  let template;

  if (Subject && Prefix) {
    template = templateMapping.find(
      (mapping) =>
        mapping.Subject === Subject && mapping.Prefix.includes(Prefix),
    );
  }

  const templateName = window.output.Data?.Type;

  if (!templateName) return;

  const matchedTemplate = templateMapping.find(
    (mapping) => mapping.template === templateName,
  );

  return matchedTemplate ?? template;
}
