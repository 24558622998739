<template>
  <div class="z-50 bg-white">
    <div class="flex justify-end mb-3 mt-1 items-center">
      <div>
        <label class="form-field min-w-[200px]">
          <div class="form-label ml-0 pl-0">
            <i class="fas fa-clipboard-list"></i>
            {{ translations["Warehouse"] }}:
          </div>

          <r-select :value="warehouse" :read-only="true" />
        </label>
      </div>
    </div>
    <div class="border border-dashed border-gray-900/25 rounded-md p-1">
      <div class="min-h-[600px] pt-2 m-4 flex">
        <div>
          <div class="text-center flex">
            <div class="mr-4">
              <p :title="itemLabel">
                {{ translations["ItemID"] }}. <br /><strong>{{
                  sliceWithEllipsis(itemLabel, 35)
                }}</strong>
              </p>
            </div>

            <div>
              <p>
                {{ translations["AvailabilityList_SelectedPeriod"]
                }}<br /><strong>{{ startDate + " - " + endDate }}</strong>
              </p>
            </div>
          </div>

          <div class="flex justify-center">
            <table
              v-for="(item, index) in items"
              :key="index"
              class="table table-sm"
              :class="{'!mr-6 min-w-[250px]': index === 0 && items.length > 1}"
            >
              <tbody>
                <tr
                  style="border-top: 1px solid #dee2e6"
                  class="text-right font-bold"
                >
                  <td
                    colspan="2"
                    :class="{
                      'min-w-[80px]': index !== 0,
                      'bg-gray-100': index === 0,
                    }"
                  >
                    {{ item[0].value }}
                  </td>
                </tr>
                <tr
                  v-for="(property, ranking) in item.slice(1)"
                  :key="property.title"
                >
                  <td
                    v-if="index === 0"
                    class="text-nowrap bg-gray-100"
                    :class="{'font-bold': ranking === 10}"
                  >
                    {{ property.title }}
                  </td>
                  <td
                    :class="[
                      computedValueClass(ranking),
                      {'pl-8 bg-gray-100': index === 0},
                    ]"
                    class="text-right"
                  >
                    {{ property.value ?? 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="my-1">
      <r-button
        variant="primary"
        class="m-0 mt-2"
        @click="$emit('close-item-stock-dialog', $event)"
      >
        <i class="fas fa-arrow-left"></i> {{ translations["Back"] }}
      </r-button>
    </div>
  </div>
</template>

<script>
import {getDescriptionFromDropdownValue} from "../../util/parsing/getDescriptionFromDropdownValue.js";
import {getRawValueFromDropdownValue} from "../../util/parsing/getRawValueFromDropdownValue.js";
import {getItemStockDialogItems} from "../../services/dialog/getItemStockDialogItems.js";
import {sliceWithEllipsis} from "../../util/sliceWithEllipsis.js";
import {getTranslations} from "../../functions/session/getTranslations.js";
import {getItem} from "../../services/v2/items/getItem.js";
import RButton from "../elements/RButton.vue";
import RSelect from "../elements/RSelect.vue";
import dayjs from "dayjs";

export default {
  name: "DialogItemStock",
  components: {
    RSelect,
    RButton,
  },
  props: {
    segment: {
      type: Object,
      required: true,
    },
    warehouse: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      translatedDetails: {},
      items: [],
      rentalItem: {},
    };
  },
  computed: {
    startDate() {
      return dayjs(this.segment.start).format("YYYY-MM-DD");
    },
    endDate() {
      return dayjs(this.segment.end).format("YYYY-MM-DD");
    },
    translations() {
      return getTranslations();
    },
    itemLabel() {
      return `${this.rentalItem.ItemID ?? ""} | ${
        this.rentalItem.Description ?? ""
      }`;
    },
  },
  async created() {
    this.rentalItem = await getItem(
      this.segment.criteria.ItemID ?? this.segment.criteria.itemID,
    );
    const retrievedItems = await getItemStockDialogItems({
      itemId: this.segment.criteria.ItemID ?? this.segment.criteria.itemID,
      startDate: dayjs(this.segment.start).format("YYYY-MM-DD"),
      warehouseId: this.warehouse,
    });

    let items = retrievedItems.map((item) => {
      const itemKeys = Object.keys(item);
      const itemValues = Object.values(item);

      return itemKeys.map((key, index) => {
        return {
          title: this.getTranslatedTitle(getRawValueFromDropdownValue(key)),
          value: getDescriptionFromDropdownValue(itemValues[index]),
        };
      });
    });

    const summedItem = [];

    for (const item of items) {
      item.splice(1, 1);
      for (const index in item) {
        const property = item[index];
        if (index === "0") {
          summedItem[index] = {
            title: property.title,
            value: this.translations["Total"],
          };
          continue;
        }

        summedItem[index] = {
          title: property.title,
          value: (summedItem[index]?.value ?? 0) + property.value,
        };
      }
    }

    items.unshift(summedItem);

    if (retrievedItems.length === 1) {
      items.splice(1, 1);
    }

    this.items = items;
  },
  methods: {
    sliceWithEllipsis,
    computedValueClass(title) {
      switch (title) {
        case 0:
          return "text-right";
        case 1:
          return "text-right";
        case 2:
          return "text-right";
        case 3:
          return "text-right text-danger";
        case 4:
          return "text-right text-warning";
        case 5:
          return "text-right";
        case 6:
          return "text-right text-warning";
        case 7:
          return "text-right text-warning";
        case 8:
          return "text-right text-danger";
        case 9:
          return "text-right";
        case 10:
          return "text-right text-success font-bold";
        default:
          return "text-right";
      }
    },
    getTranslatedTitle(title) {
      if (
        title === "InPurchase" ||
        title === "Blocked" ||
        title === "Reserved" ||
        title === "Commited" ||
        title === "Deprecated" ||
        title === "Total"
      ) {
        return this.translations[`AvailabilityList_${title}`];
      }

      return this.translations[title];
    },
  },
};
</script>
