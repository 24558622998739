<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :page-size="pageSize"
        :skeleton-rows="addItemQueue.length"
        @loading="loading = $event"
        @click-row="handleRowClick"
        @cell-icon-click="handleCellIconClick($event)"
        @page-change="handlePageChange({page: $event})"
        @rows-updated="handleRowsUpdated"
      >
        <template #header>
          <datagrid-header-stock-transfer-item
            :loaded="loaded"
            :scan-amount="scanAmount"
            :settings="settings"
            :page-size="pageSize"
            @item-select="addItem"
            @page-size-change="handlePageSizeChange"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {initializeDatagridStockTransferItem} from "./utils/initializeDatagridStockTransferItem";
import {rowsSetNullValueForMissingProps} from "../../../../functions/datagrid/rows/rowsSetNullValueForMissingProps";
import {saveDatagridStockTransferItem} from "./utils/saveDatagridStockTransferItem";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {detectDatagridDataChanges} from "../../../../functions/datagrid/detectDatagridDataChanges.js";
import {convertRowToliRowDataRow} from "../../../../functions/datagrid/order-item/row/convertRowToliRowDataRow.js";
import {integrateRowsChanges} from "../../../../functions/datagrid/rows/integrateRowsChanges";
import {loadRowDataForPage} from "../../../../functions/datagrid/stock-transfer-item/loadRowDataForPage";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {removePropFromRows} from "../../../../functions/datagrid/stock-transfer-item/removePropFromRows";
import {mapActionComponent} from "../../../../functions/datagrid/mapActionComponent";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {addNewRows} from "../../../../functions/datagrid/stock-transfer-item/addNewRows";
import {getUserId} from "../../../../functions/session/getUserId";
import {cloneDeep} from "lodash";
import DatagridHeaderStockTransferItem from "../../datagridHeaders/DatagridHeaderStockTransferItem.vue";
import DatagridStandalone from "../../DatagridStandalone.vue";
import {setLocalStoragePageSize} from "../../../../functions/datagrid/setLocalStoragePageSize";

export default {
  name: "DatagridStockTransferItem",
  components: {
    DatagridHeaderStockTransferItem,
    DatagridStandalone,
  },
  provide() {
    return {
      translations: this.translations,
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    rParentWindow: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      loadingPage: false,
      criteria: [],
      rows: [],
      initialRows: [],
      originalRows: [],
      columns: {},
      stockTransferId: null,
      window: {},
      activeRow: null,
      selectedItemId: null,
      addItemQueue: [],
      loading: false,
      scanAmount: 1,
      page: 1,
      pageSize:
        JSON.parse(localStorage.getItem("datagridPageSizes"))
          ?.StockTransferItem ?? 50,
      translations: getTranslations(),
      actions: [{type: "Delete"}].map(mapActionComponent),
    };
  },
  computed: {
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Warehouse.StockTransferItem-Multi`;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    await initializeDatagridStockTransferItem({
      vueInstance: this,
    });
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);
      if (this.addItemQueue.length > 1) {
        return;
      }

      let rows = cloneDeep(this.rows);

      const requestDataRows = removePropFromRows({
        prop: "Description",
        rows: rows,
      });

      const requestData = {
        subject: this.rWindow.subject,
        criteria: this.criteria,
        search: item.value,
        rows: requestDataRows,
        windowId: this.rWindow.id,
      };

      while (this.addItemQueue.length > 0) {
        const queuedItem = this.addItemQueue[0];
        const newRows = await addNewRows({
          item: queuedItem,
          rows: this.rows,
          scanAmount: this.scanAmount,
          requestData: requestData,
        });

        this.updateRows(newRows);
        this.updateWindowData();
        this.addItemQueue.shift();
      }

      this.rows = rowsSetNullValueForMissingProps({
        rows: this.rows,
        columns: this.columns,
      });

      this.navigateToLastPage();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        this.$store.state.loading = true;
        document.activeElement.blur();

        while (this.loading || this.loadingPage) {
          await new Promise((r) => setTimeout(r, 0));
        }

        await saveDatagridStockTransferItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);
        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        await initializeDatagridStockTransferItem({
          vueInstance: this,
        });
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async loadAndApplyRowDataForPage() {
      for (const row of this.rows) {
        row.SerialID.IsReadOnly = false;
        row.Description.IsReadOnly = true;
      }

      const changedRowsObject = await loadRowDataForPage({
        rows: this.rows,
        page: this.page,
        pageSize: this.pageSize,
      });

      return integrateRowsChanges({
        originalRows: this.rows,
        changedRows: changedRowsObject,
      });
    },
    updateRows(newRows) {
      newRows.forEach((newRow) => {
        const existingRow = this.rows.find(
          (row) => row.ClientSideUUID.Value === newRow.ClientSideUUID.Value,
        );
        if (existingRow) {
          // Update properties of the existing row
          Object.assign(existingRow, newRow);
        } else {
          // Add new row if it does not exist yet
          this.rows.push(newRow);
        }
      });
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
      });
    },
    handlePageSizeChange(pageSize) {
      setLocalStoragePageSize({entity: "StockTransferItem", pageSize});
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    navigateToLastPage() {
      this.page = Math.ceil(this.rows.length / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleCellIconClick({row, cell}) {
      if (cell.Name === "Amount") {
        this.selectedItemId = row.ItemID.Value;
        setTimeout(() => {
          this.selectedItemId = null;
        }, 300);
      }
    },
    handleRowsUpdated({rows}) {
      this.rows = rows;
      this.updateWindowData();
    },
    updateWindowData() {
      const columnsObject = this.columns.reduce((acc, column) => {
        acc[column.Name] = column;
        return acc;
      }, {});

      const windowData = {
        ...this.rWindow.data,
        Columns: columnsObject,
        Rows: this.rows.map(convertRowToliRowDataRow),
        dirty: detectDatagridDataChanges({
          newRows: this.rows,
          originalRows: this.originalRows,
          columns: this.columns,
        }),
      };

      this.$emit("data-change", {
        windowId: this.rWindow.id,
        newData: windowData,
      });
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
  },
};
</script>
