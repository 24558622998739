import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Updates a list of default planning DTOs
 *
 * @param {Array} defaultPlanningList - The list of default planning DTOs to update
 * @param {Object} defaultPlanningList[].DefaultPlanningID - The ID of the default planning
 * @param {Object} defaultPlanningList[].DefaultPlanningResourceID - The ID of the default planning resource
 * @param {string} defaultPlanningList[].Day - The day in ISO format (e.g., "2025-03-05T12:30:18.368Z")
 * @param {string} defaultPlanningList[].StartTime - The start time
 * @param {string} defaultPlanningList[].EndTime - The end time
 * @param {string} defaultPlanningList[].WarehouseID - The ID of the warehouse
 * @returns {Promise<Array>} - Promise containing the updated default planning DTOs
 */
export const updateDefaultPlanning = async (defaultPlanningList) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/default-planning", {
    method: "PATCH",
    data: defaultPlanningList,
  });
};
