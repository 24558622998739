import {processDialogLinkedFormFieldFromCriteria} from "./processDialogLinkedFormFieldFromCriteria.js";

export const processDialogChangedFieldExtraCriteria = async ({
  fields,
  changedField,
}) => {
  const extraCriteria = changedField.Dropdown.ExtraCriteria;

  for (const criterion in extraCriteria) {
    const linkedField = fields.find(
      (field) => field.Name === extraCriteria[criterion]?.FormColumnName,
    );

    if (!linkedField) continue;

    if (linkedField.NewValue ?? linkedField.Value) {
      return await processDialogLinkedFormFieldFromCriteria({
        fields,
        changedField,
        linkedField,
      });
    }
  }

  return fields;
};
