<template>
  <input
    :id="fieldName"
    :value="value"
    type="time"
    class="form-control editable-text placeholder:text-primary"
    :readonly="readonly"
    :disabled="readonly"
    :title="value"
    @blur="handleChange"
  />
</template>

<script>
export default {
  name: "TimeField",
  props: {
    field: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    rowCell: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    value() {
      return this.field.Value;
    },
    fieldName() {
      return this.field.Name ?? "";
    },
    readonly() {
      return this.field.IsReadOnly;
    },
  },
  methods: {
    handleChange(event) {
      let time = event.target?.value;

      this.$emit("input", time);
    },
  },
};
</script>

<style scoped>
input[readonly] {
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #888;
}
</style>
