<template>
  <div class="grid grid-cols-12 w-full">
    <form-field
      v-for="field in sortedFormFields"
      :key="field.Name"
      :class="getFieldWidth(field.ColumnWidth)"
      :value="fields[field.Name]"
      :field="field"
      :row="row"
      :warehouse-id="warehouseId"
      :window-id="windowId"
      @input="updateField(field.Name, $event)"
      @input:search-value="$emit('input', {Name: field.Name, $event})"
    />
  </div>
</template>

<script>
import FormField from "@/components/form/FormField";
import orderBy from "lodash/orderBy";

export default {
  name: "RowForm",
  components: {FormField},
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    orderBy: {
      type: String,
      default: "Ranking",
    },
    warehouseId: {
      type: String,
      required: false,
      default: null,
    },
    windowId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    sortedFormFields() {
      const fieldsArray = Object.values(this.fields);

      if (fieldsArray.length && !fieldsArray[0][this.orderBy]) {
        return fieldsArray;
      }
      return orderBy(fieldsArray, [this.orderBy], ["asc"]);
    },
  },
  methods: {
    getFieldWidth(columnWidth) {
      //col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12
      return `col-span-${columnWidth}`;
    },
    updateField(fieldName, cell) {
      this.$emit("change", {Name: fieldName, ...cell});
    },
  },
};
</script>

<style></style>
