<template>
  <div
    class="col-xs-12"
    :class="{
      'col-lg-10': settings.HideRailRoad,
      'col-lg-8': !settings.HideRailRoad,
    }"
  >
    <form-section
      v-for="section in formattedSections"
      :key="section.id"
      :section="section"
      :settings="settings"
      :form-fields="formFields"
      :initial-row="initialRow"
      @field-change="$emit('field-change', $event)"
    />
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";

export default {
  name: "FormContent",
  components: {
    FormSection,
  },
  props: {
    formFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    sections: {
      type: Object,
      required: true,
    },
    initialRow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedSections() {
      return Object.values(this.sections);
    },
  },
};
</script>
