<template>
  <div class="relative mb-3">
    <div class="flex-col w-full relative">
      <div class="flex w-full flex-row">
        <div class="relative">
          <div
            v-if="railroadItem.Blocking"
            class="absolute rounded-full border-2 border-primary"
            :class="{
              'w-8 h-8 -top-1 -left-1': !isSubTask,
              'w-7 h-7 -top-1 -left-0.5': isSubTask,
            }"
          ></div>
          <div
            class="pr-2 flex flex-column align-items-center"
            :class="{
              'pl-0.5': !isSubTask,
              'mt-4': isAvailable && !isSubTask,
              'mt-1.5': !isAvailable && !isSubTask,
              'bg-gray-30 h-1 w-10': isSubTask,
            }"
          >
            <form-sidebar-railroad-item-icon-success
              v-if="railroadItem.Completed || railroadItem.Status === 'Skipped'"
              :small="railroadItem.Sub"
              :read-only="railroadItem.Available"
              :is-available="isAvailable"
            />
            <form-sidebar-railroad-item-icon-cancelled
              v-else-if="railroadItem.Cancelled || !railroadItem.Available"
              :small="railroadItem.Sub"
              :read-only="railroadItem.Available"
              :is-available="isAvailable"
            />
            <form-sidebar-railroad-item-icon-in-progress
              v-else-if="railroadItem.InProgress"
              :small="railroadItem.Sub"
              :read-only="railroadItem.Available"
              :is-available="isAvailable"
            />

            <form-sidebar-railroad-item-icon-todo
              v-else
              :small="railroadItem.Sub"
              :read-only="railroadItem.Available"
              :is-available="isAvailable"
            />
          </div>
        </div>
        <div class="group w-full flex flex-column">
          <div class="flex flex-row">
            <div
              :class="{
                'pointer-events-none': $store.state.headless,
                'w-full': true,
              }"
            >
              <div class="">
                <div
                  v-if="railroadItem.Resources.length && railroadItem.Sub"
                  class="flex justify-start"
                >
                  <form-sidebar-railroad-item-resources
                    :resources="railroadItem.Resources"
                    :is-sub-task="isSubTask"
                  />
                </div>
              </div>
              <!-- TASK Title -->

              <div class="flex justify-between hover:cursor-pointer">
                <div
                  v-if="!railroadItem.Sub"
                  class="font-bold w-full group-hover:text-primary'"
                  @click="
                    processEvent(
                      railroadItem.Action,
                      railroadItem.LegacyAction,
                      !railroadItem.Sub,
                    )
                  "
                >
                  <div class="flex justify-between">
                    <div>
                      {{ railroadItem.Title }}
                      <div
                        v-if="railroadItem.IsQueued"
                        class="inline-block ml-[1px]"
                      >
                        <tooltip
                          :message="translations.TaskIsQueued"
                          tooltip-position="top-end"
                        >
                          <i class="fad fa-business-time"></i>
                        </tooltip>
                      </div>
                      <span v-if="railroadItem.Blocking" class="text-primary"
                        >*</span
                      >
                    </div>
                    <span
                      v-if="railroadItem.NumberOfTasks > 1"
                      class="bg-gray-400 text-white rounded-full mx-1 text-xs w-5 h-5 flex items-center justify-center"
                    >
                      {{ railroadItem.NumberOfTasks }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- END TASK Title -->

              <!-- TASK Description -->
              <small
                v-if="!railroadItem.Sub"
                class="hover:cursor-pointer text-muted"
                :class="{
                  'font-light': railroadItem.Sub,
                }"
                @click="
                  processEvent(
                    railroadItem.Action,
                    railroadItem.LegacyAction,
                    !railroadItem.Sub,
                  )
                "
                >{{ railroadItem.Description }}
              </small>
              <!-- END TASK Description -->

              <!-- SUB TASK Description -->
              <rail-road-item-sub-task-description
                v-else
                :railroad-item="railroadItem"
              />
              <!-- END SUB TASK Description -->
            </div>
            <div
              v-if="railroadItem.Resources.length && !railroadItem.Sub"
              class="mt-1"
            >
              <form-sidebar-railroad-item-resources
                :resources="railroadItem.Resources"
                :is-sub-task="isSubTask"
              />
            </div>
          </div>
          <div v-if="isAvailable" class="flex justify-between w-full">
            <!-- TASK add button -->
            <div
              v-if="railroadItem.AllowNewTasks"
              class="text-primary cursor-copy mt-2"
              @click="
                openAddTaskDialog({parentTaskId: railroadItem.TaskID || null})
              "
            >
              <i class="fas fa-plus-square"></i>
            </div>
            <!-- END TASK add button -->

            <!-- TASK reference button -->
            <div
              v-if="!railroadItem.Sub && railroadItem.Enabled"
              class="text-primary cursor-alias mt-[0.5px] text-[18px] lg:mr-1"
              @click="
                processEvent(
                  railroadItem.Action,
                  railroadItem.LegacyAction,
                  false,
                )
              "
            >
              <i class="fas fa-external-link-alt"></i>
            </div>

            <!-- END TASK reference button -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSidebarRailroadItemIconInProgress from "./FormSidebarRailroadItemIconInProgress.vue";
import FormSidebarRailroadItemIconCancelled from "./FormSidebarRailroadItemIconCancelled.vue";
import FormSidebarRailroadItemIconSuccess from "./FormSidebarRailroadItemIconSuccess.vue";
import FormSidebarRailroadItemResources from "./FormSidebarRailroadItemResources.vue";
import FormSidebarRailroadItemIconTodo from "./FormSidebarRailroadItemIconTodo.vue";
import RailRoadItemSubTaskDescription from "./railroad-item/RailroadItemSubTaskDescription.vue";
import Tooltip from "../../util/Tooltip.vue";

import {confirmDiscardUnsavedChanges} from "../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {handleJsFunctionPayload} from "../../../functions/window/handleJsFunctionPayload";
import openTaskCreateDialog from "../../../services/railroad/openTaskCreateDialog";
import processLegacyEvent from "../../../actions/processLegacyEvent";
import action from "../../../functions/action";

export default {
  name: "FormSidebarRailroadItem",
  components: {
    FormSidebarRailroadItemIconInProgress,
    FormSidebarRailroadItemIconCancelled,
    FormSidebarRailroadItemIconSuccess,
    FormSidebarRailroadItemResources,
    FormSidebarRailroadItemIconTodo,
    RailRoadItemSubTaskDescription,
    Tooltip,
  },
  props: {
    railroadItem: {
      type: Object,
      required: true,
    },
    rWindow: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoadingDialog: false,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    subject() {
      return this.$store.state.window.output?.Request.Subject;
    },
    isSubTask() {
      return !!this.railroadItem.Sub;
    },
    isAvailable() {
      return this.railroadItem.Available;
    },
  },
  methods: {
    async processEvent(action, eventString, editAction) {
      if (
        !this.railroadItem.Editable ||
        (this.rWindow.data.dirty && !(await confirmDiscardUnsavedChanges()))
      )
        return;

      if (editAction) {
        if (!this.railroadItem.Editable) return;
        await this.action(this.railroadItem.EditAction);
        return;
      }
      if (!this.railroadItem.Enabled) return;
      if (action) {
        import(`@/actions/${action.Function}.js`).then((module) => {
          module.default(action.Data);
        });
        return;
      }

      if (this.railroadItem.JSFunctionPayload) {
        await handleJsFunctionPayload({
          jsFunctionPayload: this.railroadItem.JSFunctionPayload,
        });
        return;
      }

      await processLegacyEvent(eventString, this.$store.getters.subWindow.id);
    },
    async openAddTaskDialog({parentTaskId}) {
      if (!this.railroadItem.Editable) return;

      const criteria = {
        ParentTaskID: parentTaskId,
      };

      if (!this.isLoadingDialog) {
        this.isLoadingDialog = true;

        await openTaskCreateDialog({
          criteria,
        });

        this.isLoadingDialog = false;
      }
    },
    action,
  },
};
</script>

<style scoped></style>
