import {fetchOptions} from "./fetchOptions.js";

export const getOptions = async ({
  cell,
  field,
  row,
  searchValue,
  maxReturnableOptions,
}) => {
  if (field.Dropdown?.TableName) {
    return await fetchOptions({field, row, searchValue, maxReturnableOptions});
  }

  if (cell?.Dropdown?.Items?.length > 0) return cell.Dropdown.Items;

  if (field.Dropdown?.Items) return await field.Dropdown.Items;

  return field.Options ?? [];
};
