import {mutateBusinessMomentsCapacity} from "../../../services/dialog/mutateBusinessMomentsCapacity";
import {getTranslations} from "../../session/localstorage/getTranslations";
import {alertPopup} from "../../../interface/alertPopup/alertPopup";
import {notify} from "../../../util/notify";

export const bulkCapacityMutation = async ({fields, businessMoments}) => {
  const translations = getTranslations();
  let {MutationCapacity, MutationReason, MutationType} = fields;

  MutationCapacity =
    MutationType === "DecreaseCapacity" ? -MutationCapacity : MutationCapacity;

  const capacityMutationData = businessMoments.map((businessMoment) => {
    return {
      BusinessMomentID: businessMoment.BusinessMomentID,
      Reason: MutationReason,
      Amount: MutationCapacity,
    };
  });

  try {
    const result = await mutateBusinessMomentsCapacity(capacityMutationData);

    if (result?.data?.length > 0) {
      notify({
        message: translations.AdminDesktop_SuccesSave,
        type: "success",
      });
    } else throw new Error();
  } catch (error) {
    if (error?.response?.data?.Error?.Message) {
      alertPopup({
        text: error?.response?.data?.Error?.Message,
        icon: "warning",
      });
    } else {
      notify({
        message:
          error?.response?.data?.Error?.Message ?? translations.SaveFailed,
        type: "error",
      });
    }
  }
};
