import {clearInvalidDialogLinkedField} from "./clearInvalidDialogLinkedField.js";
import {getOptions} from "../../fields/select-field/getOptions.js";
import {createRowFromFieldsArray} from "../../fields/createRowFromFieldsArray.js";

export const processDialogLinkedFormFieldFromCriteria = async ({
  fields,
  linkedField,
}) => {
  if (linkedField.Type === "Date" || linkedField.Editor === "date")
    return clearInvalidDialogLinkedField({fields, linkedField});

  const row = createRowFromFieldsArray({fields});
  const newOptions = await getOptions({
    maxReturnableOptions: null,
    field: linkedField,
    row,
  });

  const newCriterionFieldOptionsIncludesSelectedValue = newOptions.some(
    (option) => option.Value === (linkedField.NewValue ?? linkedField.Value),
  );

  if (newCriterionFieldOptionsIncludesSelectedValue)
    fields[fields.indexOf(linkedField)].Dropdown.Items = newOptions;

  if (!newCriterionFieldOptionsIncludesSelectedValue)
    fields = clearInvalidDialogLinkedField({fields, linkedField});

  return fields;
};
