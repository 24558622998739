<template>
  <div class="week-planning-container">
    <!-- Week Navigation -->
    <div
      class="flex items-center justify-between p-3 mb-4 bg-gray-100 rounded shadow-sm"
    >
      <r-button
        variant="secondary"
        size="md"
        :disabled="isLoading"
        class="flex items-center justify-center w-10 h-10"
        @click="previousWeek"
      >
        <i class="fas fa-chevron-left"></i>
      </r-button>

      <div class="flex flex-col items-center">
        <span class="text-lg font-bold text-gray-800">
          {{ translations["Week"] }} {{ currentWeek.week() }} ({{
            formatWeekRange()
          }})
        </span>
        <r-button
          variant="primary"
          size="sm"
          :disabled="isCurrentWeek"
          class="mt-2"
          @click="goToCurrentWeek"
        >
          {{ translations["CurrentWeek"] }}
        </r-button>
      </div>

      <r-button
        variant="secondary"
        size="md"
        :disabled="isLoading"
        class="flex items-center justify-center w-10 h-10"
        @click="nextWeek"
      >
        <i class="fas fa-chevron-right"></i>
      </r-button>
    </div>

    <!-- Loading State -->
    <div v-if="!loaded" class="flex items-center justify-center h-64">
      <div class="flex flex-col items-center">
        <div
          class="w-12 h-12 border-4 border-gray-200 border-t-primary rounded-full animate-spin mb-3"
        ></div>
        <div class="text-gray-600 font-medium">
          {{ translations["LoadingPlanningData"] }}
        </div>
      </div>
    </div>

    <!-- Grid View -->
    <r-grid-view
      v-if="loaded"
      ref="gridView"
      :columns="weekDayColumns"
      :rows="sortedGridData"
      :r-window="rWindow"
      :recently-updated="recentlyUpdated"
      :modified-tiles="modifiedTiles"
      class="rounded-md shadow-md overflow-hidden"
      @tile-click="handleTileClick"
      @selection-change="handleSelectionChange"
    >
      <!-- Custom template for resource names -->
      <template #resource-info="{row}">
        <div class="ml-2 flex flex-col py-2 cursor-pointer group">
          <div class="flex items-center">
            <span
              class="font-medium text-gray-800 group-hover:text-primary transition-colors duration-150"
              >{{ row.title }}</span
            >
          </div>
        </div>
      </template>

      <!-- Custom grid tile template that shows hourCapacity and delete option -->
      <template #grid-tile="props">
        <div class="h-full w-full relative">
          <!-- Use the original GridViewTile for compatibility with background color override -->
          <GridViewTile
            :time-slot="props.timeSlot"
            :is-selected="props.isSelected"
            :is-recently-updated="props.isRecentlyUpdated"
            :selection-mode="props.selectionMode"
            :is-modified="props.isModified"
            :override-classes-function="overrideClasses"
            @click="() => props.handleClick()"
            @toggle-selection="() => props.toggleSelection()"
          />

          <!-- Delete icon - only show if has planningID and not system driven -->
          <div
            v-if="
              props.timeSlot.resource?.IsSystemDriven === false ||
              props.timeSlot.planning?.IsSystemDriven === false
            "
            class="absolute bottom-2 left-2 z-10"
          >
            <div class="w-6 h-6 flex items-center justify-center">
              <i class="fas fa-calendar-edit text-primary text-xs"></i>
            </div>
          </div>

          <!-- Overlay hourCapacity information - only show if configured -->
          <div
            v-if="props.timeSlot.resource?.HourCapacity"
            class="absolute bottom-2 right-2 px-1 py-0.5 rounded bg-opacity-70 text-center text-xs font-medium"
            :class="{
              'bg-gray-100 text-gray-700': !props.isSelected,
              'bg-primary bg-opacity-90 text-white': props.isSelected,
              'bg-red-200': props.timeSlot.resource?.HourCapacity < 0,
              'opacity-75':
                !props.timeSlot.resource && !props.timeSlot.planning,
            }"
          >
            {{ props.timeSlot.resource?.HourCapacity }}
            {{ translations["CapacityPerHour"] }}
          </div>
        </div>
      </template>
    </r-grid-view>

    <!-- Time Edit Modal -->
    <RGridViewPlanningWrapperTimeEditModal
      :show="showTimeEditModal"
      :is-bulk-edit="isBulkEdit"
      :initial-values="timeEditInitialValues"
      @hide="closeTimeEditModal"
      @save="handleSaveTimeChanges"
    />

    <RGridViewPlanningWrapperAddResourceModal
      :show="showAddResourceDialog"
      :week-days="weekDayColumns"
      @hide="showAddResourceDialog = false"
      @add-resource="handleAddResource"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import RGridView from "../RGridView.vue";
import GridViewTile from "../GridViewTile.vue";
import {fetchPlanning} from "../../../../services/v2/warehouse/planning/fetchPlanning.js";
import {createPlanning} from "../../../../services/v2/warehouse/planning/createPlanning.js";
import {updatePlanning} from "../../../../services/v2/warehouse/planning/updatePlanning.js";
import {fetchPlanningResource} from "../../../../services/v2/warehouse/planning/fetchPlanningResource.js";
import {updatePlanningResource} from "../../../../services/v2/warehouse/planning/updatePlanningResource.js";
import {deletePlanning} from "../../../../services/v2/warehouse/planning/deletePlannings.js";
import RGridViewPlanningWrapperTimeEditModal from "./RGridViewPlanningWrapperTimeEditModal.vue";
import {getTranslations} from "../../../../functions/session/getTranslations.js";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse.js";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution.js";
import {deletePlanningResources} from "../../../../services/v2/warehouse/planning/deletePlanningResources.js";
import RButton from "../../../elements/RButton.vue";
import {createPlanningResource} from "../../../../services/v2/warehouse/planning/createPlanningResource.js";
import RGridViewPlanningWrapperAddResourceModal from "./RGridViewPlanningWrapperAddResourceModal.vue";
import {v4 as uuidv4} from "uuid";
import {notify} from "@/util/notify.js";
import {prompt} from "../../../../functions/prompt.js";

// Extend dayjs with plugins
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const translations = getTranslations();

export default {
  name: "RGridViewPlanningWrapper",

  components: {
    RGridViewPlanningWrapperAddResourceModal,
    RButton,
    RGridView,
    GridViewTile,
    RGridViewPlanningWrapperTimeEditModal,
  },

  props: {
    rWindow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      translations,
      currentWeek: dayjs(),
      planningData: {}, // Data indexed by ResourceID
      planningResources: [], // Store all planning resources
      planningEntries: [], // Store all planning entries
      gridData: [], // Formatted data for the grid
      isLoading: false,
      warehouseID: null,
      loaded: false,
      showTimeEditModal: false,
      showEditResourceModal: false,
      isBulkEdit: false,
      selectedResource: null,
      selectedTimeSlot: null,
      selectedColumn: null,
      selectedSlots: [],
      showAddResourceDialog: false,
      timeEditInitialValues: {
        startTime: null,
        endTime: null,
        hourCapacity: 0,
        day: null,
      },
      recentlyUpdated: [],
      weekDayColumns: [
        {name: "Monday", title: translations.DescriptionMonday, subTitle: null},
        {
          name: "Tuesday",
          title: translations.DescriptionTuesday,
          subTitle: null,
        },
        {
          name: "Wednesday",
          title: translations.DescriptionWednesday,
          subTitle: null,
        },
        {
          name: "Thursday",
          title: translations.DescriptionThursday,
          subTitle: null,
        },
        {name: "Friday", title: translations.DescriptionFriday, subTitle: null},
        {
          name: "Saturday",
          title: translations.DescriptionSaturday,
          subTitle: null,
        },
        {name: "Sunday", title: translations.DescriptionSunday, subTitle: null},
      ],
      currentJob: null,
      originalValues: {}, // For tracking changes
      modifiedTiles: [],
      planningIDsMarkedForDeletion: [], // Track planning IDs marked for deletion
      planningResourceIDsToDelete: [], // Track planning resource IDs to delete
      planningResourceMap: {}, // Maps PlanningResourceID to resource details

      // New data structures for tracking resource deletion
      resourceUsage: {}, // Maps PlanningResourceID to count of planning entries using it
      planningToResourceMap: {}, // Maps PlanningID to PlanningResourceID
    };
  },

  /* Rest of the script remains unchanged */

  computed: {
    sortedGridData() {
      return this.gridData.slice().sort((a, b) => {
        // Case 1: Both have defaultPlanningResourceID - sort alphabetically by title
        if (a.defaultPlanningResourceID && b.defaultPlanningResourceID) {
          return a.title.localeCompare(b.title);
        }

        // Case 2: Only a has defaultPlanningResourceID - a comes first
        if (a.defaultPlanningResourceID && !b.defaultPlanningResourceID) {
          return -1;
        }

        // Case 3: Only b has defaultPlanningResourceID - b comes first
        if (!a.defaultPlanningResourceID && b.defaultPlanningResourceID) {
          return 1;
        }

        // Case 4: Neither has defaultPlanningResourceID - sort alphabetically by title
        return a.title.localeCompare(b.title);
      });
    },
    weekStartDate() {
      return this.currentWeek.startOf("week");
    },

    weekEndDate() {
      return this.currentWeek.endOf("week");
    },

    isCurrentWeek() {
      const now = dayjs();
      return (
        this.currentWeek.week() === now.week() &&
        this.currentWeek.year() === now.year()
      );
    },

    actionPortalName() {
      return this.rWindow ? `action-bar-${this.rWindow.id}` : "";
    },
  },

  watch: {
    "rWindow.jobs": {
      handler(newJobs) {
        if (this.currentJob) {
          return;
        }

        if (newJobs && Object.keys(newJobs).length > 0) {
          const firstJob = Object.values(newJobs)[0];
          this.currentJob = firstJob;
          this.handleJobExecution(firstJob);
        }
      },
      deep: true,
    },
  },

  async created() {
    this.loaded = false;
    this.$store.state.loading = true;
    this.warehouseID = getActiveWarehouse();
    await this.fetchWeekPlanning();
    this.$store.state.loading = false;
    this.loaded = true;
  },

  methods: {
    /**
     * Format the date range for the current week
     */
    formatWeekRange() {
      return `${this.weekStartDate.format("MMM D")} - ${this.weekEndDate.format("MMM D, YYYY")}`;
    },
    overrideClasses({isSelected, isRecentlyUpdated, isModified, timeSlot}) {
      if (timeSlot.resource?.HourCapacity < 0) {
        return {"bg-red-50": true};
      }

      if (isSelected) {
        return {"bg-orange-100 border-2 border-blue-400": true};
      }

      if (!timeSlot.resource?.HourCapacity && !timeSlot.planning?.StartTime) {
        return {
          "bg-gray-50 opacity-50 hover:opacity-100 hover:bg-orange-100": true,
        };
      }

      if (isRecentlyUpdated) {
        return {"bg-orange-200 animate-flash font-bold": true};
      }

      return {"bg-gray-50 hover:bg-gray-100": true};
    },
    /**
     * Check if a time slot can be deleted (has planningID and not system driven)
     */
    canDelete(timeSlot) {
      if (!timeSlot.planningID && !timeSlot?.value?.planningResourceID)
        return false;
      return timeSlot?.isSystemDriven === false;
    },

    /**
     * Toggle whether multiple time slots are marked for deletion
     */
    async deleteTilesResources(tiles) {
      this.$store.state.loading = true;
      const planningIdsToDelete = [];
      const resourceIdsToDelete = [];

      // Collect all IDs that need to be deleted
      for (const tile of tiles) {
        if (tile.planning?.PlanningID) {
          planningIdsToDelete.push(tile.planning.PlanningID);
        }
        if (tile.resource?.PlanningResourceID) {
          resourceIdsToDelete.push(tile.resource.PlanningResourceID);
        }
      }

      // Delete plannings if needed
      if (planningIdsToDelete.length > 0) {
        await deletePlanning(planningIdsToDelete);
      }

      // Delete resources if needed
      if (resourceIdsToDelete.length > 0) {
        await deletePlanningResources(resourceIdsToDelete);
      }

      // TODO remove, backend gives feedback entity is removed, but fetching data results in old data
      await new Promise((resolve) => setTimeout(resolve, 200));

      await this.fetchWeekPlanning();
      this.$store.state.loading = false;
      notify({
        type: "success",
        message: translations["PlanningDeletedSuccessfully"],
      });
    },

    /**
     * Updates a specific tile in the grid data by its clientSideUUID
     * @param {Object} params - The parameters object
     * @param {string} params.clientSideUUID - The unique identifier of the tile to update
     * @param {Object} params.tile - The new tile data to merge with the existing tile
     * @returns {Object|null} - The updated tile or null if not found
     */
    updateTileInGridData({clientSideUUID, tile}) {
      if (!clientSideUUID || !tile) {
        console.error(
          "Missing required parameters: clientSideUUID or tile data",
        );
        return null;
      }

      let updatedTile = null;

      // Create a new grid data array with the updated tile
      const newGridData = this.gridData.map((row) => {
        // Check if this row contains our target tile
        const tileIndex = row.values.findIndex(
          (value) => value.clientSideUUID === clientSideUUID,
        );

        // If the tile is not in this row, return the row unchanged
        if (tileIndex === -1) return row;

        // Create a new values array with the updated tile
        const newValues = [...row.values];

        // Get the existing tile
        const existingTile = newValues[tileIndex];

        // Deep merge the existing tile with the new tile data
        updatedTile = {
          ...existingTile,
          ...tile,
          // Handle nested objects with deep merge
          value: {
            ...existingTile.value,
            ...(tile.value || {}),
          },
          resource: tile.resource
            ? {
                ...existingTile.resource,
                ...tile.resource,
              }
            : existingTile.resource,
          planning: tile.planning
            ? {
                ...existingTile.planning,
                ...tile.planning,
              }
            : existingTile.planning,
        };

        // Replace the old tile with the updated one
        newValues[tileIndex] = updatedTile;

        // Return the updated row
        return {
          ...row,
          values: newValues,
        };
      });

      // Update the grid data
      this.gridData = newGridData;

      // Force update to refresh the UI
      if (this.$forceUpdate) {
        this.$forceUpdate();
      }

      return updatedTile;
    },
    /**
     * Add a resource job handler
     */
    addResource(job) {
      this.showAddResourceDialog = true;
      this.$emit("job-completed", job);
      return false;
    },

    /**
     * Handle adding a new resource with custom description
     * Here we can use arrow functions inside the method for callbacks
     */
    async handleAddResource(resource) {
      try {
        this.$store.state.loading = true;
        this.isLoading = true;

        // Get the selected days
        const selectedDays = resource.Days || ["Monday"];

        // Create an array to hold the created resources by day
        const createdResourcesByDay = new Map();

        // if we already have an entry with the same description say this is not allowed
        const existingResource = this.planningResources.find(
          (r) => r.Description === resource.Description,
        );

        if (existingResource) {
          notify({
            type: "error",
            message: translations["ResourceWithDescriptionExists"],
          });
          return;
        }

        // Create a resource for each selected day
        for (const dayName of selectedDays) {
          // Calculate the correct date for this day
          const dayIndex = this.getDayOfWeekIndex(dayName);
          const planningDate = this.getDateForDayOfWeek(dayIndex);
          const dateString = planningDate.format("YYYY-MM-DD");

          // Use 00:00:00 for start time and 23:59:59 for end time
          const resourceStartDate = `${dateString}T00:00:00`;
          const resourceEndDate = `${dateString}T23:59:59`;

          // Format for API
          const newResource = [
            {
              DefaultPlanningResourceID: undefined,
              ResourceID: resource.ResourceID,
              StartDate: resourceStartDate,
              EndDate: resourceEndDate,
              HourCapacity: resource.HourCapacity,
              Description: resource.Description,
            },
          ];

          // Create the resource
          try {
            const response = await createPlanningResource(newResource);

            if (response.data && response.data.length > 0) {
              const createdResource = response.data[0];
              // Store the created resource by day
              createdResourcesByDay.set(dayName, createdResource);
            }

            //create planning if we have a start Time and endTime for every createdResource

            if (resource.StartTime && resource.EndTime) {
              // Create a new planning entry for this resource
              const newPlannings = [];
              for (const createdResource of response.data) {
                newPlannings.push({
                  PlanningResourceID: createdResource.PlanningResourceID,
                  StartDate: `${dateString}T${resource.StartTime}:00`,
                  EndDate: `${dateString}T${resource.EndTime}:00`,
                  HourCapacity: createdResource.HourCapacity,
                  IsSystemDriven: true,
                  WarehouseID: this.warehouseID,
                });
              }

              try {
                await createPlanning(newPlannings);
              } catch (error) {
                console.error(`Error creating planning for ${dayName}:`, error);
              }
            }
          } catch (error) {
            console.error(`Error creating resource for ${dayName}:`, error);
          }
        }

        // Refresh data to show the new resources

        // wait 50 ms
        await new Promise((resolve) => setTimeout(resolve, 50));
        await this.fetchWeekPlanning();
        // clear selection

        // Find the rows with newly created resources for visual feedback
        if (createdResourcesByDay.size > 0) {
          const updates = [];

          this.gridData.forEach((row) => {
            // Skip rows that don't match our created resource description
            if (row.title !== resource.Description) return;

            row.values.forEach((valueItem) => {
              const dayName = valueItem.column;

              // Check if we created a resource for this day
              const createdResource = createdResourcesByDay.get(dayName);
              if (createdResource) {
                // This is one of our created resources, update the UI
                updates.push({
                  row,
                  column: dayName,
                  newValue: {
                    hourCapacity: resource.HourCapacity,
                    planningResourceID: createdResource.PlanningResourceID,
                  },
                });

                // Also update the value in the grid directly for immediate feedback
                if (valueItem.value) {
                  valueItem.value.hourCapacity = resource.HourCapacity;
                  valueItem.value.planningResourceID =
                    createdResource.PlanningResourceID;
                } else {
                  valueItem.value = {
                    startTime: null,
                    endTime: null,
                    hourCapacity: resource.HourCapacity,
                    planningResourceID: createdResource.PlanningResourceID,
                  };
                }
              }
            });
          });

          // Mark the created days as updated for visual feedback
          if (updates.length > 0) {
            this.markAsUpdated(updates);
          }
        }
        notify({
          type: "success",
          message: translations["ResourceAddedSuccessfully"],
        });
        this.showAddResourceDialog = false;
      } catch (error) {
        console.error("Failed to create resources:", error);
      } finally {
        this.isLoading = false;
        this.$store.state.loading = false;
      }
    },
    /**
     * Build mapping of PlanningResourceID to full resource details
     */
    buildPlanningResourceMap(resources) {
      const map = {};
      resources.forEach((resource) => {
        map[resource.PlanningResourceID] = resource;
      });
      return map;
    },

    /**
     * Track resource usage by planning entries
     */
    trackResourceUsage(planningEntries) {
      const usage = {};
      const planningMap = {};

      planningEntries.forEach((planning) => {
        const planningResourceID = planning.PlanningResourceID;
        const planningID = planning.PlanningID;

        // Increment usage count for this planning resource
        usage[planningResourceID] = (usage[planningResourceID] || 0) + 1;

        // Map planning ID to planning resource ID
        planningMap[planningID] = planningResourceID;
      });

      return {usage, planningMap};
    },

    /**
     * Fetch planning data for the current week
     */
    async fetchWeekPlanning() {
      this.$store.state.loading = true;
      this.planningIDsMarkedForDeletion = []; // Reset marked deletions
      this.planningResourceIDsToDelete = []; // Reset resource deletions

      try {
        // Format dates for API
        const startDate = this.weekStartDate.format("YYYY-MM-DD");
        const endDate = `${this.weekEndDate.format("YYYY-MM-DD")}T23:59:59`;

        // set columns sub title to date in DD-MM-YYYY format
        this.weekDayColumns.forEach((column) => {
          column.subTitle = dayjs(
            this.getDateForDayOfWeek(this.getDayOfWeekIndex(column.name)),
          ).format("DD-MM-YYYY");
        });

        // Fetch planning resources and entries in parallel
        const [resourcesResponse, planningResponse] = await Promise.all([
          fetchPlanningResource({
            params: {
              startDate,
              endDate,
              size: 100,
            },
          }),
          fetchPlanning({
            params: {
              startDate,
              endDate,
              size: 1000,
            },
          }),
        ]);

        // Store the raw data
        this.planningResources = resourcesResponse.data?.Collection || [];
        this.planningEntries = planningResponse.data?.Collection || [];

        // Create mapping of PlanningResourceID to resource details
        this.planningResourceMap = this.buildPlanningResourceMap(
          this.planningResources,
        );

        // Track resource usage for deletion purposes
        const {usage, planningMap} = this.trackResourceUsage(
          this.planningEntries,
        );
        this.resourceUsage = usage;
        this.planningToResourceMap = planningMap;

        // Build centralized data structure indexed by ResourceID (not PlanningResourceID)
        this.planningData = this.buildDataStructure();

        // Transform to grid format
        this.gridData = this.transformToGridFormat();
        // clear selection
        this.selectedSlots = [];

        // Store original values for change detection
        this.storeOriginalValues();
      } catch (error) {
        console.error("Error fetching planning data:", error);
      } finally {
        this.isLoading = false;
        this.$store.state.loading = false;
      }
    },

    /**
     * Build a data structure that indexes by ResourceID
     * This ensures one row per resource, regardless of planning entries
     */
    buildDataStructure() {
      // Map to store grouped data
      const data = {};
      // Map to track day conflicts for same-description resources
      const dayConflictMap = {};

      // STEP 1: First process all planning resources
      this.planningResources.forEach((resource) => {
        // Primary key is ResourceID if available, otherwise use Description
        const resourceId = resource.ResourceID || resource.PlanningResourceID;
        const description = resource.Description;
        const planningResourceId = resource.PlanningResourceID;

        // Determine the group key - use ResourceID if non-null, otherwise use Description
        const groupKey = resource.ResourceID
          ? `id-${resourceId}`
          : `desc-${description}`;

        // Get the day of the week for this planning resource
        let dayName = null;
        if (resource.StartDate) {
          try {
            const date = dayjs(resource.StartDate);
            dayName = this.getDayNameFromDate(date);
          } catch (error) {
            console.warn("Error parsing date:", resource.StartDate, error);
          }
        }

        // Initialize group if not already present
        if (!data[groupKey]) {
          data[groupKey] = {
            resource: {
              ResourceID: resource.ResourceID,
              PlanningResourceID: resource.PlanningResourceID,
              Description: description,
              HourCapacity: resource.HourCapacity ?? null,
              IsSystemDriven: resource.IsSystemDriven,
            },
            planningResourceIds: [],
            planningByDay: {
              Monday: null,
              Tuesday: null,
              Wednesday: null,
              Thursday: null,
              Friday: null,
              Saturday: null,
              Sunday: null,
            },
            hourCapacityByDay: {},
            conflictDayEntries: {}, // Store conflicting entries by day
          };
        }

        // Add this PlanningResourceID to the group if not already there
        if (!data[groupKey].planningResourceIds.includes(planningResourceId)) {
          data[groupKey].planningResourceIds.push(planningResourceId);
        }

        // Process day-specific info if we have a valid day
        if (dayName) {
          // Track resource usage by day to detect conflicts later
          const dayKey = `${groupKey}-${dayName}`;
          if (!dayConflictMap[dayKey]) {
            dayConflictMap[dayKey] = [];
          }
          dayConflictMap[dayKey].push(resource);

          // Store hour capacity for this day
          data[groupKey].hourCapacityByDay[dayName] =
            resource.HourCapacity ?? null;

          data[groupKey].days = {
            ...(data[groupKey].days ?? {}),
            [dayName]: {
              resource,
              planning: this.planningEntries.find(
                (entry) =>
                  entry.PlanningResourceID === resource.PlanningResourceID,
              ),
            },
          };

          // Create a placeholder entry for this day if none exists
          if (!data[groupKey].planningByDay[dayName]) {
            data[groupKey].planningByDay[dayName] = {
              planningID: null,
              planningResourceID: planningResourceId,
              startTime: null,
              endTime: null,
              hourCapacity: resource.HourCapacity || null,
              isSystemDriven: resource.IsSystemDriven || false,
              day: resource.StartDate,
              isResourceOnly: true,
            };
          }
        }
      });

      // STEP 2: Process planning entries
      this.planningEntries.forEach((planning) => {
        const planningResourceId = planning.PlanningResourceID;
        const resource = this.planningResourceMap[planningResourceId];

        if (!resource) {
          console.warn(
            `No resource found for PlanningResourceID: ${planningResourceId}`,
          );
          return;
        }

        // Determine the group key - use ResourceID if non-null, otherwise use Description
        const resourceId = resource.ResourceID || resource.PlanningResourceID;
        const description = resource.Description;
        const groupKey = resource.ResourceID
          ? `id-${resourceId}`
          : `desc-${description}`;

        if (!data[groupKey]) {
          console.warn(`No data entry for group: ${groupKey}`);
          return;
        }

        // Get the day of the week for this planning entry
        let dayName;
        try {
          dayName = this.getDayNameFromDate(dayjs(planning.Day));
        } catch (error) {
          console.warn("Error parsing day:", planning.Day, error);
          return;
        }

        // Skip if no valid day name
        if (!dayName) return;

        // Add planning entry to the appropriate day
        const existingEntry = data[groupKey].planningByDay[dayName];

        // Use the new entry if there's no existing entry, or if the existing is resource-only,
        // or if the new one has values and old doesn't
        if (
          !existingEntry ||
          existingEntry.isResourceOnly ||
          (!existingEntry.startTime && planning.StartDate) ||
          (!existingEntry.endTime && planning.EndDate)
        ) {
          data[groupKey].planningByDay[dayName] = {
            planningID: planning.PlanningID,
            planningResourceID: planningResourceId,
            startTime: planning.StartDate
              ? dayjs(planning.StartDate).format("HH:mm")
              : null,
            endTime: planning.EndDate
              ? dayjs(planning.EndDate).format("HH:mm")
              : null,
            hourCapacity: planning.HourCapacity || resource.HourCapacity || 0,
            isSystemDriven:
              planning.IsSystemDriven !== false &&
              resource.IsSystemDriven !== false,
            day: planning.Day,
            isResourceOnly: false,
          };
        }
      });

      // STEP 3: Handle day conflicts
      // Check each day for each group to see if there are multiple resources for the same day
      for (const dayKey in dayConflictMap) {
        const resources = dayConflictMap[dayKey];

        // If there's more than one resource for this day/description combination
        if (resources.length > 1) {
          // Extract group key and day name
          const [groupKey, dayName] = dayKey.split("-");

          // Get existing entry in main data structure
          const groupData = data[groupKey];
          if (!groupData) continue;

          // Move all but the first resource to separate entries for this day
          for (let i = 1; i < resources.length; i++) {
            const conflictResource = resources[i];

            // Generate a conflict key that includes the resource ID to make it unique
            const conflictKey = `${groupKey}-conflict-${conflictResource.PlanningResourceID}`;

            // Check if we already have an entry for this conflict
            if (!data[conflictKey]) {
              // Create a new group entry for this conflict
              data[conflictKey] = {
                resource: {
                  ResourceID: conflictResource.ResourceID,
                  PlanningResourceID: conflictResource.PlanningResourceID,
                  Description: conflictResource.Description,
                  HourCapacity: conflictResource.HourCapacity ?? null,
                  IsSystemDriven: conflictResource.IsSystemDriven,
                  isConflictEntry: true, // Mark as a conflict entry
                },
                planningResourceIds: [conflictResource.PlanningResourceID],
                planningByDay: {
                  Monday: null,
                  Tuesday: null,
                  Wednesday: null,
                  Thursday: null,
                  Friday: null,
                  Saturday: null,
                  Sunday: null,
                },
                hourCapacityByDay: {},
              };
            }

            // Set the planning data for just this specific day
            data[conflictKey].planningByDay[dayName] = {
              planningID: null, // We'll handle planningID later if it exists
              planningResourceID: conflictResource.PlanningResourceID,
              startTime: null,
              endTime: null,
              hourCapacity: conflictResource.HourCapacity ?? null,
              isSystemDriven: conflictResource.IsSystemDriven || false,
              day: conflictResource.StartDate,
              isResourceOnly: true,
            };

            // Find any corresponding planning entry
            const planningEntry = this.planningEntries.find(
              (entry) =>
                entry.PlanningResourceID ===
                  conflictResource.PlanningResourceID &&
                this.getDayNameFromDate(dayjs(entry.Day)) === dayName,
            );

            if (planningEntry) {
              data[conflictKey].planningByDay[dayName] = {
                planningID: planningEntry.PlanningID,
                planningResourceID: planningEntry.PlanningResourceID,
                startTime: planningEntry.StartDate
                  ? dayjs(planningEntry.StartDate).format("HH:mm")
                  : null,
                endTime: planningEntry.EndDate
                  ? dayjs(planningEntry.EndDate).format("HH:mm")
                  : null,
                hourCapacity:
                  planningEntry.HourCapacity ||
                  conflictResource.HourCapacity ||
                  0,
                isSystemDriven:
                  planningEntry.IsSystemDriven !== false &&
                  conflictResource.IsSystemDriven !== false,
                day: planningEntry.Day,
                isResourceOnly: false,
              };
            }

            // Update hourCapacityByDay
            data[conflictKey].hourCapacityByDay[dayName] =
              conflictResource.HourCapacity ?? null;
          }
        }
      }

      return data;
    },

    /**
     * Transform the data structure into the format expected by the grid
     */
    transformToGridFormat() {
      // Map data to grid format first
      const gridRows = Object.entries(this.planningData).map(([key, data]) => {
        let defaultPlanningID = null;
        let defaultPlanningResourceID = null;
        // Create a value array with entries for each day of the week
        const values = this.weekDayColumns.map((column) => {
          const dayName = column.name;
          const {resource, planning} = data.days[dayName] ?? {};

          if (planning) {
            defaultPlanningID = planning?.DefaultPlanningID;
            defaultPlanningResourceID = resource?.DefaultPlanningResourceID;
          }

          // Get planning resource ID specifically for this day if available
          const planningResourceID =
            planning?.PlanningResourceID ??
            resource?.planningResourceID ??
            null;
          const isSystemDriven =
            planning?.isSystemDriven === false &&
            resource?.isSystemDriven === false;

          const startTime = planning?.StartDate
            ? dayjs(planning.StartDate).format("HH:mm")
            : null;
          const endTime = planning?.EndDate
            ? dayjs(planning.EndDate).format("HH:mm")
            : null;

          return {
            clientSideUUID: uuidv4(),
            planning,
            resource,
            column: dayName,
            planningID: planning?.PlanningID ?? null,
            value: {
              startTime,
              endTime,
              hourCapacity: resource?.HourCapacity,
              planningResourceID,
              isSystemDriven,
              resourceID: resource?.ResourceID ?? null,
            },
            isSystemDriven,
          };
        });

        // If this is a conflict entry, set isConflictEntry flag
        const isConflictEntry = false;

        const title = data.resource.Description;
        const resourceId =
          Object.values(data.days).find(
            (day) => day.resource?.ResourceID !== null,
          )?.resource?.ResourceID || null;

        return {
          title,
          resourceId,
          defaultPlanningResourceID,
          defaultPlanningID,
          planningResourceID: null, // Used only as a fallback
          warehouseID: this.warehouseID,
          isSystemDriven: false,
          isConflictEntry: isConflictEntry, // Add conflict flag to identify special rows
          values,
        };
      });

      // Sort the rows to ensure rows with null ResourceID are at the bottom
      return gridRows.sort((a, b) => {
        // Primary sort: non-null ResourceID rows come first
        if (a.resourceId !== null && b.resourceId === null) return -1; // a has ResourceID, b doesn't -> a comes first
        if (a.resourceId === null && b.resourceId !== null) return 1; // a doesn't have ResourceID, b does -> b comes first

        // Secondary sort: conflict entries come after regular entries of the same type
        if (!a.isConflictEntry && b.isConflictEntry) return -1;
        if (a.isConflictEntry && !b.isConflictEntry) return 1;

        // Tertiary sort: alphabetical by description/title
        return a.title.localeCompare(b.title);
      });
    },

    /**
     * Get day name (Monday-Sunday) from a date
     */
    getDayNameFromDate(date) {
      if (!date || !date.isValid || !date.isValid()) {
        console.warn("Invalid date passed to getDayNameFromDate:", date);
        return null;
      }

      try {
        const dayIndex = date.day();
        // Convert from JS day (0=Sunday) to our format (0=Monday)
        const adjustedIndex = (dayIndex + 6) % 7;

        if (adjustedIndex < 0 || adjustedIndex >= this.weekDayColumns.length) {
          console.warn("Invalid dayIndex:", adjustedIndex);
          return null;
        }

        return this.weekDayColumns[adjustedIndex].name;
      } catch (error) {
        console.error("Error in getDayNameFromDate:", error);
        return null;
      }
    },

    /**
     * Store original values for change detection
     */
    storeOriginalValues() {
      const originals = {};

      this.gridData.forEach((row) => {
        row.values.forEach((valueItem) => {
          const cellKey = `${row.resourceId}-${valueItem.column}`;

          originals[cellKey] = {
            startTime: valueItem.value?.startTime || null,
            endTime: valueItem.value?.endTime || null,
            hourCapacity: valueItem.value?.hourCapacity || 0,
            planningID: valueItem.planningID || null,
            planningResourceID: valueItem.value?.planningResourceID || null,
          };
        });
      });

      this.originalValues = originals;
    },

    /**
     * Convert day name to index (0-6, Monday-Sunday)
     */
    getDayOfWeekIndex(dayName) {
      return this.weekDayColumns.findIndex((col) => col.name === dayName);
    },

    /**
     * Get the date for a specific day in the current week
     */
    getDateForDayOfWeek(dayIndex) {
      return this.weekStartDate.add(dayIndex, "day");
    },

    /**
     * Navigate to the previous week
     */
    async previousWeek() {
      this.$store.state.loading = true;
      this.currentWeek = this.currentWeek.subtract(1, "week");
      await this.fetchWeekPlanning();
      this.$store.state.loading = false;
    },

    /**
     * Navigate to the next week
     */
    async nextWeek() {
      this.$store.state.loading = true;
      this.currentWeek = this.currentWeek.add(1, "week");
      await this.fetchWeekPlanning();
      this.$store.state.loading = false;
    },

    /**
     * Go to the current week
     */
    async goToCurrentWeek() {
      this.$store.state.loading = true;
      this.currentWeek = dayjs();
      await this.fetchWeekPlanning();
      this.$store.state.loading = false;
    },

    /**
     * Handle new job from action bar
     */
    handleNewJob(job) {
      this.currentJob = job;
      this.handleJobExecution(job);
    },

    /**
     * Handle job execution
     */
    async handleJobExecution(job) {
      try {
        this.$store.state.loading = true;
        this.$emit("job-completed", job);
        await handleJobExecution({job, vueInstance: this});
      } catch (error) {
        console.error("Error executing job:", error);
        notify({
          type: "error",
          message: translations["FailedToExecuteJob"],
        });
        this.$emit("job-error", job, error);
      } finally {
        this.currentJob = null;
        this.$store.state.loading = false;
      }
    },

    /**
     * Change time ranges job handler
     */
    changeTimeRanges(job) {
      // If there are selected slots already, open bulk edit
      if (this.selectedSlots && this.selectedSlots.length > 0) {
        this.handleOpenBulkEdit(this.selectedSlots);
      } else {
        // Otherwise just open an empty time modal for a new entry

        notify({
          type: "warning",
          message: translations["NoTilesSelectedSelectOneBeforeEditing"],
        });
      }

      this.$emit("job-completed", job);
      return false;
    },

    /**
     * Clear selection job handler
     */
    clearSelection(job) {
      this.selectedSlots = [];

      // If there's a child component reference, notify it to clear its selection too
      if (this.$refs.gridView) {
        this.$refs.gridView.clearSelection();
      }

      this.$emit("job-completed", job);
      return false;
    },

    /**
     * Enhanced version of parseGridChanges that uses newly created resource IDs at tile level
     */
    parseGridChanges(newResourceMap = new Map()) {
      const planningsToCreate = [];
      const planningsToUpdate = [];
      const resourceCapacityMap = new Map();

      // Process each row (resource) in the grid
      this.gridData.forEach((row, rowIndex) => {
        const resourceId = row.resourceId;

        // Process each day's planning for this resource
        row.values.forEach((valueItem) => {
          const dayName = valueItem.column;
          const tileKey = `${rowIndex}-${dayName}`;

          // Skip if marked for deletion
          if (
            valueItem.planningID &&
            this.planningIDsMarkedForDeletion.includes(valueItem.planningID)
          ) {
            return;
          }

          const dayIndex = this.getDayOfWeekIndex(dayName);
          const planningDate = this.getDateForDayOfWeek(dayIndex);

          // Original values for comparison
          const originalKey = `${resourceId}-${dayName}`;
          const originalValue = this.originalValues[originalKey] || {
            startTime: null,
            endTime: null,
            hourCapacity: 0,
            planningID: null,
          };

          const currentValue = valueItem.value || {
            startTime: null,
            endTime: null,
            hourCapacity: 0,
          };

          // Check for ANY changes - times OR hour capacity
          const timesChanged =
            originalValue.startTime !== currentValue.startTime ||
            originalValue.endTime !== currentValue.endTime;

          const capacityChanged =
            originalValue.hourCapacity !== currentValue.hourCapacity;

          // Get the planning resource ID for this specific tile
          // Priority: 1) Newly created ID 2) Existing ID in the tile 3) Fallback to row
          const planningResourceID =
            newResourceMap.get(tileKey) ||
            (valueItem.value && valueItem.value.planningResourceID) ||
            (capacityChanged && row.planningResourceID); // Only use fallback for capacity changes

          // Process if:
          // 1. We have time changes AND both times are set, OR
          // 2. We have a capacity change AND a valid planning resource ID
          const shouldProcessTimeChange =
            timesChanged &&
            currentValue.startTime &&
            currentValue.endTime &&
            planningResourceID; // Must have a valid planningResourceID
          debugger;
          const shouldProcessCapacityChange =
            capacityChanged && planningResourceID;

          if (shouldProcessTimeChange) {
            // Format the dates for API
            const startDateTime = `${planningDate.format("YYYY-MM-DD")}T${currentValue.startTime}:00`;
            const endDateTime = `${planningDate.format("YYYY-MM-DD")}T${currentValue.endTime}:00`;
            const dayIsoDate = `${planningDate.format("YYYY-MM-DD")}T00:00:00`;

            const planningEntry = {
              PlanningResourceID: planningResourceID,
              StartDate: startDateTime,
              EndDate: endDateTime,
              Day: dayIsoDate,
              HourCapacity: currentValue.hourCapacity || 0,
              WarehouseID: this.warehouseID,
            };

            // Decide whether to create or update
            if (valueItem.planningID) {
              planningsToUpdate.push({
                ...planningEntry,
                PlanningID: valueItem.planningID,
              });
            } else {
              planningsToCreate.push(planningEntry);
            }
          }

          // Always process capacity changes if we have a valid planning resource ID
          if (shouldProcessCapacityChange) {
            // Process capacity changes for resources - unique by planning resource ID
            if (!resourceCapacityMap.has(planningResourceID)) {
              resourceCapacityMap.set(planningResourceID, {
                PlanningResourceID: planningResourceID,
                ResourceID: resourceId || 0,
                HourCapacity: currentValue.hourCapacity || 0,
                Description: row.title || "Manual Planning",
              });
            }
          }
        });
      });

      return {
        planningsToCreate,
        planningsToUpdate,
        resourcesWithCapacityChanges: Array.from(resourceCapacityMap.values()),
      };
    },

    /**
     * Save changes job handler with proper tile-level resource creation
     */
    async saveChanges(job) {
      try {
        this.$store.state.loading = true;
        // Process deletions first
        if (this.planningIDsMarkedForDeletion.length > 0) {
          await this.executeMarkedDeletions();
        }

        // Step 1: Create new planning resources for individual tiles that need them
        const resourceCreationResults =
          await this.createMissingPlanningResources();

        // Step 2: Process grid changes with the newly created resource IDs
        const changes = this.parseGridChanges(
          resourceCreationResults.newResourceMap,
        );

        // Step 3: Create and update planning entries
        if (changes.planningsToCreate.length > 0) {
          await createPlanning(changes.planningsToCreate);
        }

        if (changes.planningsToUpdate.length > 0) {
          await updatePlanning(changes.planningsToUpdate);
        }

        // Step 4: Process resource capacity updates
        if (changes.resourcesWithCapacityChanges.length > 0) {
          await updatePlanningResource(changes.resourcesWithCapacityChanges);
        }

        // Refresh data
        await this.fetchWeekPlanning();

        this.$emit("job-completed", job);
      } catch (error) {
        console.error("Error saving planning changes:", error);
        this.$emit("job-error", job, error);
      } finally {
        this.$store.state.loading = false;
      }

      return false;
    },

    /**
     * Create new planning resources for individual tiles that need them
     */
    async createMissingPlanningResources() {
      this.$store.state.loading = true;
      // Track resources that need to be created
      const resourcesToCreate = [];
      const newResourceMap = new Map(); // Maps "rowIndex-columnName" to new planning resource ID

      // Check each tile for missing planning resources
      this.gridData.forEach((row, rowIndex) => {
        const resourceId = row.resourceId; // This is the actual ResourceID

        // Process each day/column in this row
        row.values.forEach((valueItem) => {
          const dayName = valueItem.column;
          const tileKey = `${rowIndex}-${dayName}`;

          // Skip if this tile already has a planning ID (it's an update, not new)
          if (valueItem.planningID) return;

          // Skip if tile already has a planning resource ID
          if (valueItem.value && valueItem.value.planningResourceID) return;

          // This tile needs a new planning resource - check if content has changed
          const originalKey = `${resourceId}-${dayName}`;
          const originalValue = this.originalValues[originalKey] || {
            startTime: null,
            endTime: null,
            hourCapacity: 0,
          };

          const currentValue = valueItem.value || {
            startTime: null,
            endTime: null,
            hourCapacity: 0,
          };

          // Check for ANY changes - times OR hour capacity
          const hasTimeChange =
            originalValue.startTime !== currentValue.startTime ||
            originalValue.endTime !== currentValue.endTime;

          const hasHourChange =
            originalValue.hourCapacity !== currentValue.hourCapacity;

          // Create a resource if EITHER time or hour capacity has changed
          if (
            (hasTimeChange || hasHourChange) &&
            (currentValue.startTime ||
              currentValue.endTime ||
              currentValue.hourCapacity > 0)
          ) {
            // Calculate the correct date for this day
            const dayIndex = this.getDayOfWeekIndex(dayName);
            const planningDate = this.getDateForDayOfWeek(dayIndex);
            const dateString = planningDate.format("YYYY-MM-DD");

            // Use 00:00:00 for both start and end time of the planning resource
            // The specific start/end times will be set in the planning entry itself
            const resourceStartDate = `${dateString}T00:00:00`;
            const resourceEndDate = `${dateString}T23:59:59`;

            resourcesToCreate.push({
              tileKey,
              resource: {
                // DefaultPlanningResourceID should be undefined for new resources
                DefaultPlanningResourceID: undefined,
                // Use the actual ResourceID from the row
                ResourceID: resourceId,
                StartDate: resourceStartDate,
                EndDate: resourceEndDate,
                HourCapacity: currentValue.hourCapacity || 0,
                Description: row.title || "Manual Planning",
              },
            });
          }
        });
      });

      // Create planning resources if needed
      if (resourcesToCreate.length > 0) {
        try {
          // Extract just the resource data for the API call
          const resourceData = resourcesToCreate.map((item) => item.resource);

          // Make the API call to create resources
          const response = await createPlanningResource(resourceData);

          // Map the created resources back to their tiles
          if (response.data && response.data.length > 0) {
            response.data.forEach((createdResource, index) => {
              const tileKey = resourcesToCreate[index].tileKey;
              const planningResourceID = createdResource.PlanningResourceID;

              // Store the new planning resource ID in our tracking map
              newResourceMap.set(tileKey, planningResourceID);

              // Also update it in the grid data
              const [rowIndex, columnName] = tileKey.split("-");
              const row = this.gridData[rowIndex];
              const valueItem = row.values.find((v) => v.column === columnName);

              if (valueItem && valueItem.value) {
                valueItem.value.planningResourceID = planningResourceID;
              }
            });
          }

          if (resourcesToCreate.length > 0) {
            notify({
              type: "success",
              message: translations["PlanningResourcesCreatedSuccessfully"],
            });
          }
        } catch (error) {
          console.error("Error creating planning resources:", error);
          notify({
            type: "error",
            message: translations["FailedToCreatePlanningResources"],
          });
          throw error; // Re-throw to be caught by the parent function
        }
      }

      this.$store.state.loading = false;
      return {
        newResourceMap,
        createdCount: resourcesToCreate.length,
      };
    },

    /**
     * Handle tile click
     */
    handleTileClick({row, column, timeSlot}) {
      this.selectedTimeSlot = row;
      this.selectedColumn = column;
      this.isBulkEdit = false;

      // Extract time values and hourCapacity for the modal
      this.timeEditInitialValues = {
        startTime: timeSlot?.value?.startTime || "",
        endTime: timeSlot?.value?.endTime || "",
        hourCapacity: timeSlot?.value?.hourCapacity || 0,
        clientSideUUID: timeSlot.clientSideUUID,
      };

      this.showTimeEditModal = true;
    },

    /**
     * Handle bulk edit
     */
    handleOpenBulkEdit(selectedSlots) {
      this.selectedSlots = selectedSlots;
      this.isBulkEdit = true;

      // Use the first slot as initial values if available
      if (selectedSlots.length > 0) {
        const firstSlot = selectedSlots[0];
        const timeSlot = firstSlot.row.values.find(
          (v) => v.column === firstSlot.column,
        );

        this.timeEditInitialValues = {
          startTime: timeSlot?.value?.startTime || "",
          endTime: timeSlot?.value?.endTime || "",
          hourCapacity: timeSlot?.value?.hourCapacity || 0,
        };
      } else {
        this.timeEditInitialValues = {
          startTime: "",
          endTime: "",
          hourCapacity: 0,
        };
      }

      this.showTimeEditModal = true;
    },

    /**
     * Handle selection change
     */
    handleSelectionChange(slots) {
      this.selectedSlots = slots;
    },

    /**
     * Close time edit modal
     */
    closeTimeEditModal() {
      this.showTimeEditModal = false;
      this.selectedTimeSlot = null;
      this.selectedColumn = null;
    },

    /**
     * Handle save time changes
     */
    async handleSaveTimeChanges(timeValues) {
      this.$store.state.loading = true;
      try {
        if (this.isBulkEdit) {
          await this.saveChangesForSelection(timeValues);
        } else {
          await this.saveSingleTimeChanges(timeValues);
        }
      } catch (error) {
        notify({
          type: "error",
          message: translations["FailedToSaveTimeChanges"],
        });
      } finally {
        notify({
          type: "success",
          message: translations["PlanningSavedSuccessfully"],
        });
        this.$store.state.loading = false;
        this.closeTimeEditModal();
        // clear selection
        this.clearSelection();
      }
    },

    async saveBulkTimeChanges(timeValuesArray) {
      this.$store.state.loading = true;
      // Validate input
      if (!Array.isArray(timeValuesArray) || timeValuesArray.length === 0) {
        console.error("Invalid or empty timeValuesArray provided");
        notify({type: "error", message: translations["InvalidTimeValues"]});
        return 0;
      }

      // Create arrays to track all changes grouped by type
      const resourcesToCreate = [];
      const resourcesToUpdate = [];
      const planningsToCreate = [];
      const planningsToUpdate = [];

      // Map to track resourceIDs by clientSideUUID
      const resourceMap = new Map();
      // Map clientSideUUID to its tile and gridRow for later updates
      const tileMap = new Map();
      // Array to track all updates for visual feedback
      const updates = [];

      // First pass: analyze what needs to be done for each time value
      for (const timeValues of timeValuesArray) {
        if (!timeValues.clientSideUUID) {
          console.error("Missing clientSideUUID for time values", timeValues);
          continue;
        }

        // Find the tile and gridRow based on clientSideUUID
        const gridRow = this.gridData.find((row) =>
          row.values.find(
            (value) => value.clientSideUUID === timeValues.clientSideUUID,
          ),
        );

        const tile = gridRow?.values.find(
          (value) => value.clientSideUUID === timeValues.clientSideUUID,
        );

        if (!tile || !gridRow) {
          console.error(
            "Selected resource not found for",
            timeValues.clientSideUUID,
          );
          continue;
        }

        // Store tile and gridRow for later updates
        tileMap.set(timeValues.clientSideUUID, {tile, gridRow});

        // Check if we need to create or update a planning resource
        const needsResource = !tile.resource?.PlanningResourceID;
        const planningResourceID = tile.resource?.PlanningResourceID ?? null;
        const dayIndex = this.getDayOfWeekIndex(tile.column);
        const dateString = this.weekStartDate
          .add(dayIndex, "day")
          .format("YYYY-MM-DD");

        if (needsResource) {
          // Create a new planning resource
          const resourceStartDate = `${dateString}T00:00:00`;
          const resourceEndDate = `${dateString}T23:59:59`;

          resourcesToCreate.push({
            clientSideUUID: timeValues.clientSideUUID,
            resource: {
              DefaultPlanningResourceID:
                tile.resource?.DefaultPlanningResourceID ??
                gridRow?.defaultPlanningResourceID ??
                undefined,
              ResourceID:
                tile.resource?.ResourceID ?? gridRow.resourceId ?? null,
              StartDate: resourceStartDate,
              EndDate: resourceEndDate,
              HourCapacity: timeValues.hourCapacity ?? 0,
              Description: gridRow.title || "Manual Planning",
            },
          });
        } else {
          // Update existing planning resource
          resourcesToUpdate.push({
            clientSideUUID: timeValues.clientSideUUID,
            resource: {
              DefaultPlanningResourceID:
                tile.resource?.DefaultPlanningResourceID ??
                gridRow.defaultPlanningResourceID ??
                undefined,
              PlanningResourceID: planningResourceID,
              HourCapacity: timeValues.hourCapacity ?? 0,
              StartDate: tile.resource?.StartDate ?? null,
              EndDate: tile.resource?.EndDate ?? null,
              Description: gridRow.title ?? "Manual Planning",
            },
          });

          // Store resource ID mapping for planning operations
          resourceMap.set(timeValues.clientSideUUID, planningResourceID);
        }

        // Check if we need to create or update a planning (only if time values are provided)
        if (timeValues.startTime && timeValues.endTime) {
          const needsPlanning = !tile.planning?.PlanningID;
          const startDateTime = `${dateString}T${timeValues.startTime}:00`;
          const endDateTime = `${dateString}T${timeValues.endTime}:00`;

          if (needsPlanning) {
            // Mark for later creation after resources are processed
            planningsToCreate.push({
              clientSideUUID: timeValues.clientSideUUID,
              planning: {
                DefaultPlanningID: tile.planning?.DefaultPlanningID ?? null,
                StartDate: startDateTime,
                EndDate: endDateTime,
                Day: dateString,
                HourCapacity: timeValues.hourCapacity || 0,
                WarehouseID: this.warehouseID,
              },
            });
          } else {
            // Update existing planning
            planningsToUpdate.push({
              clientSideUUID: timeValues.clientSideUUID,
              planning: {
                PlanningID: tile.planning?.PlanningID,
                DefaultPlanningID: tile.planning?.DefaultPlanningID ?? null,
                PlanningResourceID: planningResourceID,
                StartDate: startDateTime,
                EndDate: endDateTime,
                Day: dateString,
                HourCapacity: timeValues.hourCapacity || 0,
                WarehouseID: this.warehouseID,
              },
            });
          }
        }

        // Add to updates array for visual feedback
        updates.push({
          row: gridRow,
          column: tile.column,
          newValue: {
            startTime: timeValues.startTime,
            endTime: timeValues.endTime,
            hourCapacity: timeValues.hourCapacity,
          },
        });
      }

      try {
        // Process resources first
        if (resourcesToCreate.length > 0) {
          const resourcesData = resourcesToCreate.map((item) => item.resource);
          const response = await createPlanningResource(resourcesData);

          if (response.data && response.data.length > 0) {
            // Map created resources to their clientSideUUIDs
            response.data.forEach((resource, index) => {
              const clientSideUUID = resourcesToCreate[index].clientSideUUID;
              resourceMap.set(clientSideUUID, resource.PlanningResourceID);
            });
          }
        }

        if (resourcesToUpdate.length > 0) {
          const resourcesData = resourcesToUpdate.map((item) => item.resource);
          await updatePlanningResource(resourcesData);
        }

        // Process plannings after resources
        if (planningsToCreate.length > 0) {
          const planningsData = planningsToCreate.map((item) => ({
            ...item.planning,
            PlanningResourceID: resourceMap.get(item.clientSideUUID),
          }));

          await createPlanning(planningsData);
        }

        if (planningsToUpdate.length > 0) {
          const planningsData = planningsToUpdate.map((item) => item.planning);
          await updatePlanning(planningsData);
        }

        // Refresh data to show the updated resources and plannings
        await this.fetchWeekPlanning();

        // Mark all updates for visual feedback
        if (updates.length > 0) {
          this.markAsUpdated(updates);
        }
      } catch (error) {
        console.error("Error in bulk time changes:", error);
        notify({
          type: "error",
          message: translations["FailedToSaveTimeChanges"],
        });
      } finally {
        this.$store.state.loading = false;
      }

      return updates.length; // Return the number of successful updates
    },

    async saveSingleTimeChanges(timeValues) {
      if (!this.selectedTimeSlot || !this.selectedColumn) return;

      return await this.saveBulkTimeChanges([timeValues]);
    },

    saveChangesForSelection(timeValues) {
      const tiles = this.selectedSlots.map((slot) => slot.value);

      this.saveBulkTimeChanges(tiles.map((tile) => ({...timeValues, ...tile}))); // Merge timeValues with tile data
    },
    /**
     * Save bulk changes
     */
    async saveBulkChanges(timeValues) {
      this.$store.state.loading = true;
      // Group selected slots by resource for efficient processing
      const slotsByResource = {};

      this.selectedSlots.forEach((slot) => {
        const resourceId = slot.row.resourceId;
        if (!slotsByResource[resourceId]) {
          slotsByResource[resourceId] = [];
        }
        slotsByResource[resourceId].push(slot);
      });

      // Process each resource group
      for (const resourceId in slotsByResource) {
        const slots = slotsByResource[resourceId];
        if (!slots.length) continue;

        // Find the resource in the grid data
        const resourceIndex = this.gridData.findIndex(
          (resource) => resource.resourceId === resourceId,
        );

        if (resourceIndex === -1) {
          console.error(`Resource not found: ${resourceId}`);
          continue;
        }

        const resource = this.gridData[resourceIndex];

        // Check if we need to create a planning resource
        let planningResourceID = null;

        // Try to find an existing planningResourceID in this resource
        const existingSlot = resource.values.find(
          (slot) =>
            slot.value?.planningResourceID &&
            slot.value.planningResourceID.length > 0,
        );

        if (existingSlot) {
          // Use the existing planning resource ID
          planningResourceID = existingSlot.value.planningResourceID;
        } else {
          // We need to create a planning resource first
          try {
            // Create a new planning resource
            const newResource = [
              {
                DefaultPlanningResourceID: 0,
                ResourceID: resource.resourceId || 0,
                StartDate: new Date().toISOString(),
                EndDate: new Date().toISOString(),
                HourCapacity: timeValues.hourCapacity || 0,
                Description: resource.title || "Manual Planning",
              },
            ];

            const response = await createPlanningResource(newResource);

            if (response.data && response.data.length > 0) {
              planningResourceID = response.data[0].PlanningResourceID;

              // Update all slots in this resource with the new planningResourceID
              resource.values.forEach((slot) => {
                if (slot.value) {
                  slot.value.planningResourceID = planningResourceID;
                }
              });

              notify({
                type: "success",
                message: translations["PlanningResourceCreatedSuccessfully"],
              });
            } else {
              notify({
                type: "error",
                message: translations["FailedToCreatePlanningResource"],
              });
              continue;
            }
          } catch (error) {
            console.error("Error creating planning resource:", error);
            notify({
              type: "error",
              message: translations["FailedToCreatePlanningResource"],
            });
            continue;
          }
        }

        // Update all selected slots for this resource
        const updates = [];

        for (const slot of slots) {
          const timeSlotIndex = resource.values.findIndex(
            (v) => v.column === slot.column,
          );

          if (timeSlotIndex === -1) {
            console.error("Time slot not found");
            continue;
          }

          // Update the time values
          const newValues = [...resource.values];
          newValues[timeSlotIndex] = {
            ...newValues[timeSlotIndex],
            value: {
              ...newValues[timeSlotIndex].value,
              startTime: timeValues.startTime,
              endTime: timeValues.endTime,
              hourCapacity: timeValues.hourCapacity,
              planningResourceID: planningResourceID,
            },
          };

          // Use Vue's reactivity to update the grid
          this.$set(this.gridData[resourceIndex], "values", newValues);

          updates.push({
            row: this.gridData[resourceIndex],
            column: slot.column,
            newValue: {
              startTime: timeValues.startTime,
              endTime: timeValues.endTime,
              hourCapacity: timeValues.hourCapacity,
            },
          });
        }

        if (updates.length > 0) {
          this.markAsUpdated(updates);
          notify({
            type: "success",
            message: translations["TimeChangesAppliedSuccessfully"],
          });
        }
      }
      this.$store.state.loading = false;
    },

    /**
     * Mark updates as recently updated
     */
    markAsUpdated(updates) {
      // Add to recently updated list
      this.recentlyUpdated = [...updates];

      // Clear the recently updated list after a delay
      setTimeout(() => {
        this.recentlyUpdated = [];
      }, 2000);
    },

    /**
     * Execute the deletion of all planning entries marked for deletion
     * and their associated planning resources if they're not used elsewhere
     */
    async executeMarkedDeletions(job) {
      this.$store.state.loading = true;
      if (this.selectedSlots.length === 0) {
        notify({
          message: translations["NoTilesSelectedForDeletion"],
          type: "warning",
        });
        this.$emit("job-completed", job);
        return false;
      }

      // Show confirmation dialog
      const confirmed = await prompt({
        text: translations["AreYouSureDeleteOrResetResources"],
        confirmText: translations["Yes"] || "Yes",
        cancelText: translations["No"] || "No",
      });

      if (confirmed) {
        await this.deleteTilesResources(
          this.selectedSlots.map((slot) => slot.value),
        );
      }

      this.$emit("job-completed", job);
      this.$store.state.loading = false;
      return false;
    },

    // No need to update all methods, just keeping this one as an example
  },
};
</script>

<style>
/* Any component-specific styles that can't be handled by Tailwind */
.week-planning-container .r-grid-view {
  @apply border border-gray-200;
}

/* Add any hover effects or transitions */
.week-planning-container .grid-row:hover {
  @apply bg-gray-50;
}
</style>
