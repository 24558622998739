export function setReturnScanboxItems({formFields, rows}) {
  let seen = {};
  if (rows.length < 1)
    formFields.Scan = {
      ...formFields.Scan,
      Dropdown: {
        ...formFields.Scan.Dropdown,
        Items: [],
      },
    };

  const scannableRows = rows.filter(
    (r) => !r.Checked?.Value && !r.rowMeta?.virtual && !r.rowMeta?.classes?.hidden,
  );
  const scanboxItems =
    scannableRows
      .map((r) => ({
        Text:
          (r.SerialID?.Value || r.ItemID.Value) +
          " - " +
          r.ItemDescription.Value,
        Value: r.SerialID?.Value ?? r.ItemID.Value,
      }))
      .filter(
        (x) =>
          (seen[x.Value] = !Object.prototype.hasOwnProperty.call(
            seen,
            x.Value,
          )),
      ) ?? [];

  if (rows.length >= 1)
    formFields.Scan = {
      ...formFields.Scan,
      Dropdown: {
        ...formFields.Scan.Dropdown,
        Items: scanboxItems,
      },
    };

  return formFields;
}
