export const getCriteria = ({targetTableName, currentCriteria}) => {
  if (targetTableName === "Supplier.SupplierContact") {
    return [
      {
        SupplierID: currentCriteria?.[0]?.SupplierID,
      },
    ];
  }

  return null;
};
