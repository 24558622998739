export const getDateTimePickedMapping = ({row}) => {
  const trackedBulkPicksArray = row.TrackedBulkPicks || [];

  let dateTimePickedMap = [];
  let currentIndex = 0;

  // Build a map of which date applies to which index
  trackedBulkPicksArray.forEach((entry) => {
    const quantity = Number(Object.keys(entry)[0]);
    const dateTime = Object.values(entry)[0];

    for (let i = 0; i < quantity; i++)
      dateTimePickedMap[currentIndex + i] = dateTime;

    currentIndex += quantity;
  });

  return dateTimePickedMap;
};
