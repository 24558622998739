<template>
  <div
    class="bg-white"
    :class="{'opacity-60 pointer-events-none': loadingPage}"
  >
    <item-picker-stock-dialog
      v-if="showItemPickerDialog"
      v-model="showItemPickerDialog"
      :selected-item-id="selectedItemId"
      :order-dates="itemPickerDialogDates"
      :order-id="orderId"
      :translations="translations"
      :active-date-range-start="order.DateTimeExpectedStart"
      :active-date-range-end="order.DateTimeExpectedEnd"
      :extra-scan-fetch-params="order"
      @close="showItemPickerDialog = false"
      @scanItem="addItem"
    />
    <div
      v-if="!loaded || loadingPage"
      class="loader w-full flex justify-center"
    >
      <i class="fas fa-spinner fa-spin text-3xl mt-3"></i>
    </div>
    <div ref="datagrid">
      <datagrid-standalone
        v-if="loaded && columns.length"
        :rows="rows"
        :actions="actions"
        :columns="columns"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :user-metadata-key="userMetadataKey"
        :page="page"
        :visible-row-function="getVisibleRowsCompositionGrouped"
        :page-size="pageSize"
        :process-context="{order}"
        :action-functions="actionFunctions"
        :skeleton-rows="addItemQueue.length"
        @click-row="handleRowClick"
        @loading="loading = $event"
        @rows-updated="handleRowsUpdated"
        @cell-icon-click="handleCellIconClick($event)"
        @page-change="handlePageChange({page: $event})"
        @page-size-change="handlePageSizeChange"
      >
        <template #header>
          <datagrid-header-order-item
            :loaded="loaded"
            :scan-amount="scanAmount"
            :settings="settings"
            :page-size="pageSize"
            :order="order"
            @scan-amount-change="handleScanAmountChange"
            @item-select="addItem"
            @dialog-toggle="showItemPickerDialog = !showItemPickerDialog"
            @page-size-change="handlePageSizeChange"
          />
        </template>
        <template #footer>
          <datagrid-order-item-footer
            :total-paid="totalPaid"
            :total-weights="totalWeights"
            :extra-total-values="extraTotalValues"
            :rows="rows"
            :default-currency-id="defaultCurrencyId"
            :loading-page="loadingPage"
            :page="page"
            :page-size="pageSize"
            :active-row="activeRow"
            :currency="currency"
            @page-change="handlePageChange({page: $event})"
          />
        </template>
      </datagrid-standalone>
    </div>
  </div>
</template>

<script>
import {getVisibleRowsCompositionGrouped} from "../../../../functions/datagrid/rows/getVisibleRowsCompositionGrouped";
import handleRowDeleteCompositionSupport from "../../../../functions/datagrid/row/handleRowDeleteCompositionSupport";
import {getTotalWeightFromRows} from "../../../../functions/datagrid/rows/getTotalWeightFromRows.js";
import {initializeDatagridOrderItem} from "./utils/initializeDatagridOrderItem";
import {confirmDiscardUnsavedChanges} from "../../../../interface/prompts/confirmDiscardUnsavedChanges.js";
import {saveDatagridOrderItem} from "./utils/saveDatagridOrderItem";
import {prepareRowsForRender} from "../../../../functions/datagrid/rows/prepareRowsForRender";
import {integrateRowsChanges} from "../../../../functions/datagrid/rows/integrateRowsChanges";
import {getExtraTotalValues} from "../../../../functions/datagrid/order-item/getExtraTotalValues";
import {loadRowDataForPage} from "../../../../functions/datagrid/order-item/loadRowDataForPage";
import {mapActionComponent} from "../../../../functions/datagrid/mapActionComponent";
import {handleJobExecution} from "../../../../functions/datagrid/actions/handleJobExecution";
import {rowsProcessWebhook} from "../../../../functions/datagrid/order-item/rows/rowsProcessWebhook";
import {setLocalStoragePageSize} from "../../../../functions/datagrid/setLocalStoragePageSize";
import {handleRowsUpdated} from "../../../../functions/datagrid/order-item/event-handlers/handleRowsUpdated.js";
import {getTranslations} from "../../../../functions/session/localstorage/getTranslations.js";
import {updateRows} from "../../../../functions/datagrid/rows/updateRows.js";
import {addNewRows} from "../../../../functions/datagrid/order-item/addNewRows";
import {getUserId} from "../../../../functions/session/getUserId";
import {cloneDeep} from "lodash";
import {sortRows} from "../../../../functions/datagrid/rows/sortRows";
import {notify} from "../../../../util/notify";
import Decimal from "decimal.js";

import DatagridStandalone from "../../DatagridStandalone.vue";
import DatagridHeaderOrderItem from "../../datagridHeaders/DatagridHeaderOrderItem.vue";
import DatagridOrderItemFooter from "../../datagridFooters/DatagridOrderItemFooter.vue";
import ItemPickerStockDialog from "../../../item/ItemPickerStockDialog/ItemPickerStockDialog.vue";

export default {
  name: "DatagridOrderItem",
  components: {
    DatagridHeaderOrderItem,
    DatagridOrderItemFooter,
    ItemPickerStockDialog,
    DatagridStandalone,
  },
  provide() {
    const vm = this;

    const rate = {};
    Object.defineProperty(rate, "rate", {
      enumerable: true,
      get: () => this.rate,
    });

    return {
      rate,
      translations: this.translations,
      rateCallback(row, followSetting = true) {
        if (vm.selectedCurrencyId === vm.defaultCurrencyId && followSetting)
          return 1;
        return row?.Rate?.Value ?? 1;
      },
    };
  },
  props: {
    rWindow: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderId: null,
      order: {},
      rows: [],
      originalRows: [],
      columns: {},
      activeRow: null,
      showItemPickerDialog: false,
      itemPickerDialogDates: {},
      selectedItemId: null,
      addItemQueue: [],
      scanAmount: 1,
      extraTotalValues: {},
      dataWebhooks: [],
      sortBy: "Ranking",
      sortDirection: "asc",
      showDimensionObjects: false,
      rentalOrderItemPricesUpdatedDraftWebhookEvent: false,
      loading: false,
      loaded: false,
      loadingPage: false,
      page: 1,
      pageSize:
        JSON.parse(localStorage.getItem("datagridPageSizes"))?.OrderItem ?? 10,
      translations: getTranslations(),
      defaultCurrencyId: global.session?.defaultCurrencyId,
      actions: [{type: "Delete"}].map(mapActionComponent),
      actionFunctions: {
        deleteRow: handleRowDeleteCompositionSupport,
      },
    };
  },
  computed: {
    totalPaid() {
      const balance = new Decimal(this.order?.Balance ?? 0);
      return new Decimal(this.order.TotalIncVAT || 0).minus(balance).toNumber();
    },
    totalWeights() {
      return getTotalWeightFromRows({rows: this.rows});
    },
    rate() {
      return this.rows[0]?.Rate?.Value ?? 1;
    },
    currency() {
      return (
        this.order?.CurrencyID?.Description ?? this.defaultCurrencyId ?? null
      );
    },
    selectedCurrencyId() {
      return (
        this.rWindow.request.Data?.ClientCurrency ?? this.defaultCurrencyId
      );
    },
    userMetadataKey() {
      const userId = getUserId();
      return `${userId}-Rental.OrderItem-Multi`;
    },
    warehouseId() {
      return this.order?.WarehouseID?.Key ?? this.order?.WarehouseID;
    },
  },
  async created() {
    global.eventBus.on(`new-job-${this.rWindow.id}`, this.handleJobExecution);
    await initializeDatagridOrderItem({vueInstance: this});
  },
  beforeDestroy() {
    global.eventBus.off(`new-job-${this.rWindow.id}`, this.handleJobExecution);
  },
  methods: {
    async addItem(item) {
      const rentalOrderItemPricesUpdatedDraftWebhookEvent =
        this.dataWebhooks.some(
          (webhook) =>
            webhook.EventID === "rental.orderitem.prices.updated.draft",
        );
      const datagridElement =
        this.$refs.datagrid.querySelector(".datagrid-table");

      this.addItemQueue.push(item);

      if (this.addItemQueue.length > 1) {
        return;
      }

      while (this.addItemQueue.length > 0) {
        try {
          const queuedItem = this.addItemQueue[0];
          const newRows = await addNewRows({
            item: queuedItem,
            columns: this.columns,
            rows: this.rows,
            order: this.order,
            settings: this.settings,
            scanAmount: this.scanAmount,
            vueInstance: this,
          });
          const oldRows = cloneDeep(this.rows);
          this.updateRows({newRows, oldRows, vueComponent: this});
        } catch (error) {
          console.error(error);
        } finally {
          this.addItemQueue.shift();
        }
      }
      let rows = this.rows;
      if (rentalOrderItemPricesUpdatedDraftWebhookEvent) {
        rows = prepareRowsForRender({rows, columns: this.columns});
        this.rows = rows;
        rows = await rowsProcessWebhook({
          rows,
          order: this.order,
        });
      }

      rows = prepareRowsForRender({rows, columns: this.columns});
      this.rows = rows;
      this.extraTotalValues = {
        ...(await getExtraTotalValues({
          rows: this.rows,
          order: this.order,
          dataWebHooks: this.dataWebhooks,
        })),
      };

      this.navigateToLastPage();
      this.scrollToBottom(datagridElement);
    },
    async save(job) {
      try {
        await saveDatagridOrderItem({vueInstance: this});
      } finally {
        this.$emit("job-completed", job);
        this.$store.state.loading = false;
      }
      return false;
    },
    async sortRows(job) {
      this.rows = sortRows(this.rows);
      notify({message: this.translations["RowsSorted"], type: "success"});
      this.$emit("job-completed", job);
      return false;
    },
    async reset(job) {
      try {
        const rowsAreEqual =
          JSON.stringify(this.rWindow.data.Rows) ===
          JSON.stringify(this.rWindow.initialData.Rows);

        if (!rowsAreEqual && !(await confirmDiscardUnsavedChanges())) {
          return false;
        }

        this.$store.state.loading = true;
        await initializeDatagridOrderItem({vueInstance: this});
      } finally {
        this.$store.state.loading = false;
        this.$emit("job-completed", job);
      }
      return false;
    },
    async loadAndApplyRowDataForPage({rows}) {
      const changedRowsObject = await loadRowDataForPage({
        rows,
        page: this.page,
        pageSize: this.pageSize,
        order: this.order,
      });

      return integrateRowsChanges({
        originalRows: rows,
        changedRows: changedRowsObject,
      });
    },
    async handlePageChange({page}) {
      this.page = page;
      this.rows = await this.loadAndApplyRowDataForPage({
        rows: this.rows,
        page,
        pageSize: this.pageSize,
        order: this.order,
      });
    },
    handleScanAmountChange(scanAmount) {
      this.scanAmount = scanAmount.target.value;
    },
    handleRowsUpdated(event) {
      handleRowsUpdated({...event, vueComponent: this});
    },
    handlePageSizeChange(pageSize) {
      setLocalStoragePageSize({entity: "OrderItem", pageSize});
      this.pageSize = pageSize;
      this.navigateToPage({page: 1});
    },
    scrollToBottom(element) {
      element.scrollTop = element.scrollHeight;
    },
    navigateToLastPage() {
      const rowCount = this.rows.filter(
        (row) => !row.rowMeta?.compositionItem,
      ).length;
      this.page = Math.ceil(rowCount / this.pageSize);
    },
    navigateToPage({page}) {
      this.page = page;
    },
    handleCellIconClick({row, cell}) {
      if (cell.Name === "Amount") {
        this.selectedItemId = row.ItemID.Value;
        this.showItemPickerDialog = true;
        setTimeout(() => {
          this.selectedItemId = null;
        }, 300);
      }
    },
    handleRowClick(row) {
      this.updateActiveRow(row);
    },
    updateActiveRow(row) {
      this.activeRow = row;
    },
    handleJobExecution() {
      for (const job of this.rWindow.jobs) {
        handleJobExecution({job, vueInstance: this});
      }
    },
    updateRows,
    getVisibleRowsCompositionGrouped,
  },
};
</script>

<style scoped lang="scss">
.form-input {
  padding: 0;
}
.btn-group {
  .input-group-append {
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 9%) !important;
    border: 1px solid #ced4da;
    height: 31.5px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin-left: -2px;
    :hover {
      background-color: #eeeeee;
    }
  }
  .input-group-append:hover {
    background-color: #eeeeee;
    cursor: default;
  }
}
</style>
