import React from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {detailButton} from "@/components/item/ItemPickerStockDialog/itemPickerStockDialogVisRowDetailButton";
import {warehouseButton} from "@/components/item/ItemPickerStockDialog/itemPickerStockDialogVisRowWarehouseButton";

export function itemPickerStockDialogVisRow({
  dataItem,
  subject,
  warehouse,
  settings,
  currentWarehouse,
  showSerials,
}) {
  const canEdit =
    warehouse === currentWarehouse ||
    settings?.MultipleWarehousesPerDocumentPolicy ===
      "MultipleWarehousesPerDocumentPolicyAllow";

  const reactComponent = (
    <div className={"flex"}>
      <RenderScanSerialButton
        dataItem={dataItem}
        canEdit={canEdit}
        showSerials={showSerials}
      />
      <RenderButtonsNonSerialItemDefaultOverview
        dataItem={dataItem}
        subject={subject}
        canEdit={canEdit}
        showSerials={showSerials}
      />
      <RenderItemSerialButtonsDefaultOverview
        dataItem={dataItem}
        subject={subject}
        canEdit={canEdit}
        warehouse={warehouse}
        settings={settings}
        showSerials={showSerials}
      />
      <RenderItemSerialButtonsNoSerialsDefaultOverview
        dataItem={dataItem}
        subject={subject}
        canEdit={canEdit}
      />
      <RenderDefault
        dataItem={dataItem}
        subject={subject}
        canEdit={canEdit}
        warehouse={warehouse}
        settings={settings}
      />
      <div
        title={dataItem.segmentGroupDescription}
        data-tooltip={dataItem.segmentGroupDescription}
        className={"ml-1.5 max-w-[300px] truncate"}
      >
        {dataItem.segmentGroupDescription}
      </div>
    </div>
  );

  return renderToStaticMarkup(reactComponent);
}

function RenderButtonsNonSerialItemDefaultOverview({
  dataItem,
  subject,
  canEdit,
  showSerials,
}) {
  if (
    dataItem.item.useSerials === false &&
    subject !== "Rental.virtual_ItemDetailAvailability" &&
    subject !== "Rental.virtual_ItemWarehouseAvailability" &&
    subject !== "Rental.virtual_SerialAvailability"
  ) {
    return (
      <div>
        {canEdit && <AddItemFromModal dataItem={dataItem} />}

        {showSerials && (
          <button
            className="btn btn-sm"
            style={{opacity: 0, marginLeft: "2px"}}
          >
            <i className="fas fa-tag pointer-events-none"></i>
          </button>
        )}
        <span className="pl-1">
          {dataItem.isComposition !== true &&
            warehouseButton({dataItem, subject})}
          {dataItem.isComposition !== true && detailButton({dataItem, subject})}
        </span>
      </div>
    );
  }

  return null;
}

function RenderItemSerialButtonsDefaultOverview({
  dataItem,
  subject,
  canEdit,
  warehouse,
  settings,
  showSerials,
}) {
  const isSerialItem = dataItem.item.useSerials && dataItem.item.hasSerials;
  if (
    (isSerialItem && subject === "Rental.virtual_ItemAvailability") ||
    (subject === "Rental.virtual_ItemWarehouseAvailability" &&
      settings?.MultipleWarehousesPerDocumentPolicy ===
        "MultipleWarehousesPerDocumentPolicyAllow")
  ) {
    let segmentWarehouse = warehouse;
    if (subject === "Rental.virtual_ItemWarehouseAvailability")
      segmentWarehouse = dataItem.segmentGroupDescription;

    const showSerial = isSerialItem && showSerials;

    return (
      <div>
        {canEdit && (
          <AddItemFromModal dataItem={dataItem} warehouse={segmentWarehouse} />
        )}
        {showSerial && (
          <button
            style={{marginLeft: "2px"}}
            className="btn btn-sm btn-primary"
            data-item-description={dataItem.item.description}
            data-item-id={dataItem.item.itemID}
            data-function={"openItemSerialOverview"}
            data-warehouse-id={segmentWarehouse}
          >
            <i className="fas fa-tag pointer-events-none"></i>
          </button>
        )}

        <span className="pl-1">
          {warehouseButton({dataItem, subject})}
          {detailButton({dataItem, subject})}
        </span>
      </div>
    );
  }
  return null;
}

function RenderItemSerialButtonsNoSerialsDefaultOverview({
  dataItem,
  subject,
  canEdit,
}) {
  if (
    dataItem.item.useSerials &&
    !dataItem.item.hasSerials &&
    subject === "Rental.virtual_ItemAvailability"
  ) {
    return (
      <div>
        {canEdit && <AddItemFromModal dataItem={dataItem} />}

        <button
          style={{marginLeft: "2px"}}
          className="btn btn-sm btn-primary disabled"
          data-item-id={dataItem.item.itemID}
          data-function={"openItemSerialOverview"}
        >
          <i className="fas fa-tag pointer-events-none"></i>
        </button>
        <span className="pl-1">
          {warehouseButton({dataItem, subject})}
          {detailButton({dataItem, subject})}
        </span>
      </div>
    );
  }
  return null;
}

function RenderDefault({dataItem, subject, canEdit, warehouse, settings}) {
  const buttonCollection = [
    RenderScanSerialButton({dataItem, canEdit}),
    RenderButtonsNonSerialItemDefaultOverview({
      dataItem,
      subject,
      canEdit,
    }),
    RenderItemSerialButtonsDefaultOverview({
      dataItem,
      subject,
      canEdit,
      warehouse,
      settings,
    }),
    RenderItemSerialButtonsNoSerialsDefaultOverview({
      dataItem,
      subject,
      canEdit,
    }),
  ];

  if (!buttonCollection.some(Boolean)) {
    return (
      <div>
        <span style={{width: "155px", display: "inline-block"}}></span>
      </div>
    );
  }
  return null;
}

function RenderScanSerialButton({dataItem, canEdit, showSerials}) {
  if (canEdit && dataItem.item.serialID && showSerials) {
    return (
      <button
        className="btn btn-sm btn-primary"
        data-item-id={dataItem.item.itemID}
        data-serial-id={dataItem.item.serialID}
        data-function={"scanSerial"}
      >
        <i className="fas fa-plus pointer-events-none"></i>
      </button>
    );
  }
  return null;
}

function AddItemFromModal({dataItem, warehouse, canEdit}) {
  return (
    <button
      className="btn btn-sm btn-primary addItemFromModal"
      data-item-id={dataItem.item.itemID}
      data-function={"scanItem"}
      data-warehouse-id={warehouse}
    >
      <i className="fas fa-plus pointer-events-none"></i>
      {canEdit}
    </button>
  );
}
