var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"r-select-wrapper",staticClass:"r-select-wrapper flex h-full",class:[
    _vm.themeClasses,
    {
      '!bg-[#fff] !rounded-[3px] !border-solid border-[1px] border-[#ced4da] shadow-[1px_1px_1px_0px_rgba(0,0,0,0.09)]':
        _vm.defaultStyling,
      '!cursor-not-allowed': _vm.readOnly,
      'cursor-text': !_vm.readOnly,
      'allow-scrollable-container': _vm.multiple,
    },
  ],attrs:{"title":_vm.tooltip}},[_c('vue-select',{ref:"r-select",staticClass:"r-select flex-grow h-full min-w-0",class:{
      'hide-vs-search-element': !_vm.isSearchActive && !_vm.hideCursor,
      '!text-[#888]': _vm.readOnly,
      '!bg-[#eeeeee]': _vm.readOnly && _vm.defaultStyling,
    },attrs:{"value":_vm.selectedValue,"options":_vm.shownOptions,"label":_vm.label,"required":_vm.required,"placeholder":_vm.placeholder,"loading":_vm.loading,"disabled":_vm.readOnly,"clearable":_vm.clearable,"selectable":_vm.canSelectOptions,"filter-by":_vm.filterBy,"filter":_vm.optionsFilter,"no-drop":_vm.disableDropdown,"multiple":_vm.multiple,"taggable":_vm.taggable,"push-tags":_vm.pushTags,"auto-scroll":true,"append-to-body":_vm.appendToBody,"close-on-select":_vm.closeOnSelect,"clear-search-on-blur":_vm.clearSearchOnBlur,"clear-search-on-select":_vm.clearSearchOnSelect,"reset-on-options-change":_vm.resetOnOptionsChange,"dropdown-should-open":_vm.dropdownShouldOpen,"calculate-position":_vm.calculatedPosition},on:{"focus":_vm.handleFocus,"search":_vm.handleSearch,"input":function($event){return _vm.handleInput($event)},"open":function($event){return _vm.handleDropdownOpened($event)},"select":function($event){return _vm.$emit('value-change', $event)},"search:blur":_vm.handleFocusLoss,"option:selected":function($event){return _vm.$emit('option-selected', $event)},"option:deselected":function($event){return _vm.$emit('option-deselected', $event)}},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return _vm.$emit('keyup-backspace', $event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('keyup-enter', $event)}]},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{attrs:{"title":option.Text +
          (option.SubDescription != null ? `\n${option.SubDescription}` : '')},on:{"click":function($event){return _vm.handleOptionClick(option)}}},[_vm._t("select-option",function(){return [_c('div',{staticClass:"flex items-center"},[(option?.Color)?_c('div',[_c('span',{staticClass:"inline-block !min-w-[13px] !min-h-[13px] rounded-full mt-1 mr-1.5",style:({'background-color': option.Color})})]):_vm._e(),(_vm.label === null)?_c('span',[_vm._v(_vm._s(option.null))]):_c('span',[_vm._v(_vm._s(option.Text ?? option.Value ?? option))])])]},{"option":option})],2)]}},{key:"selected-option",fn:function(option){return [(_vm.selectedValueColor)?_c('div',[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"inline-block !min-w-[13px] !min-h-[13px] rounded-full mb-1.5 mr-1.5",style:({'background-color': _vm.selectedValueColor})})])]):_vm._e(),(Array.isArray(option))?_c('span',[_vm._v(" "+_vm._s(option.map((item) => _vm.extractSelectedOptionText(item)).join(", "))+" ")]):_c('span',[_vm._v(_vm._s(_vm.extractSelectedOptionText(option)))])]}},{key:"search",fn:function({attributes, events}){return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"id":_vm.inputId}},'input',attributes,false),events)),(_vm.required)?_c('input',{attrs:{"hidden":"","name":_vm.randomString,"required":Array.isArray(_vm.selectedValue)
            ? _vm.selectedValue.length < 1
            : !_vm.selectedValue}}):_vm._e()]}},{key:"no-options",fn:function(){return [_c('div',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.noOptionsFoundPlaceholder)+" ")])]},proxy:true}],null,true)}),(!_vm.hideAllButtons)?_c('div',{staticClass:"flex items-center flex-shrink-0 !text-black bg-inherit pr-1",class:{'!bg-[#eeeeee]': _vm.readOnly && _vm.defaultStyling}},[_c('r-select-buttons',{attrs:{"show-create-button":_vm.showCreateButton,"show-ref-button":_vm.showRefButton,"is-dropdown-open":_vm.isSearchActive,"hide-buttons":_vm.hideAllButtons,"smaller-icons":_vm.smallerIcons,"action-buttons-disabled":_vm.actionButtonsDisabled,"read-only":_vm.readOnly,"clearable":!!(_vm.clearable && _vm.selectedValue),"loading":_vm.loading},on:{"clear-selection":function($event){return _vm.handleInput(null)},"open-indicator-click":_vm.handleOpenIndicatorClick,"open-create-entity":_vm.handleCreateEntityClick,"open-ref":_vm.handleOpenRefClick}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }