<template>
  <div class="flex justify-end mb-1.5">
    <div v-if="loaded" class="w-full">
      <item-scanner :disabled="!loaded" @select="handleSelect" />
    </div>
    <datagrid-page-size
      class="ml-1"
      :page-size="pageSize"
      :page-sizes="[5, 10, 25, 50, 100]"
      @change="$emit('page-size-change', $event)"
    />
  </div>
</template>
<script>
import DatagridPageSize from "../datagridFilters/DatagridPageSize.vue";
import ItemScanner from "../../item/ItemScanner.vue";
import {scanPickListItem} from "../../../functions/datagrid/data/scanPickListItem";

export default {
  name: "DatagridHeaderPickListItem",
  components: {
    ItemScanner,
    DatagridPageSize,
  },
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pickListItems() {
      return this.rows.map((row) => {
        return {
          PickListItemID: row.PickListItemID.Value,
          ItemID: row.ItemID.Value,
          SerialID: row.SerialID.Value,
          StatusCode: row.StatusCode.Value,
        };
      });
    },
  },
  methods: {
    async handleSelect(item) {
      const data = await scanPickListItem({
        search: item.value,
        items: this.pickListItems,
      });

      this.$emit("scan", data);
    },
  },
};
</script>
