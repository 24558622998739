import {axiosInstanceWithErrorHandling} from "../../../axiosInstanceWithErrorHandling.js";

/**
 * Creates a list of planning resource DTOs
 *
 * @param {Array} data - The list of planning resource DTOs to create
 * @param {string} data[].PlanningResourceID - The planning resource ID (UUID)
 * @param {number} data[].DefaultPlanningResourceID - The default planning resource ID
 * @param {number} data[].ResourceID - The resource ID
 * @param {string} data[].StartDate - The start date (ISO format)
 * @param {string} data[].EndDate - The end date (ISO format)
 * @param {number} data[].HourCapacity - The hour capacity
 * @param {string} data[].Description - The description
 * @returns {Promise<Array>} - Promise containing the created planning resource DTOs
 */
export const createPlanningResource = async (data) => {
  return axiosInstanceWithErrorHandling("/api/v2/warehouse/planning-resource", {
    method: "POST",
    data,
  });
};
