import dayjs from "dayjs";
import {rowsSetDateTimePickedToNow} from "./rowsSetDateTimePickedToNow.js";

export const processRowsDateTimePicked = ({rows}) => {
  const processedRows = rows.map((row) => {
    if (row.UseSerials.Value)
      return {
        ...row,
        DateTimePicked: {
          Name: "DateTimePicked",
          Value:
            row.PickedQuantity.Value > 0
              ? (row.DateTimePicked?.Value ??
                dayjs().format("YYYY-MM-DDTHH:mm:ss"))
              : null,
        },
      };

    if (
      (!row.TrackedBulkPicks?.Value ||
        row.TrackedBulkPicks?.Value?.length < 1) &&
      row.PickedQuantity.Value > 0
    )
      return {
        ...row,
        DateTimePicked: {
          Name: "DateTimePicked",
          Value: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        },
      };

    return row;
  });
  debugger;
  const rowsWithDateTimePicked = processedRows.filter(
    (row) => row.DateTimePicked?.Value,
  );
  const rowsWithoutDateTimePicked = processedRows.filter(
    (row) => !row.DateTimePicked?.Value,
  );
  const rowsWithoutDateTimePickedSetToCurrentDateTime =
    rowsSetDateTimePickedToNow({rows: rowsWithoutDateTimePicked});

  return [
    ...rowsWithDateTimePicked,
    ...rowsWithoutDateTimePickedSetToCurrentDateTime,
  ];
};
