var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'text-[#888] !cursor-not-allowed': _vm.readonly,
      'text-black !cursor-text': !_vm.readonly,
    },
    _vm.cellClasses,
  ],attrs:{"title":_vm.cellTitle},on:{"mousedown":function($event){return _vm.$emit('cell-click', {row: _vm.row, column: _vm.column, clickEvent: $event, cell: _vm.cell, name: _vm.name})}}},[(_vm.column.IsVisible)?_c(_vm.cellComponent,{tag:"component",attrs:{"value":_vm.cell,"classes":_vm.cellClasses,"column":_vm.column,"cell":_vm.cell,"columns":_vm.columns,"name":_vm.name,"row":_vm.row,"data-component":`datagrid-cell-${_vm.cellComponentName}`},on:{"change":_vm.handleChange,"input":_vm.handleChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }