import {changeBusinessMomentsAvailability} from "./changeBusinessMomentsAvailability";
import {getDialogFieldValues} from "../getDialogFieldValues";
import {getComboboxItems} from "../../../services/mainRadComboBox";
import {getTranslations} from "../../session/localstorage/getTranslations";
import {alertPopup} from "../../../interface/alertPopup/alertPopup";
import openDialog from "../../../actions/openDialog";
import {notify} from "../../../util/notify";
import store from "../../../../state/store";

export const openChangeBusinessMomentsAvailabilityDialog = async ({window}) => {
  const translations = getTranslations();
  const selectedBusinessMoments = window.selection;
  if (selectedBusinessMoments?.length < 1) {
    alertPopup({
      text: translations["AdminDesktop_AlertNoRowsSelected"],
      icon: "warning",
    });
    return false;
  }

  const availabilityPolicies = await getComboboxItems({
    tableName: "Core.Dropdown",
    columnName: "Key",
    prefix: "Multi",
    primaryKeys: {
      ColumnName: "AvailabilityPolicy",
      FullTableName: "Settings.BusinessMoment",
    },
  });
  availabilityPolicies.forEach(
    (policy) =>
      (policy.Text = translations[`AvailabilityPolicy${policy.Value}`]),
  );
  availabilityPolicies.unshift({
    Value: null,
    Text: translations.UseBusinessHourAvailability,
  });

  const dialogData = {
    RawTitle: "ChangeBusinessMomentsAvailability",
    Data: {
      Actions: [
        {
          RawTitle: "Cancel",
          ButtonVariant: "secondary",
          Callback: () => {
            store.commit("dialog/hideDialog");
          },
        },
        {
          RawTitle: "Save",
          ButtonVariant: "primary",
          Callback: async (dialogData) => {
            const fields = getDialogFieldValues(dialogData);
            const success = await changeBusinessMomentsAvailability({
              fields,
              businessMoments: selectedBusinessMoments,
            });
            if (success) {
              notify({
                message: getTranslations().AdminDesktop_SuccesSave,
                type: "success",
              });
            } else
              notify({
                message: getTranslations().SaveFailed,
                type: "error",
              });
            store.commit("dialog/hideDialog");
            window.reload();
          },
        },
      ],
      Fields: [
        {
          Name: "AvailabilityPolicy",
          RawTitle: "AvailabilityPolicy",
          Type: "Select",
          Value: null,
          Dropdown: {
            Items: [...availabilityPolicies],
          },
        },
      ],
    },
  };

  openDialog(dialogData);
};
