<template>
  <r-modal
    :show="show"
    size="sm"
    :title="translations['EditResourceCapacity']"
    @hide="$emit('hide')"
  >
    <dialog-form
      :dialog-data="dialogData"
      :data-in-store="false"
      @input="handleInput"
    />

    <template #modal-footer>
      <div class="flex justify-end">
        <r-button variant="secondary" @click="$emit('hide')">
          {{ translations["Cancel"] }}
        </r-button>
        <r-button variant="primary" @click="handleSave">
          {{ translations["Save"] }}
        </r-button>
      </div>
    </template>
  </r-modal>
</template>

<script>
import RModal from "@/components/elements/RModal.vue";
import DialogForm from "@/components/dialog/DialogForm.vue";
import RButton from "@/components/elements/RButton.vue";
import {getTranslations} from "@/functions/session/getTranslations.js";

const translations = getTranslations();

export default {
  name: "EditResourceModal",
  components: {
    RButton,
    DialogForm,
    RModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      translations,
      dialogData: {
        Fields: [
          {
            Name: "Description",
            Type: "text",
            IsRequired: true,
            IsReadOnly: true,
            Value: "",
            Title: translations["Resource"],
            ReadOnly: true,
          },
          {
            Name: "HourCapacity",
            Type: "number",
            Required: true,
            Value: 0,
            Title: translations["HourCapacity"],
            Placeholder: translations["EnterNumberOfHours"],
          },
        ],
      },
    };
  },
  watch: {
    resource: {
      handler(newResource) {
        if (newResource) {
          this.updateDialogData(newResource);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateDialogData(resource) {
      this.dialogData = {
        Fields: [
          {
            ...this.dialogData.Fields[0],
            Value: resource.title || resource.name || "",
          },
          {
            ...this.dialogData.Fields[1],
            Value: resource.hourCapacity || 0,
          },
        ],
      };
    },

    handleInput(fields) {
      this.dialogData = {
        ...this.dialogData,
        Fields: Object.values(fields),
      };
    },

    handleSave() {
      const hourCapacity = this.dialogData.Fields[1].Value;

      this.$emit("save", {
        ...this.resource,
        hourCapacity,
      });
    },
  },
};
</script>
