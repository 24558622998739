import {createVirtualHeaderRow} from "./createVirtualHeaderRow";

export const groupReturnItemRows = ({rows, vueComponent}) => {
  if (rows[0].rowMeta?.virtual) return rows;

  const {GroupRentalEndRows} = vueComponent.settings;
  if (GroupRentalEndRows === "None") return rows;

  const sortedRows = rows.sort((a, b) => {
    return a[GroupRentalEndRows].Value - b[GroupRentalEndRows].Value;
  });

  const groupedRows = [];
  let lastSeenId = null;
  sortedRows.forEach((row) => {
    if (row.OrderID.Value !== lastSeenId) {
      lastSeenId = row.OrderID.Value;
      groupedRows.push(
        createVirtualHeaderRow({
          columns: vueComponent.columns,
          referencedRow: row,
        }),
      );
    }

    groupedRows.push({
      ...row,
      rowMeta: {
        ...row.rowMeta,
        styles: {
          "border-left": "2px solid #b1aeae",
          "border-right": "2px solid #b1aeae",
        },
      },
    });
  });

  return groupedRows;
};
