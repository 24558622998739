export function validateReturnItemRowClick({event}) {
  if (!event) return false;

  const isOpenRefClick =
    event.clickEvent.srcElement.className.includes("material-icons");

  const isEditableCellClick =
    event.cell?.Name === "Amount" && event.row.MaxAmountToReturn.Value > 1;

  const isCheckedRowServiceCellClick =
    event.cell?.Name === "PerformServiceBeforeReturn" &&
    event.row.Checked.Value;

  const isUnCheckedRowServiceCellUnCheckClick =
    event.cell?.Name === "PerformServiceBeforeReturn" &&
    event.cell?.Value === true &&
    !event.row.Checked.Value;

  return (
    !isOpenRefClick &&
    !isEditableCellClick &&
    !isCheckedRowServiceCellClick &&
    !isUnCheckedRowServiceCellUnCheckClick
  );
}
