import {getRawValueFromDropdownValue} from "../../../../util/parsing/getRawValueFromDropdownValue";
import {getActiveWarehouse} from "../../../../util/getActiveWarehouse";

export const createQuotationItem = ({
  Amount,
  ConditionID,
  DateTimeExpectedEnd,
  DateTimeExpectedStart,
  ItemID,
  QuotationItemID,
  Ranking,
  ClientSideUUID,
  WarehouseID,
}) => ({
  Amount: Amount.Value,
  ConditionID: getRawValueFromDropdownValue(ConditionID.Value),
  DateTimeExpectedEnd: DateTimeExpectedEnd.Value ?? null,
  DateTimeExpectedStart: DateTimeExpectedStart.Value ?? null,
  ItemID: getRawValueFromDropdownValue(ItemID.Value),
  QuotationItemID: QuotationItemID.Value,
  Ranking: Ranking.Value,
  WarehouseID:
    getRawValueFromDropdownValue(WarehouseID?.Value) ?? getActiveWarehouse(),
  ClientSideUUID: ClientSideUUID.Value,
});
