import {alertPopup} from "../../../../interface/alertPopup/alertPopup.js";

export const validateRequiredFields = async ({
  formFields,
  row,
  translations,
}) => {
  const emptyRequiredFields = [];
  const requiredFieldsToValidate = formFields.filter(
    (field) =>
      field.IsRequired &&
      field.IsVisible &&
      (field.Editor !== "AutoIncrement" || !field.IsReadOnly),
  );

  formFields.forEach((field) => {
    const rowValue = row[field.Name];

    if (
      requiredFieldsToValidate.includes(field) &&
      (rowValue === "" || rowValue === null)
    )
      emptyRequiredFields.push(field.Title);
  });

  if (emptyRequiredFields.length === 0) {
    return true;
  }

  const message = translations.ExceptionRequiredFieldNotGiven.replace(
    "{}",
    emptyRequiredFields.join(", "),
  );

  alertPopup({icon: "error", text: message});
  return false;
};
