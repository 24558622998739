import {unixMilisecondsToDayjs} from "../../util/formatting/unixToDayjs";

export const processScanResult = (result) => {
  const newRows = result.Rows;
  if (!newRows || !newRows.length) return [];

  newRows.forEach((row, index) => {
    const rowMetadata = result.Metadata[index];

    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "string" && row[key]?.startsWith("/Date(")) {
        row[key] = unixMilisecondsToDayjs(row[key]).format("YYYY-MM-DD");
      }

      if (rowMetadata[key]?.Description)
        row[key] = {
          Key: row[key],
          Description: rowMetadata[key]?.Description,
        };
    });
  });

  return newRows;
};
