export function convertKeyValueRowToCellRow(row) {
  const exemptedProperties = ["rowMeta"];
  const convertedValues = row;

  for (const value in convertedValues) {
    if (
      row[value]?.hasOwnProperty("Value") ||
      exemptedProperties.includes(value)
    )
      continue;

    convertedValues[value] = {
      Value: row[value],
      Name: value,
    };
  }
  return convertedValues;
}
